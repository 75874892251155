import {
    ConfirmModal,
    IconButton,
    StyleFunction,
    useModal,
    useThemedStyle,
} from "@venuepos/react-common";
import { LayoutSection } from "lib";
import { useTranslation } from "locales";
import React, { useCallback } from "react";
import { View } from "react-native";
import {
    LayoutAction,
    MoveActionOptions,
} from "../../layout-operation-reducer";
import { SectionTabHeader } from "../../section-tab-header";
import { useLayoutButtons, useLayoutButtonsDispatch } from "../buttons-context";
import { tableLayoutReducer } from "./table-layout-operations-reducer";

export function SectionTabs() {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { render } = useModal();

    const dispatch = useLayoutButtonsDispatch();
    const layoutData = useLayoutButtons();

    /**
     * Deletes the section with the given index.
     * Also, makes sure that there is always at least one section in the collection.
     */
    const handleDeleteSection = useCallback(
        async (sectionId: string) => {
            if (!dispatch || !layoutData) {
                return;
            }

            if (!sectionId) {
                console.error(
                    "There is no id. What would you have me do, huh?"
                );
                return;
            }

            if (
                !(await render(onClose => (
                    <ConfirmModal
                        headerText={t(
                            "backoffice.layout.delete_section_header",
                            "Delete section?"
                        )}
                        bodyText={t(
                            "backoffice.layout.delete_section_body",
                            "Are you sure you want delete this section?"
                        )}
                        onClose={onClose}
                    />
                )))
            ) {
                return;
            }

            dispatch({
                type: LayoutAction.DELETE_SECTION,
                sectionId,
                translationFunction: t,
            });

            // Callback to update the form values.
            return layoutData.onLayoutUpdate(
                tableLayoutReducer(layoutData, {
                    type: LayoutAction.DELETE_SECTION,
                    sectionId,
                    translationFunction: t,
                }).sections
            );
        },
        [dispatch, layoutData, render, t]
    );

    const handleRenameSection = useCallback(
        (sectionId: LayoutSection["id"], newLabel: LayoutSection["label"]) => {
            if (!dispatch || !layoutData) {
                return;
            }

            dispatch({
                type: LayoutAction.RENAME_SECTION,
                sectionId,
                newLabel,
            });

            // Callback to update the form values.
            return layoutData.onLayoutUpdate(
                tableLayoutReducer(layoutData, {
                    type: LayoutAction.RENAME_SECTION,
                    sectionId,
                    newLabel,
                }).sections
            );
        },
        [dispatch, layoutData]
    );

    const handleAddNewSection = useCallback(() => {
        if (!dispatch || !layoutData) {
            return;
        }

        dispatch({
            type: LayoutAction.ADD_SECTION,
            translationFunction: t,
        });

        // Callback to update the form values.
        return layoutData.onLayoutUpdate(
            tableLayoutReducer(layoutData, {
                type: LayoutAction.ADD_SECTION,
                translationFunction: t,
            }).sections
        );
    }, [dispatch, layoutData, t]);

    const handleSelectSection = useCallback(
        (sectionId: LayoutSection["id"]) => {
            if (!dispatch || !layoutData) {
                return;
            }

            dispatch({ type: LayoutAction.SELECT_SECTION, sectionId });
        },
        [dispatch, layoutData]
    );

    const handleMoveSection = useCallback(
        (sectionId: LayoutSection["id"], direction: MoveActionOptions) => {
            if (!dispatch || !layoutData) {
                return;
            }

            dispatch({ type: LayoutAction.MOVE_SECTION, sectionId, direction });

            // Callback to update the form values.
            return layoutData.onLayoutUpdate(
                tableLayoutReducer(layoutData, {
                    type: LayoutAction.MOVE_SECTION,
                    sectionId,
                    direction,
                }).sections
            );
        },
        [dispatch, layoutData]
    );

    const handleToggleRenaming = useCallback(
        (sectionId: LayoutSection["id"], toggleState: boolean) => {
            if (!dispatch || !layoutData) {
                return;
            }

            dispatch({
                type: LayoutAction.TOGGLE_RENAME_SECTION,
                sectionId,
                toggleState,
            });
        },
        [dispatch, layoutData]
    );

    if (!layoutData) {
        return null;
    }

    return (
        <View style={styles.sectionTabContainer}>
            <View style={styles.sectionTabs}>
                {layoutData.sections.map((section, sectionIndex) => (
                    <SectionTabHeader
                        key={`section_${section.id}`}
                        sectionId={section.id}
                        label={section.label}
                        onPress={handleSelectSection}
                        onDelete={handleDeleteSection}
                        onChange={handleRenameSection}
                        activeTab={
                            sectionIndex === layoutData.currentSectionIndex
                        }
                        allowMoveBefore={sectionIndex !== 0}
                        allowMoveAfter={
                            sectionIndex !== layoutData.sections.length - 1
                        }
                        onMoveSection={handleMoveSection}
                        onToggleRenaming={handleToggleRenaming}
                        renaming={!!section?.renaming}
                        testID={`layout:section:${sectionIndex}`}
                    />
                ))}
            </View>
            <View>
                <IconButton
                    name="plus-circle"
                    size="large"
                    color={styles.icon.color}
                    onPress={handleAddNewSection}
                    testID="layout:addSection"
                />
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    icon: {
        color: theme.colors.grey250,
    },

    sectionTabs: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "stretch",
    },
    sectionTabContainer: {
        flex: 1,
        flexDirection: "row",
        marginBottom: theme.spacingScale * 2,
    },
});
