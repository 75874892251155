import React, { useState } from "react";
import { useTranslation } from "locales";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { isHexColor } from "../../utils/color";

import { StyleFunction, useThemedStyle } from "../../theme";
import { ColorIndicator } from "../color-indicator";
import { ColorPickerButton } from "../color-picker-button";
import { InputLabel } from "../input-label";
import { TextInput } from "../textinput";

export function ColorPickerInput({
    label,
    onChangeColor,
    style,
    disabled = false,
    defaultColor,
    testID,
}: {
    label?: string;
    onChangeColor?: (value?: string) => void;
    style?: StyleProp<ViewStyle>;
    disabled?: boolean;
    defaultColor?: string;
    testID?: string;
}) {
    const [t] = useTranslation();
    const [currentColor, setCurrentColor] = useState<string | undefined>(
        defaultColor
    );
    const [inputFieldColor, setInputFieldColor] = useState<string | undefined>(
        defaultColor
    );
    const styles = useThemedStyle(styleFunc);

    return (
        <View style={style}>
            {!label ? null : <InputLabel>{label}</InputLabel>}
            <View style={styles.colorPickerContainer}>
                <View style={styles.inputContainer}>
                    <TextInput
                        style={[styles.textInput, style]}
                        value={inputFieldColor}
                        onChangeText={newColor => {
                            if (isHexColor(newColor)) {
                                setCurrentColor(newColor);
                            }
                            setInputFieldColor(newColor);
                            if (onChangeColor) {
                                onChangeColor(newColor);
                            }
                        }}
                        disabled={disabled}
                        testID={testID}
                        maxLength={7}
                    />
                    <View style={styles.inputRightEnd}>
                        <ColorIndicator
                            color={currentColor}
                            animate={false}
                            copyOnClick={true}
                            size="large"
                        />
                    </View>
                </View>
                <ColorPickerButton
                    defaultColor={currentColor}
                    color={currentColor}
                    onChange={newValue => {
                        setCurrentColor(newValue);
                        setInputFieldColor(newValue);
                        if (onChangeColor) {
                            onChangeColor(newValue);
                        }
                    }}
                    onCancel={oldValue => setCurrentColor(oldValue)}
                    disabled={disabled}
                    buttonStyle={styles.colorPicker}
                >
                    {t("component.color_picker_input.button_text", "Change")}
                </ColorPickerButton>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    textInput: {
        flex: 1,
        paddingHorizontal: 8,
        paddingVertical: 8,
        borderStyle: "solid",
        borderRadius: theme.borderRadiusSmall,
        borderWidth: StyleSheet.hairlineWidth,
        marginBottom: 0,
        borderRightWidth: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    inputRightEnd: {
        paddingHorizontal: theme.spacingScale,
        borderRadius: theme.borderRadiusSmall,
        borderWidth: StyleSheet.hairlineWidth,
        borderLeftWidth: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderColor: theme.colors.secondary,
        height: 40,
        justifyContent: "center",
        marginRight: 10,
    },
    inputContainer: {
        ...theme.styles.flex1,
        ...theme.styles.row,
    },
    colorPickerContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        marginBottom: theme.spacingScale,
    },
    colorPicker: {
        paddingHorizontal: theme.spacingScale * 2,
    },
});
