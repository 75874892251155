import { Loading, useMerchantConfig } from "@venuepos/react-common";
import React, { useMemo, useState } from "react";
import { View } from "react-native";
import { useLayoutButtons } from "../buttons-context";
import { GridEditor } from "./grid-editor";

export function LayoutGridSection() {
    const merchantConfig = useMerchantConfig();

    const layoutData = useLayoutButtons();

    const [gridWidth, setGridWidth] = useState<number>(1000);
    const [gridHeight, setGridHeight] = useState<number>(640);

    const editorWidth = useMemo(
        () => ({
            width: gridWidth,
        }),
        [gridWidth]
    );

    const handleOnLayout = (e: {
        nativeEvent: {
            layout: {
                width: React.SetStateAction<number>;
                height: React.SetStateAction<number>;
            };
        };
    }) => {
        setGridWidth(e.nativeEvent.layout.width);
        setGridHeight(e.nativeEvent.layout.height);
    };

    if (!layoutData) {
        return <Loading />;
    }

    return (
        <View key="LayoutSections" onLayout={handleOnLayout}>
            <View
                style={editorWidth}
                key={`section_${
                    layoutData?.sections[layoutData.currentSectionIndex].id
                }`}
                testID={`layout:grid:${layoutData?.currentSectionIndex}`}
            >
                <GridEditor
                    dimensions={layoutData?.dimensions}
                    width={gridWidth}
                    height={gridHeight}
                    currency={merchantConfig.currency}
                    key={`section_${
                        layoutData?.sections[layoutData.currentSectionIndex].id
                    }_${
                        layoutData?.sections[layoutData.currentSectionIndex]
                            .label
                    }`}
                />
            </View>
        </View>
    );
}
