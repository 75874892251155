import React from "react";
import {
    ColorPickerInput,
    Form,
    InputControl,
    NumberInput,
    Spacer,
    StyleFunction,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import { BuyAccountFundsButtonType, parseAmount } from "lib";
import { View } from "react-native";

export function BuyAccountFundsSubForm({
    form: [{ errors, values }, { setValue }],
}: {
    form: Form<BuyAccountFundsButtonType>;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const merchantConfig = useMerchantConfig();

    return (
        <View style={[styles.rowContainer, styles.onTop]}>
            <InputControl
                style={styles.flex1}
                error={errors.amount}
                description={t(
                    "layout.function_button_form.buy_funds_amount",
                    "Leave field blank to let the amount be decided by the cashier"
                )}
            >
                <NumberInput
                    label={t("common.amount", "Amount")}
                    defaultValue={values?.amount ? values.amount / 100 : null}
                    onChange={value => {
                        setValue("amount", value ? parseAmount(value) : 0);
                    }}
                    min={0}
                    unit={merchantConfig.currency}
                />
            </InputControl>
            <Spacer space={2} />
            <InputControl error={errors.color} style={styles.flex1}>
                <ColorPickerInput
                    label={t(
                        "layout.function_button_form.button_color",
                        "Color of the button"
                    )}
                    defaultColor={values?.color ?? undefined}
                    onChangeColor={newColor => {
                        if (newColor !== values?.color) {
                            setValue("color", newColor ?? null);
                        }
                    }}
                />
            </InputControl>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContainer: {
        flexDirection: "row",
    },
    onTop: { zIndex: 2 },
    flex1: {
        ...theme.styles.flex1,
    },
});
