import {
    Alert,
    Button,
    CheckBox,
    ColorIndicator,
    ConfirmModal,
    DataTable,
    Icon,
    IconButton,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useModal,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    useProductGroupDeleteMutation,
    useProductGroupsQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { AdminContainer } from "../../container";
import { SearchDefinition } from "lib";
import { useSearchDefinition } from "../../../hooks";

type ScreenProps = RootStackScreenProps<"PRODUCT_GROUPS">;

export function ProductGroupListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.product");

    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const { render } = useModal();
    const { createSearchDefinition } = useSearchDefinition();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
        initialSortDirection: "ASC",
    });
    const defaultProductGroupSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        eid: {
            name: t("searching.external_id", "External ID"),
            type: "string",
            value: "",
            enabled: false,
        },
        createdAt: createSearchDefinition("createdAt"),
        color: {
            name: t("backoffice.product_group_form.color", "Color"),
            type: "string",
            value: "",
            enabled: false,
        },
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultProductGroupSearch, {
        visible: true,
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const [selectedProductGroups, setSelectedProductGroups] = useState<
        string[]
    >([]);
    const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);

    // GraphQL
    const { data, loading, error, refetch } = useProductGroupsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });
    const [deleteProductGroup] = useProductGroupDeleteMutation();
    const { handleMutationError } = useHandleMutationError();

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    // Actions
    const handleEdit = (id: string) => {
        navigate("PRODUCT_GROUP_EDIT", {
            id,
        });
    };

    const handleDelete = useCallback(
        async (id: string) => {
            await handleMutationError(
                async () => await deleteProductGroup({ variables: { id } }),
                t("backoffice.product_group.deleted", "Product group deleted"),
                async () => {
                    await refetch();
                }
            );
        },
        [deleteProductGroup, handleMutationError, refetch, t]
    );

    const handleMultiSelect = useCallback(
        (selected: boolean, itemIds: string[]) => {
            let clonedSelectedProductGroups = [...selectedProductGroups];
            if (selected) {
                clonedSelectedProductGroups = [
                    ...clonedSelectedProductGroups,
                    ...itemIds,
                ];
            } else {
                clonedSelectedProductGroups =
                    clonedSelectedProductGroups.filter(
                        i => !itemIds.includes(i)
                    );
            }
            setSelectedProductGroups(clonedSelectedProductGroups);
        },
        [selectedProductGroups]
    );

    const deleteSelectedProductGroups = useCallback(async () => {
        const deleteResponse = await render(onClose => (
            <ConfirmModal
                headerText={t(
                    "backoffice.product_groups.delete",
                    "Delete product groups?"
                )}
                bodyText={t(
                    "backoffice.product_groups.delete_explain",
                    "If you choose to delete these product groups, any buttons that are added to any of your layouts, which use these product groups, all the products in the groups, and any buttons that use these products, will be removed. This cannot be undone.\n\nAre you sure, you want to delete these product groups?"
                )}
                onClose={onClose}
            />
        ));

        if (!deleteResponse) {
            return;
        }

        await Promise.all([
            selectedProductGroups.map(groupId => handleDelete(groupId)),
        ]);
        setSelectedProductGroups([]);
        setAllRowsSelected(false);
    }, [handleDelete, render, selectedProductGroups, t]);

    const selectedCountText = useMemo(() => {
        if (selectedProductGroups.length === 0) {
            return "";
        }

        return ` (${selectedProductGroups.length})`;
    }, [selectedProductGroups]);

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer testID="productGroupListScreen">
            <View style={styles.container}>
                <View style={styles.actionButtonContainer}>
                    <Button
                        style={styles.button}
                        variant="invert"
                        disabled={!selectedProductGroups.length}
                        onPress={deleteSelectedProductGroups}
                        testID="deleteProductGroup"
                    >
                        {`${t("common.delete", "Delete")}${selectedCountText}`}
                    </Button>
                    <Spacer space={1} />
                    <Button
                        style={styles.button}
                        type="secondary"
                        onPress={() => {
                            navigate("PRODUCT_GROUP_CREATE");
                        }}
                        testID="createProductGroup"
                    >
                        {t("common.create", "Create")}
                    </Button>
                    <Spacer space={1} />
                    {searchIndicator}
                </View>
            </View>
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title style={styles.iconColumn}>
                            <CheckBox
                                value={allRowsSelected}
                                onValueChange={value => {
                                    const allItemIds =
                                        data!.productGroups.data.map(p => p.id);
                                    handleMultiSelect(value, allItemIds);
                                    setAllRowsSelected(value);
                                }}
                            />
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "externalId"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("externalId")}
                        >
                            {t("backoffice.product_group.external_id", "EID")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "isExternal"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("isExternal")}
                            style={styles.externalColumn}
                        >
                            {t(
                                "backoffice.product_group.is_external",
                                "External"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "vatId" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("vatId")}
                            style={styles.vatColumn}
                            numeric
                        >
                            {t("backoffice.product_group.vat", "VAT")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.colorColumn} />
                        <DataTable.Title style={styles.iconColumn} numeric>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data?.productGroups.data.map((item, index) => (
                            <DataTable.Row
                                key={index.toString()}
                                onPress={() => handleEdit(item.id)}
                                style={
                                    index % 2 === 1 ? styles.oddRow : undefined
                                }
                                testID={`productGroup:item:${item.name}`}
                            >
                                <DataTable.Cell style={styles.iconColumn}>
                                    <CheckBox
                                        value={selectedProductGroups.includes(
                                            item.id
                                        )}
                                        onValueChange={value =>
                                            handleMultiSelect(value, [item.id])
                                        }
                                        testID={"select:" + item.name}
                                    />
                                </DataTable.Cell>
                                <DataTable.Cell>{item.name}</DataTable.Cell>
                                <DataTable.Cell>
                                    {item.externalId}
                                </DataTable.Cell>
                                <DataTable.Cell style={styles.externalColumn}>
                                    {item.isExternal
                                        ? t("common.yes", "Yes")
                                        : null}
                                </DataTable.Cell>
                                <DataTable.Cell
                                    numeric
                                    style={styles.vatColumn}
                                >{`${item.vat.percentage}%`}</DataTable.Cell>
                                <DataTable.Cell style={styles.colorColumn}>
                                    <ColorIndicator
                                        color={item.color}
                                        copyOnClick={true}
                                        size="large"
                                    />
                                </DataTable.Cell>
                                <DataTable.Cell
                                    style={styles.iconColumn}
                                    numeric
                                >
                                    <IconButton
                                        color={theme.colors.secondary}
                                        name="edit"
                                        onPress={() => handleEdit(item.id)}
                                        testID={"edit:" + item.name}
                                    />
                                </DataTable.Cell>
                            </DataTable.Row>
                        ))
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.productGroups.pagination.pages}
                        itemCount={data?.productGroups.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",
        marginBottom: theme.spacingScale * 2,
        flexWrap: "wrap",
    },
    actionButtonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    colorColumn: {
        flexBasis: 40,
        flexGrow: 0,
        flexShrink: 0,
    },
    iconColumn: { flexBasis: 40, flexGrow: 0, flexShrink: 0 },
    externalColumn: { flexBasis: 80, flexGrow: 0, flexShrink: 0 },
    vatColumn: { flexBasis: 80, flexGrow: 0, flexShrink: 0 },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
