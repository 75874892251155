import { Link } from "@react-navigation/native";
import { StyleFunction, Text, useThemedStyle } from "@venuepos/react-common";
import { GQAccountQuery } from "graphql-sdk";
import { useTranslation } from "locales";
import React from "react";
import { View } from "react-native";

export function AccountInfo({
    name,
    customer,
    amount,
    accountStatus,
    tags,
    onAssignTags,
}: {
    name: GQAccountQuery["account"]["name"];
    customer: GQAccountQuery["account"]["customer"];
    amount: string;
    accountStatus: string;
    tags: GQAccountQuery["account"]["tags"]["data"];
    onAssignTags: () => {};
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <View>
            <View style={[styles.row, styles.firstRow]}>
                <View style={styles.labelHolder}>
                    <Text style={styles.labelText}>
                        {`${t("common.name", "Name")}:`}
                    </Text>
                </View>
                <View style={styles.valueHolder}>
                    <Text style={!name ? styles.noName : undefined}>
                        {name || t("common.no_name", "No name")}
                    </Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.labelHolder}>
                    <Text style={styles.labelText}>
                        {`${t("common.balance", "Balance")}:`}
                    </Text>
                </View>
                <View style={styles.valueHolder}>
                    <Text>{amount}</Text>
                </View>
            </View>

            <View style={styles.row}>
                <View style={styles.labelHolder}>
                    <Text style={styles.labelText}>
                        {`${t("common.status", "Status")}:`}
                    </Text>
                </View>
                <View style={styles.valueHolder}>
                    <Text>{accountStatus}</Text>
                </View>
            </View>

            {customer && (
                <View style={styles.row}>
                    <View style={styles.labelHolder}>
                        <Text style={styles.labelText}>
                            {t("common.customer", "Customer")}
                        </Text>
                    </View>
                    <View style={styles.valueHolder}>
                        <Link
                            to={{
                                screen: "CUSTOMER_VIEW",
                                params: { id: customer.id },
                            }}
                            style={[
                                styles.textLink,
                                !customer.name ? styles.noName : undefined,
                            ]}
                        >
                            {customer.name !== ""
                                ? customer.name
                                : t("common.no_name", "No name")}
                        </Link>
                    </View>
                </View>
            )}

            <View style={styles.row}>
                <View style={styles.labelHolder}>
                    <Text style={styles.labelText}>{`${t(
                        "common.tags",
                        "Tags"
                    )}:`}</Text>
                </View>
                <View style={styles.valueHolder}>
                    {tags.length > 0 ? (
                        tags.slice(0, 10).map((tag, index) => (
                            <React.Fragment key={tag.id}>
                                <Link
                                    to={{
                                        screen: "TAG_EDIT",
                                        params: { id: tag.id },
                                    }}
                                    style={styles.textLink}
                                >
                                    {tag.name || tag.identifier}
                                </Link>
                                {index !== tags.length - 1 ? (
                                    <Text>, </Text>
                                ) : null}
                            </React.Fragment>
                        ))
                    ) : (
                        <Text>
                            {t("backoffice.account.no_tags", "No tags")}
                        </Text>
                    )}
                    {tags.length > 10 ? (
                        <Text style={styles.textLink} onPress={onAssignTags}>
                            ... {t("backoffice.account.more_tags", "See more")}
                        </Text>
                    ) : null}
                </View>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    firstRow: {
        borderTopWidth: 0,
    },
    row: {
        flexDirection: "row",
        borderTopColor: theme.colors.grey100,
        borderTopWidth: 1,
        paddingTop: theme.spacingScale / 2,
    },
    labelHolder: {
        flexBasis: theme.spacingScale * 10,
        marginBottom: theme.spacingScale / 2,
    },
    labelText: {
        flex: 1,
        fontWeight: "bold",
    },

    valueHolder: {
        flex: 5,
        flexDirection: "row",
        flexWrap: "wrap",
    },

    textLink: {
        ...theme.styles.link,
    },
    noName: {
        fontStyle: "italic",
    },
});
