import {
    ModalWrapper,
    SortDirection,
    Spacer,
    StyleFunction,
    Tabs,
    useModal,
    useThemedStyle,
} from "@venuepos/react-common";
import React, { View } from "react-native";
import { useTranslation } from "locales";

import { TagsCreateTab } from "./tags-create-tab";
import { TagsFindTab } from "./tags-find-tab";
import { TagsViewTab } from "./tags-view-tab";

import type { GQPagination, GQTagScreenFieldsFragment } from "graphql-sdk";
import type { ITag } from "lib";

export type AssignTagsModalOutput = ITag["id"][];

export type TagFetchResult = {
    data: GQTagScreenFieldsFragment[];
    loading: boolean;
    pagination: Pick<
        GQPagination,
        "pages" | "sort" | "sortDirection" | "resultCount"
    >;
};

export type AssignTagsModalProps = {
    onClose: (tags?: AssignTagsModalOutput) => void;
    enabledTags: ITag["id"][];
    tagsFindQuery: (variables: {
        pagination: {
            page: number;
            pageSize: number;
            sort: string | undefined;
            sortDirection: SortDirection;
        };
        search: {
            query: string;
        };
    }) => Promise<TagFetchResult>;
};

export function AssignTagsModal({
    onClose,
    tagsFindQuery,
    enabledTags,
}: AssignTagsModalProps) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <ModalWrapper
            title={t("backoffice.account.tag_operations.tags", "Tags")}
            onClose={() => onClose(undefined)}
            showCloseIcon={true}
            testID="modal:assignTags"
        >
            <View style={styles.container}>
                <Tabs
                    labels={[
                        t("backoffice.account.tag_operations.View", "View"),
                        t("backoffice.account.tag_operations.Find", "Find"),
                        t("backoffice.account.tag_operations.Create", "Create"),
                    ]}
                >
                    <TagsViewTab onClose={onClose} enabledTags={enabledTags} />
                    <TagsFindTab
                        tagsFindQuery={tagsFindQuery}
                        onClose={onClose}
                        enabledTags={enabledTags}
                    />
                    <TagsCreateTab
                        onClose={onClose}
                        enabledTags={enabledTags}
                    />
                </Tabs>
            </View>
            <Spacer space={1} />
        </ModalWrapper>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    container: {
        padding: theme.spacingScale * 2,
        paddingTop: 0,
        minWidth: dimensions.width < 800 ? dimensions.width - 20 : 800,
        maxWidth: dimensions.width > 1000 ? 1000 : "auto",
        minHeight: 250,
        height: dimensions.height < 800 ? 800 : "100%",
    },
});

export function useAssignTagsModal() {
    const { render } = useModal<AssignTagsModalOutput | undefined>();
    return (props: Omit<AssignTagsModalProps, "onClose">) =>
        render(onClose => <AssignTagsModal {...props} onClose={onClose} />);
}
