import React, { useCallback, useState } from "react";
import {
    CheckBox,
    ColorIndicator,
    ColorPickerInput,
    Form,
    InputControl,
    InputLabel,
    Loading,
    Picker,
    Spacer,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import { useProductGroupsQuery } from "graphql-sdk";
import { View } from "react-native";
import { CustomItemButtonType } from "lib";

export function CustomItemSubForm({
    form: [{ errors, values }, { setValue }],
}: {
    form: Form<CustomItemButtonType>;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [activateColorPicker, setActivateColorPicker] =
        useState<boolean>(false);
    const [color, setColor] = useState<string | null>(null);

    const productGroupsQuery = useProductGroupsQuery({
        variables: {
            pagination: {
                pageSize: 999999,
                sort: "name",
                sortDirection: "ASC",
            },
        },
    });
    const productGroups = productGroupsQuery.data?.productGroups.data;

    const handleGroupChange = useCallback(
        selectedProductGroup => {
            if (!productGroups?.length) {
                return;
            }

            const groupData = productGroups.find(
                groupItr => groupItr.id === selectedProductGroup
            );

            if (!groupData) {
                setColor(null);
                setValue("productGroupId", null);
                return;
            }

            setColor(groupData.color);
            setValue("productGroupId", selectedProductGroup);
        },
        [productGroups, setValue]
    );

    return (
        <View style={styles.onTop}>
            <InputControl error={errors.productGroupId}>
                {!productGroups || !productGroups.length ? (
                    <Loading
                        message={t(
                            "backoffice.layout.checking_product_groups",
                            "Checking for product groups. Are there any?"
                        )}
                    />
                ) : (
                    <Picker
                        required
                        label={t(
                            "layout.function_button_form.product_group",
                            "Product group"
                        )}
                        selectedValue={values?.productGroupId || undefined}
                        onValueChange={handleGroupChange}
                    >
                        <Picker.Item
                            label={t(
                                "layout.function_button_form.choose_product_group",
                                "Choose a product group"
                            )}
                        />
                        {productGroups.map(productGroup => (
                            <Picker.Item
                                key={productGroup.id}
                                value={productGroup.id}
                                label={productGroup.name}
                            />
                        ))}
                    </Picker>
                )}
            </InputControl>
            <View style={styles.rowContainer}>
                <InputControl
                    description={t(
                        "layout.function_button_form.override_product_group_color",
                        "The button gets the colour of the product group, unless you select a different color here."
                    )}
                    style={styles.flex1}
                >
                    <View style={[styles.rowContainer, styles.colorRow]}>
                        <InputLabel>
                            {`${t(
                                "layout.button.product_group_color",
                                "Product group color"
                            )}:`}
                        </InputLabel>
                        <View style={styles.groupColor}>
                            <ColorIndicator
                                color={color ?? undefined}
                                copyOnClick={true}
                            />
                        </View>
                    </View>
                    <CheckBox
                        value={activateColorPicker}
                        onValueChange={checkboxValue => {
                            if (!checkboxValue) {
                                setValue("color", null);
                            } else {
                                setValue("color", "#73B22E");
                            }
                            setActivateColorPicker(checkboxValue);
                        }}
                        label={t(
                            "layout.button.pick_color_question",
                            "Pick a custom color?"
                        )}
                    />
                </InputControl>
                <Spacer space={2} />
                {activateColorPicker ? (
                    <InputControl error={errors.color} style={styles.flex1}>
                        <ColorPickerInput
                            defaultColor={values?.color ?? undefined}
                            label={t(
                                "layout.button.color",
                                "Custom button color"
                            )}
                            onChangeColor={newColor => {
                                if (newColor !== values?.color) {
                                    setValue("color", newColor ?? null);
                                }
                            }}
                        />
                    </InputControl>
                ) : (
                    <View style={styles.rowContent} />
                )}
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContent: {
        flex: 1,
    },
    rowContainer: {
        flexDirection: "row",
    },
    onTop: { zIndex: 2 },
    colorRow: {
        alignItems: "baseline",
        marginBottom: 8,
    },
    groupColor: {
        marginLeft: theme.spacingScale / 2,
    },
    flex1: {
        ...theme.styles.flex1,
    },
});
