import type { TFunction } from "i18next";
import { LayoutSection } from "lib";

export type LayoutReducerState = {
    sections: LayoutSection[];
    currentSectionIndex: number;
    onLayoutUpdate: (value: any) => void;
};

export type MoveActionOptions = "BEFORE" | "AFTER";

export enum LayoutAction {
    ADD_SECTION = "add_section",
    DELETE_SECTION = "delete_section",
    MOVE_SECTION = "move_section",
    RENAME_SECTION = "rename_section",
    SELECT_SECTION = "select_section",
    TOGGLE_RENAME_SECTION = "toggle_rename_section",
}

export type LayoutActionType =
    | {
          type: LayoutAction.ADD_SECTION;
          translationFunction: TFunction;
      }
    | {
          type: LayoutAction.DELETE_SECTION;
          sectionId: LayoutSection["id"];
          translationFunction: TFunction;
      }
    | {
          type: LayoutAction.MOVE_SECTION;
          sectionId: LayoutSection["id"];
          direction: MoveActionOptions;
      }
    | {
          type: LayoutAction.RENAME_SECTION;
          sectionId: LayoutSection["id"];
          newLabel: LayoutSection["label"];
      }
    | {
          type: LayoutAction.SELECT_SECTION;
          sectionId: LayoutSection["id"];
      }
    | {
          type: LayoutAction.TOGGLE_RENAME_SECTION;
          sectionId: LayoutSection["id"];
          toggleState: boolean;
      };
