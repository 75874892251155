import * as Sentry from "./sentry";
import { captureConsoleIntegration } from "@sentry/integrations";
import type { CaptureContext } from "@sentry/types";
import { Platform } from "react-native";
import { getVersion } from "react-native-device-info";
import { getAppConfig } from "../../config";
import {
    routingInstrumentation,
    startTransaction, // migrate to use spans instead
    Tracing,
} from "./sentry";
const { environment } = getAppConfig();

const useSentry = !__DEV__;
let initialized = false;

const appVersion =
    Platform.OS === "web" ? "unknown" : getVersion() || "0.0.1-dev";

export async function initErrorLogger(dsn: string) {
    if (!useSentry) {
        initialized = true;
        return;
    }

    Sentry.init({
        dsn,
        release: `${appVersion}`,
        environment,
        normalizeDepth: 0, // Capture all nested depth data
        integrations: [
            captureConsoleIntegration({
                levels: ["error", "warn"], // an array of methods that should be captured, defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
            }),
            Tracing(), // Our wrapper, uses react for web and react-native for android
        ],
        tracesSampleRate: __DEV__ ? 1.0 : 0.1, // 0.1 is normal, 1.0 for debug
        autoSessionTracking: true,
        enableAutoSessionTracking: true,
    });
    Sentry.setContext("venuepos", {
        environment,
    });
    initialized = true;
}

export function useErrorLogger() {
    if (!initialized) {
        throw new Error("Error logger not initialized properly. Run init()");
    }

    return {
        captureMessage,
        captureError,
    };
}

export function captureError(err: Error, captureContext?: CaptureContext) {
    if (useSentry) {
        Sentry.captureException(err, captureContext);
    } else {
        console.error(err);
    }
}

export function captureMessage(msg: string, captureContext?: CaptureContext) {
    console.log("captureMessage:", msg, ", context:", captureContext);

    if (useSentry) {
        Sentry.captureMessage(msg, captureContext);
    }
}

export function addLoggingTag(key: string, data?: string) {
    if (!useSentry) {
        return;
    }

    // The TS definition require data be a `string`, but the implementation handles `undefined`
    // as unsetting the previous value, and therefore the tag gets removed from the context
    Sentry.setTag(key, data as any);
}

export function addExtraLoggingData(key: string, data: any) {
    if (!useSentry) {
        return;
    }

    Sentry.setExtra(key, data);
}

// Wrapper for the Profiler
export function withProfiler(children: any) {
    if (!useSentry) {
        return children;
    }
    return Sentry.withProfiler(children);
}

// Used to track route changes for Sentry
export function registerNavigationInstrumentation(navigation: any) {
    if (!useSentry || Platform.OS === "web") {
        return;
    }
    return routingInstrumentation.registerNavigationContainer(navigation);
}

// Wrapper for measuring tasks.
interface InstrumentationSnap {
    finish: () => void;
}

// A very basic way of measuring how long something takes.
// - Usage: const snap = measure("addItem pos"); somethingExpensive(); snap.finish();
// - I'm trying very hard not to make us directly dependant on Sentry
// Maybe expand later.
export function measure(name: string): InstrumentationSnap {
    if (!useSentry) {
        return {
            finish: () => {
                return;
            },
        };
    }
    const t = startTransaction({ name });
    const snap = t.startChild({});
    return {
        finish: () => {
            snap.finish();
            t.finish();
            return;
        },
    };
}
