import { Link } from "@react-navigation/native";
import {
    Button,
    CheckBox,
    DataTable,
    IconButton,
    InputControl,
    Loading,
    RequiredText,
    StyleFunction,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import { produce } from "immer";
import React, { useCallback, useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import type { GQPaymentConfigurationFormQuery } from "graphql-sdk";
import type {
    IPaymentConfigurationInput,
    PaymentConfigurationMethodInput,
} from "lib";
import type { Form } from "@venuepos/react-common";

export function PaymentConfigurationForm(props: {
    form: Form<IPaymentConfigurationInput>;
    paymentMethodOptions: GQPaymentConfigurationFormQuery;
    initialSorting: string[];
    onSubmit: (paymentMethodOrder: string[]) => void;
    submitButton: [string, string];
    configurationId?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    const [sortedPaymentMethods, setSortedPaymentMethods] = useState<string[]>(
        props.initialSorting
    );

    const updatePaymentMethodSettings = useCallback(
        (
            paymentMethodId: string,
            changedProperty: "showInPaymentWindow" | "showAsQuickButton",
            newValue: boolean
        ) => {
            if (!values) {
                return;
            }

            const updatedSettings = produce(values.paymentMethods, draft => {
                let index = draft.findIndex(
                    itr => itr.methodId === paymentMethodId
                );

                if (index < 0) {
                    // If the changed setting is not found in the current list
                    // of added payment methods, then add it.
                    index = draft.length;
                    draft.push({
                        methodId: paymentMethodId,
                        showInPaymentWindow: false,
                        showAsQuickButton: false,
                    });
                }

                draft[index][changedProperty] = newValue;
            });

            setValue("paymentMethods", updatedSettings);
        },
        [setValue, values]
    );

    const updateItemSorting = useCallback(
        (element: string, newSortingOrder: number, oldSortingOrder: number) => {
            if (newSortingOrder < 0) {
                newSortingOrder = 0;
            }

            const updatedElementSorting = produce(
                sortedPaymentMethods,
                draft => {
                    const elementToSwap = String(draft[newSortingOrder]);
                    draft[newSortingOrder] = element;
                    draft[oldSortingOrder] = elementToSwap;
                }
            );

            setSortedPaymentMethods(updatedElementSorting);
        },
        [sortedPaymentMethods]
    );

    if (!values || !props.paymentMethodOptions) {
        return <Loading />;
    }

    return (
        <View style={styles.container}>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    testID="name"
                    required={true}
                />
            </InputControl>

            <View style={styles.table}>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title>
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.payment_configuration.show_in_payment_window",
                                "In payment window?"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.payment_configuration.show_as_quick_button",
                                "As payment button?"
                            )}
                        </DataTable.Title>
                        <DataTable.Title style={styles.iconColumn} />
                        <DataTable.Title style={styles.iconColumn} />
                    </DataTable.Header>

                    {sortedPaymentMethods.map((paymentMethodId, index) => {
                        const paymentMethod =
                            props.paymentMethodOptions.paymentMethods.data.find(
                                pmItr => pmItr.id === paymentMethodId
                            );
                        const configuration = values.paymentMethods.find(
                            valueItr => valueItr.methodId === paymentMethodId
                        );

                        if (!paymentMethod) {
                            return null;
                        }

                        return (
                            <PaymentMethodTableRow
                                key={index.toString()}
                                rowName={paymentMethod.name}
                                paymentConfigId={props.configurationId}
                                paymentMethodId={paymentMethod.id}
                                configuration={configuration}
                                index={index}
                                enabled={paymentMethod.enabled}
                                showUpButton={index > 0}
                                showDownButton={
                                    index + 1 < sortedPaymentMethods.length
                                }
                                onUpdatePaymentMethodSettings={(
                                    changedProperty,
                                    newValue
                                ) =>
                                    updatePaymentMethodSettings(
                                        paymentMethod.id,
                                        changedProperty,
                                        newValue
                                    )
                                }
                                onMoveUp={() =>
                                    updateItemSorting(
                                        paymentMethod.id,
                                        index - 1,
                                        index
                                    )
                                }
                                onMoveDown={() =>
                                    updateItemSorting(
                                        paymentMethod.id,
                                        index + 1,
                                        index
                                    )
                                }
                            />
                        );
                    })}
                </DataTable>
            </View>

            <Button
                onPress={handleSubmit(() =>
                    props.onSubmit(sortedPaymentMethods)
                )}
                testID="paymentConfiguration:save"
            >
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>
            <RequiredText />
        </View>
    );
}

function PaymentMethodTableRow({
    rowName,
    paymentConfigId,
    paymentMethodId,
    configuration,
    index,
    enabled,
    showUpButton,
    showDownButton,
    onUpdatePaymentMethodSettings,
    onMoveUp,
    onMoveDown,
}: {
    rowName: string;
    paymentConfigId?: string;
    paymentMethodId: string;
    configuration: PaymentConfigurationMethodInput;
    index: number;
    enabled: boolean;
    showUpButton: boolean;
    showDownButton: boolean;
    onUpdatePaymentMethodSettings: (
        changedProperty: "showInPaymentWindow" | "showAsQuickButton",
        newValue: boolean
    ) => any;
    onMoveUp: () => void;
    onMoveDown: () => void;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();

    return (
        <DataTable.Row
            testID={"paymentConfiguration:index:" + index}
            style={index % 2 ? undefined : styles.oddRow}
        >
            <DataTable.Cell>
                <Link
                    numberOfLines={1}
                    to={{
                        screen: "PAYMENT_METHOD_EDIT",
                        params: {
                            id: paymentMethodId,
                            referrer: {
                                id: paymentConfigId ?? "",
                                route: "PAYMENT_CONFIGURATION_EDIT",
                            },
                        },
                    }}
                    style={styles.textLink}
                >
                    {rowName}
                </Link>
            </DataTable.Cell>
            <DataTable.Cell>
                <CheckBox
                    disabled={!enabled}
                    label={t("backoffice.payment_configuration.show", "Show")}
                    value={configuration?.showInPaymentWindow ?? false}
                    onValueChange={newValue =>
                        onUpdatePaymentMethodSettings(
                            "showInPaymentWindow",
                            newValue
                        )
                    }
                    testID={"showInPaymentWindow:" + rowName}
                />
            </DataTable.Cell>
            <DataTable.Cell>
                <CheckBox
                    disabled={!enabled}
                    label={t("backoffice.payment_configuration.show", "Show")}
                    value={configuration?.showAsQuickButton ?? false}
                    onValueChange={newValue =>
                        onUpdatePaymentMethodSettings(
                            "showAsQuickButton",
                            newValue
                        )
                    }
                    testID={"showAsQuickButton:" + rowName}
                />
            </DataTable.Cell>
            <DataTable.Cell style={styles.iconColumn}>
                {showUpButton ? (
                    <IconButton
                        color={styles.iconColor.color}
                        name="angle-up"
                        onPress={onMoveUp}
                        testID="moveUp"
                    />
                ) : null}
            </DataTable.Cell>
            <DataTable.Cell style={styles.iconColumn}>
                {showDownButton ? (
                    <IconButton
                        color={styles.iconColor.color}
                        name="angle-down"
                        onPress={onMoveDown}
                        testID="moveDown"
                    />
                ) : null}
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        width: 800,
    },
    table: {
        marginBottom: theme.spacingScale * 2,
    },
    iconColumn: {
        justifyContent: "center",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
    },
    iconColor: {
        color: theme.colors.textDark,
    },
    textLink: {
        ...theme.styles.link,
    },
    oddRow: {
        ...theme.styles.oddRow,
    },
});
