export type TagTypeValue = "NFC_WRISTBAND" | "NFC_CARD" | "BARCODE";

export type TagType = {
    label: string;
    value: TagTypeValue;
};

export const tagTypeNfcWristband: TagType = {
    label: "tag.type.wristband_nfc",
    value: "NFC_WRISTBAND",
};

export const tagTypeNfcCard: TagType = {
    label: "tag.type.card_nfc",
    value: "NFC_CARD",
};

export const tagTypeBarcode: TagType = {
    label: "tag.type.barcode",
    value: "BARCODE",
};

export const tagTypes: TagType[] = [
    tagTypeNfcWristband,
    tagTypeNfcCard,
    tagTypeBarcode,
];
