import { Link } from "@react-navigation/native";
import {
    DataTable,
    IconButton,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import { formatAmount, formatDateTime, SupportedCurrenciesType } from "lib";
import React from "react";

import type { GQCustomersQuery } from "graphql-sdk";
import type { AvailableLocale } from "locales";
import { StyleProp, ViewStyle } from "react-native";

export function CustomerItem({
    item,
    onEdit,
    onDelete,
    locale,
    currency,
    style,
}: {
    item: GQCustomersQuery["customers"]["data"][0];
    onEdit: () => void;
    onDelete: () => void;
    locale: AvailableLocale;
    currency: SupportedCurrenciesType;
    style?: StyleProp<ViewStyle>;
}) {
    const styles = useThemedStyle(styleFunc);
    const customerBalance = item.accounts.reduce(
        (acc, account) => acc + account.amount,
        0
    );

    return (
        <DataTable.Row testID={"customer:" + item.name} style={style}>
            <DataTable.Cell>
                <Link
                    to={{ screen: "CUSTOMER_VIEW", params: { id: item.id } }}
                    style={styles.textLink}
                    numberOfLines={1}
                >
                    {item.name}
                </Link>
            </DataTable.Cell>
            <DataTable.Cell>
                {item.group && (
                    <Link
                        to={{
                            screen: "CUSTOMER_GROUP_EDIT",
                            params: { id: item.group?.id },
                        }}
                        style={styles.textLink}
                        numberOfLines={1}
                    >
                        {item.group?.name}
                    </Link>
                )}
            </DataTable.Cell>
            <DataTable.Cell>
                {formatDateTime(item.createdAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell numeric>
                {formatAmount(customerBalance, currency, {
                    printCurrency: true,
                    locale,
                })}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                <IconButton
                    color={styles.iconColumn.color}
                    name="edit"
                    onPress={onEdit}
                />
                <IconButton
                    onPress={onDelete}
                    name="delete"
                    color={styles.iconColumn.color}
                    testID={"customer:delete:" + item.name}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    checkbox: {
        marginVertical: 6,
    },
    iconColumn: {
        flexBasis: 100,
        flexGrow: 0,
        flexShrink: 0,
        color: theme.colors.secondary,
    },
    textLink: {
        ...theme.styles.link,
    },
});
