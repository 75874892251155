import { useFocusEffect } from "@react-navigation/native";
import {
    Headline,
    LoadingScreen,
    Spacer,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQTagInput,
    useTagFormQuery,
    useTagLazyQuery,
    useTagSaveMutation,
} from "graphql-sdk";
import { schemaTag } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { Form } from "../form";
import { TagScreen } from "../tag-screen";

import type { StyleFunction } from "@venuepos/react-common";
import { Account } from "./account";
import { Customer } from "./customer";
import { TagHistory } from "./history";
import { useAdminSession } from "../../../session";
import { AvailableLocale } from "locales";

type ScreenProps = RootStackScreenProps<"TAG_EDIT">;

export function TagEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.tag");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const tagId = route.params.id;
    const [{ locale }] = useAdminSession(["locale"]);

    const [tagEdit] = useTagSaveMutation();
    const [getTag, tag] = useTagLazyQuery({
        fetchPolicy: "no-cache",
    });

    const form = useForm<GQTagInput>(schemaTag, null);
    const [{ values }, { setDefaultValues }] = form;
    const { handleMutationError } = useHandleMutationError();
    const { data, refetch } = useTagFormQuery();

    useEffect(() => {
        if (!tagId) {
            navigate("TAGS");
            return;
        }

        getTag({ variables: { id: tagId } });
    }, [getTag, navigate, tagId]);

    useEffect(() => {
        if (tag.data && tag.data.tag) {
            const tagData = tag.data.tag;
            setDefaultValues({
                identifier: tagData.identifier,
                tagType: tagData.tagType,
                name: tagData.name || "",
                groupId: tagData.group?.id || "",
            });
        }
    }, [tag.data, setDefaultValues]);

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    const handleEdit = useCallback(async () => {
        if (!tag.data || !tag.data.tag || !values) {
            return;
        }

        await handleMutationError(
            async () =>
                await tagEdit({
                    variables: {
                        id: tag.data!.tag.id,
                        tag: {
                            identifier: values.identifier,
                            tagType: values.tagType,
                            name: values.name || null,
                            groupId: values.groupId || null,
                            accountId: tag.data?.tag.account?.id,
                            customerId: tag.data?.tag.customer?.id,
                        },
                    },
                }),
            t("backoffice.tag.saved", "Tag saved"),
            () => {
                navigate("TAGS");
            }
        );
    }, [handleMutationError, navigate, t, tag.data, tagEdit, values]);

    if (!tag.data || !tag.data.tag) {
        return <LoadingScreen style="light" />;
    }

    return (
        <TagScreen>
            <View style={styles.container}>
                <View style={styles.left}>
                    <Form
                        form={form}
                        formData={data}
                        onSubmit={handleEdit}
                        submitButton={["common.save", "Save"]}
                    />
                </View>
                <View style={styles.right}>
                    {tag.data.tag.account || tag.data.tag.customer ? (
                        <>
                            <Surface>
                                <Headline size="h5">
                                    {t("common.links", "Links")}
                                </Headline>
                                {tag.data.tag.account ? (
                                    <Account
                                        account={tag.data.tag.account.id}
                                    />
                                ) : null}
                                {tag.data.tag.customer ? (
                                    <Customer
                                        customer={tag.data.tag.customer.id}
                                    />
                                ) : null}
                            </Surface>
                            <Spacer space={2} />
                        </>
                    ) : null}
                    {tag.data.tag.actionsForTag.data ? (
                        <>
                            <Surface>
                                <View style={styles.tableHeaders}>
                                    <Headline size="h4">
                                        {t(
                                            "backoffice.tag.actions.header",
                                            "Tag actions"
                                        )}
                                    </Headline>
                                    <Headline size="h5">
                                        {tag.data.tag?.actionsForTag.pagination
                                            ? t(
                                                  "common.results",
                                                  "{{count}} results",
                                                  {
                                                      count: tag.data.tag
                                                          .actionsForTag
                                                          ?.pagination
                                                          .resultCount,
                                                  }
                                              )
                                            : null}
                                    </Headline>
                                </View>
                                <TagHistory
                                    actions={tag.data.tag.actionsForTag.data}
                                    locale={locale as AvailableLocale}
                                />
                            </Surface>
                            <Spacer space={2} />
                        </>
                    ) : null}
                </View>
            </View>
        </TagScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    left: {
        marginBottom: theme.spacingScale * 2,
        marginRight: theme.spacingScale * 2,
        minWidth: 400,
    },
    right: {
        flex: 1,
        minWidth: 450,
        maxWidth: 900,
    },
    tableHeaders: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
});
