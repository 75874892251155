import React from "react";
import {
    DataTable,
    Spacer,
    StyleFunction,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { AvailableLocale, useTranslation } from "locales";
import { GQTagAction } from "graphql-sdk";
import { Link } from "@react-navigation/native";
import { formatDateTime } from "lib";
import { View } from "react-native";

export function TagHistory({
    actions,
    locale,
}: {
    actions: GQTagAction[];
    locale: AvailableLocale;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    // i18next t("backoffice.tag.action.values.TAG_ASSIGNED", "Assigned to")
    // i18next t("backoffice.tag.action.values.TAG_UNASSIGNED", "Removed from")

    return (
        <View>
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title>
                        {t("backoffice.tag.action.header", "Action")}
                    </DataTable.Title>
                    <DataTable.Title>
                        {t("common.account", "Account")}
                    </DataTable.Title>
                    <DataTable.Title>
                        {t("common.user", "User")}
                    </DataTable.Title>
                    <DataTable.Title>
                        {t("common.updated_at", "Updated at")}
                    </DataTable.Title>
                </DataTable.Header>
                {actions.map((item, index) => (
                    <DataTable.Row
                        style={index % 2 === 0 ? styles.oddRow : undefined}
                        key={index}
                    >
                        <DataTable.Cell>
                            {t(`backoffice.tag.action.values.${item.action}`)}
                        </DataTable.Cell>
                        <DataTable.Cell>
                            <Link
                                to={{
                                    screen: "ACCOUNT_VIEW",
                                    params: { id: item.accountId },
                                }}
                                style={styles.textLink}
                                numberOfLines={2}
                            >
                                {item.accountName}
                            </Link>
                        </DataTable.Cell>
                        <DataTable.Cell>{item.userName}</DataTable.Cell>
                        <DataTable.Cell>
                            {formatDateTime(item.createdAt, locale)}
                        </DataTable.Cell>
                    </DataTable.Row>
                ))}
            </DataTable>
            {!actions.length ? (
                <View>
                    <Spacer space={1} />
                    <Text style={styles.noteText}>
                        {t(
                            "backoffice.tag.actions.none_found",
                            "This tag was never assigned nor removed."
                        )}
                    </Text>
                </View>
            ) : null}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
    textLink: {
        ...theme.styles.link,
    },
    noteText: {
        fontStyle: "italic",
    },
});
