import produce from "immer";
import { dateToDateTime } from "lib";
import {
    BooleanSearch,
    DateSearch,
    NumberSearch,
    PercentSearch,
    SearchDef,
    SearchDefinition,
    StringSearch,
    TypeSearch,
} from "lib/src/search/types";
import React, { useMemo } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { Divider } from "react-native-paper";
import { StyleFunction, useTheme, useThemedStyle } from "../../theme";
import { Button } from "../button";
import { CheckBox } from "../check-box";
import { DateTimePickerInput } from "../datetime-picker-input";
import { Form } from "../form";
import { IconButton } from "../icon-button";
import { InputControl } from "../input-control";
import { InputLabel } from "../input-label";
import { NumberInput } from "../number-input";
import { Picker } from "../picker";
import { Spacer } from "../spacer";
import { TextInput } from "../textinput";

export function SearchForm(props: {
    form: Form<SearchDefinition>;
    onSubmit: () => void;
    onCloseSearch: () => void;
    visible: boolean;
    hideCloseButton?: boolean;
}) {
    const [t] = useTranslation();
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);

    const [{ values }, { setValue, handleSubmit }] = props.form;

    const components = useMemo(() => {
        if (!values) {
            return null;
        }

        return Object.keys(values).map((elem: keyof SearchDefinition) => {
            const element = values[elem];
            let component;
            switch (element.type) {
                case "amount":
                    component = (
                        <NumberInput
                            label={element.name}
                            placeholder={t(
                                "searching.enter",
                                "Enter {{name}}",
                                { name: element.name }
                            )}
                            onChange={text => {
                                setValue(
                                    elem as keyof SearchDef,
                                    produce(element, draft => {
                                        draft.value = text ?? 0;

                                        // If the search field is not yet enabled, then do this, since the user is fiddling with it.
                                        draft.enabled =
                                            text === null ? false : true;
                                    })
                                );
                            }}
                            defaultValue={element.value}
                        />
                    );
                    break;
                case "percent":
                    component = (
                        <NumberInput
                            label={element.name}
                            placeholder={t(
                                "searching.enter",
                                "Enter {{name}}",
                                { name: element.name }
                            )}
                            unit="%"
                            min={0} // rate, equal to 0%
                            max={1} // rate, equal to 100%
                            percentageToRate={true}
                            decimals={2}
                            onChange={text => {
                                setValue(
                                    elem,
                                    produce(element as PercentSearch, draft => {
                                        draft.value = text ?? 0;

                                        // If the search field is not yet enabled, then do this, since the user is fiddling with it.
                                        draft.enabled =
                                            text === null ? false : true;
                                    })
                                );
                            }}
                            defaultValue={element.value}
                        />
                    );
                    break;
                case "boolean":
                    component = (
                        <CheckBox
                            label={element.name}
                            onValueChange={value => {
                                setValue(
                                    elem,
                                    produce(element as BooleanSearch, draft => {
                                        draft.value = value;

                                        // If the search field is not yet enabled, then do this, since the user is fiddling with it.
                                        if (!element.enabled) {
                                            draft.enabled = true;
                                        }
                                    })
                                );
                            }}
                            value={element.value}
                        />
                    );
                    break;
                case "string":
                    component = (
                        <TextInput
                            label={element.name}
                            placeholder={t(
                                "searching.enter",
                                "Enter {{name}}",
                                { name: element.name.toLowerCase() }
                            )}
                            onChangeText={text => {
                                setValue(
                                    elem,
                                    produce(element as StringSearch, draft => {
                                        draft.value = text;

                                        // If the search field is not yet enabled, then do this, since the user is fiddling with it.
                                        draft.enabled =
                                            text === "" ? false : true;
                                    })
                                );
                            }}
                            defaultValue={element.value}
                            onSubmitEditing={handleSubmit(props.onSubmit)}
                            blurOnSubmit={false}
                        />
                    );
                    break;
                case "number":
                    component = (
                        <NumberInput
                            label={element.name}
                            placeholder={t(
                                "searching.enter",
                                "Enter {{name}}",
                                { name: element.name }
                            )}
                            onChange={text => {
                                setValue(
                                    elem,
                                    produce(element as NumberSearch, draft => {
                                        draft.value = text ?? 0;

                                        // If the search field is not yet enabled, then do this, since the user is fiddling with it.
                                        draft.enabled =
                                            text === null ? false : true;
                                    })
                                );
                            }}
                            defaultValue={element.value}
                        />
                    );
                    break;
                case "date":
                    component = (
                        <>
                            <InputLabel>{element.name}</InputLabel>
                            <DateTimePickerInput
                                dateTimeType="dateTime"
                                onChangeDate={dateObj => {
                                    if (dateObj === undefined) {
                                        return;
                                    }
                                    setValue(
                                        elem,
                                        produce(
                                            element as DateSearch,
                                            draft => {
                                                draft.values.from = dateObj;

                                                // If the search field is not yet enabled, then do this, since the user is fiddling with it.
                                                if (!element.enabled) {
                                                    draft.enabled = true;
                                                }
                                            }
                                        )
                                    );
                                }}
                                value={dateToDateTime(element.values.from)}
                            />
                            <DateTimePickerInput
                                dateTimeType="dateTime"
                                onChangeDate={dateObj => {
                                    if (dateObj === undefined) {
                                        return;
                                    }
                                    setValue(
                                        elem,
                                        produce(
                                            element as DateSearch,
                                            draft => {
                                                draft.values.to = dateObj;

                                                // If the search field is not yet enabled, then do this, since the user is fiddling with it.
                                                if (!element.enabled) {
                                                    draft.enabled = true;
                                                }
                                            }
                                        )
                                    );
                                }}
                                value={dateToDateTime(element.values.to)}
                            />
                        </>
                    );
                    break;
                case "type":
                    component = (
                        <Picker
                            label={element.name}
                            onValueChange={value => {
                                if (value === undefined) {
                                    return;
                                }
                                setValue(
                                    elem,
                                    produce(element as TypeSearch, draft => {
                                        draft.value = value;

                                        // If the search field is not yet enabled, then do this, since the user is fiddling with it.
                                        if (!element.enabled) {
                                            draft.enabled = true;
                                        }
                                    })
                                );
                            }}
                            selectedValue={element.value}
                        >
                            {element.values.map(item => (
                                <Picker.Item
                                    key={`search-${element.name}-${item.value}`}
                                    label={item.name}
                                    value={item.value}
                                />
                            ))}
                        </Picker>
                    );
                    break;
            }

            return (
                <View
                    key={`search-${element.name}`}
                    style={[theme.styles.row, styles.searchComponent]}
                >
                    <View style={styles.checkbox}>
                        <CheckBox
                            onValueChange={value =>
                                setValue(
                                    elem,
                                    produce(element, draft => {
                                        draft.enabled = value;
                                    })
                                )
                            }
                            value={element.enabled}
                        />
                    </View>
                    <Spacer />
                    <InputControl
                        style={theme.styles.flex}
                        description={element.description}
                    >
                        {component}
                    </InputControl>
                    <Spacer space={3} />
                </View>
            );
        });
    }, [
        handleSubmit,
        props.onSubmit,
        setValue,
        styles.checkbox,
        styles.searchComponent,
        t,
        theme.styles.flex,
        theme.styles.row,
        values,
    ]);

    return props.visible ? (
        <View>
            {!props.hideCloseButton ? (
                <IconButton
                    name="close"
                    style={styles.closeIcon}
                    onPress={props.onCloseSearch}
                />
            ) : null}
            <View style={styles.searchComponents}>{components}</View>
            <View style={theme.styles.row}>
                <Button onPress={handleSubmit(props.onSubmit)} size="small">
                    {t("common.search", "Search")}
                </Button>
            </View>
            <Spacer space={2} />
            <Divider />
        </View>
    ) : null;
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    checkbox: {
        paddingTop: theme.spacingScale * 3, // Adjusted to fit the text height in the input fields.
    },
    closeIcon: {
        color: theme.colors.primary,
        position: "absolute",
        top: 0,
        right: 0,
        elevation: 1,
        zIndex: 1,
    },
    searchComponents: {
        ...theme.styles.row,
        flexWrap: "wrap",
    },
    searchComponent: {
        maxWidth:
            dimensions.width > theme.breakpoint.largeTabletWidth
                ? "25%"
                : dimensions.width > theme.breakpoint.tabletWidth
                ? "33.3%"
                : "100%",
        minWidth: 250,
    },
});
