import {
    Hoverable,
    Icon,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import React from "react";
import { Pressable, StyleSheet, View } from "react-native";

export function GridEditorDroppable({
    onPress,
    testID,
}: {
    onPress: () => void;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);

    return (
        <Hoverable>
            {isHovered => (
                <View style={styles.cellContent}>
                    <Pressable
                        style={[
                            styles.button,
                            isHovered ? styles.hoveredButton : null,
                        ]}
                        onPress={onPress}
                        testID={testID}
                    >
                        <Icon
                            style={[
                                styles.droppable,
                                isHovered ? styles.hoveredCell : null,
                            ]}
                            name="plus"
                        />
                    </Pressable>
                </View>
            )}
        </Hoverable>
    );
}

const styleFunc: StyleFunction = theme => ({
    cellContent: {
        flex: 1,
        backgroundColor: theme.colors.grey50,
        borderWidth: StyleSheet.hairlineWidth,
        borderStyle: "solid",
        borderColor: theme.colors.grey500,
        borderRadius: theme.borderRadiusTiny,
    },
    button: {
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
    hoveredButton: {
        backgroundColor: theme.colors.grey100,
        borderRadius: theme.borderRadiusTiny,
    },
    droppable: {
        color: theme.colors.grey500,
    },
    hoveredCell: {
        color: theme.colors.textDark,
    },
});
