import React, { useCallback } from "react";
import type { GQVatsQuery } from "graphql-sdk";
import { StyleProp, ViewStyle } from "react-native";
import {
    useTheme,
    DataTable,
    IconButton,
    Text,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import { formatDateTime } from "lib";
import { useAdminSession } from "../../session";
import type { AvailableLocale } from "locales";

export function VatRow({
    item,
    onDelete,
    style,
}: {
    item: GQVatsQuery["vats"]["data"][0];
    onDelete: (itemId: string) => void;
    style?: StyleProp<ViewStyle>;
}) {
    const theme = useTheme();
    const [{ locale }] = useAdminSession(["locale"]);
    const styles = useThemedStyle(styleFunc);

    const handleDelete = useCallback(() => {
        onDelete(item.id);
    }, [item.id, onDelete]);

    return (
        <DataTable.Row style={style}>
            <DataTable.Cell>{item.name}</DataTable.Cell>
            <DataTable.Cell>
                <Text>{item.percentage} %</Text>
            </DataTable.Cell>
            <DataTable.Cell>
                {formatDateTime(item.createdAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                <IconButton
                    color={theme.colors.secondary}
                    name="delete"
                    onPress={handleDelete}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = () => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
    },
});
