import {
    Button,
    CheckBox,
    ColorIndicator,
    ColorPickerInput,
    Form,
    Headline,
    InputControl,
    InputLabel,
    Loading,
    Spacer,
    StyleFunction,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import React, { useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { ButtonSizeForm } from "../../../../button-size-form";

import type { ILayoutProduct, LayoutButtonMetaData, ProductButton } from "lib";
export function ProductButtonForm(props: {
    form: Form<ProductButton>;
    product: ILayoutProduct;
    onSubmit: () => void;
    submitButton: [string, string];
    metaData: LayoutButtonMetaData;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const [activateColorPicker, setActivateColorPicker] = useState<boolean>(
        (values &&
            values.color &&
            props.product.group &&
            props.product.group.color &&
            props.product.group.color !== values.color) ||
            false
    );

    const initialButtonColor =
        values?.color || props.product.group?.color || "#73B22E";

    if (!values) {
        return <Loading />;
    }

    return (
        <>
            <Headline size="h2">{props.product.name}</Headline>
            <ButtonSizeForm
                form={props.form as any}
                metaData={props.metaData}
            />
            <View>
                <InputControl error={errors.label}>
                    <TextInput
                        label={t(
                            "layout.button.label",
                            "The name on this button"
                        )}
                        placeholder={t(
                            "layout.button.label_placeholder",
                            `If this field is left blank, the button will be labelled {{ label }}.`,
                            {
                                label:
                                    props.product.buttonText ||
                                    props.product.name,
                                interpolation: { escapeValue: false },
                            }
                        )}
                        value={values.label}
                        onChangeText={text => {
                            setValue("label", text);
                        }}
                        onClear={() => {
                            setValue("label", "");
                        }}
                    />
                </InputControl>
                <View style={[styles.rowContainer, styles.onTop]}>
                    <View style={styles.rowContent}>
                        <InputControl
                            description={t(
                                "layout.function_button_form.override_product_group_color",
                                "The button gets the colour of the product group, unless you select a different color here."
                            )}
                        >
                            <View
                                style={[styles.rowContainer, styles.colorRow]}
                            >
                                <InputLabel>
                                    {`${t(
                                        "layout.button.product_group_color",
                                        "Product group color"
                                    )}:`}
                                </InputLabel>
                                <Spacer />
                                <ColorIndicator
                                    color={props.product.group?.color}
                                    copyOnClick={true}
                                    animate={false}
                                    size="normal"
                                />
                            </View>
                            <CheckBox
                                value={activateColorPicker}
                                onValueChange={checkboxValue => {
                                    if (!checkboxValue) {
                                        setValue("color", null);
                                    } else {
                                        setValue("color", initialButtonColor);
                                    }
                                    setActivateColorPicker(checkboxValue);
                                }}
                                label={t(
                                    "layout.button.pick_color_question",
                                    "Pick a custom color?"
                                )}
                            />
                            <Spacer />
                        </InputControl>
                    </View>
                    <Spacer space={2} />
                    {activateColorPicker ? (
                        <InputControl error={errors.color}>
                            <ColorPickerInput
                                defaultColor={values.color ?? undefined}
                                label={t(
                                    "layout.button.color",
                                    "Custom button color"
                                )}
                                onChangeColor={newColor => {
                                    if (
                                        newColor !== props.product.group?.color
                                    ) {
                                        setValue("color", newColor ?? null);
                                    }
                                }}
                            />
                        </InputControl>
                    ) : (
                        <View style={styles.rowContent} />
                    )}
                </View>
                <Button
                    type="primary"
                    onPress={handleSubmit(props.onSubmit)}
                    testID="layout:editButton:save"
                >
                    {t(props.submitButton[0], props.submitButton[1])}
                </Button>
            </View>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContent: {
        flex: 1,
    },
    rowContainer: {
        flexDirection: "row",
    },
    onTop: { zIndex: 2 },
    colorRow: {
        alignItems: "baseline",
        marginBottom: 8, // To match the height/layout of the right side of the row.
    },
    groupColor: {
        marginLeft: theme.spacingScale / 2,
    },
});
