import React, { useState, useCallback } from "react";
import { useTranslation } from "locales";
import type {
    GQProductLayoutFormQuery,
    GQTableLayoutFormQuery,
} from "graphql-sdk";
import {
    Form,
    InputControl,
    Picker,
    Button,
    Text,
    TextInput,
    NumberInput,
    StyleFunction,
    useThemedStyle,
    Spacer,
    InputLabel,
} from "@venuepos/react-common";
import { ILayoutCreate, LayoutGridDefinition } from "lib";
import { View } from "react-native";

export function LayoutCreateForm(props: {
    form: Form<ILayoutCreate>;
    data: GQTableLayoutFormQuery | GQProductLayoutFormQuery;
    onSubmit: () => void;
    submitButton: [string, string];
    layoutSizes: any[];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const [columnCount, setColumnCount] = useState<number>(1);
    const [rowCount, setRowCount] = useState<number>(1);
    const [selectedGridSize, setSelectedGridSize] =
        useState<LayoutGridDefinition["label"]>("none");

    const handleColumnCountChange = useCallback(
        newValue => {
            if (typeof newValue !== "number") {
                setColumnCount(1);
            } else {
                setColumnCount(newValue);
            }
            setValue("dimensions", {
                columns: newValue!,
                rows: rowCount,
            });
            setSelectedGridSize("custom");
        },
        [rowCount, setValue]
    );

    const handleRowCountChange = useCallback(
        newValue => {
            if (typeof newValue !== "number") {
                setRowCount(1);
            } else {
                setRowCount(newValue);
            }
            setValue("dimensions", {
                columns: columnCount,
                rows: newValue!,
            });
            setSelectedGridSize("custom");
        },
        [columnCount, setValue]
    );

    const handleLayoutChange = useCallback(
        value => {
            setSelectedGridSize(value);

            if (value === "custom") {
                return;
            }

            let newColumnCount: number;
            let newRowCount: number;

            if (
                value === "none" ||
                value < 0 ||
                value >= props.layoutSizes.length
            ) {
                newColumnCount = 1;
                newRowCount = 1;
            } else {
                const selectedLayoutSize = props.layoutSizes[Number(value)];

                newColumnCount = selectedLayoutSize.columns;
                newRowCount = selectedLayoutSize.rows;
            }

            // Update form values
            setColumnCount(newColumnCount);
            setRowCount(newRowCount);
            setValue("dimensions", {
                columns: newColumnCount,
                rows: newRowCount,
            });
        },
        [props.layoutSizes, setValue]
    );

    if (!values || !props.data) {
        return null;
    }

    return (
        <>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    testID="layout:name"
                    required={true}
                />
            </InputControl>

            <InputControl error={errors.dimensions}>
                <Picker
                    label={t(
                        "backoffice.layout_form.size",
                        "Select a layout format"
                    )}
                    onValueChange={handleLayoutChange}
                    testID="layout:dimensions"
                    selectedValue={selectedGridSize}
                >
                    <Picker.Item
                        label={t(
                            "backoffice.layout_form.pick_format",
                            "Pick a format"
                        )}
                        value="none"
                    />
                    {props.layoutSizes.map((layout, index) => {
                        return (
                            <Picker.Item
                                label={layout.label}
                                value={String(index)}
                                key={`layout_${index}`}
                                testID={"layout:dimensions:" + layout.label}
                            />
                        );
                    })}
                    <Picker.Item
                        label={t(
                            "backoffice.layout_form.custom_format",
                            "Custom"
                        )}
                        value="custom"
                    />
                </Picker>
            </InputControl>

            <InputControl error={errors.dimensions}>
                <InputLabel>{t("backoffice.common.size", "Size")}</InputLabel>
                <View style={styles.rowContainer}>
                    <View style={styles.column}>
                        <NumberInput
                            value={columnCount}
                            unit={t(
                                "backoffice.layout_form.columns",
                                "Columns"
                            )}
                            decimals={0}
                            max={10}
                            min={1}
                            onChange={handleColumnCountChange}
                            testID="layout:columns"
                        />
                    </View>
                    <Spacer />
                    <View>
                        <Text>×</Text>
                    </View>
                    <Spacer />
                    <View style={styles.column}>
                        <NumberInput
                            value={rowCount}
                            unit={t("backoffice.layout_form.rows", "Rows")}
                            decimals={0}
                            max={10}
                            min={1}
                            onChange={handleRowCountChange}
                            testID="layout:rows"
                        />
                    </View>
                </View>
            </InputControl>

            <Button onPress={handleSubmit(props.onSubmit)} testID="layout:save">
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>
        </>
    );
}

const styleFunc: StyleFunction = () => ({
    rowContainer: {
        flexDirection: "row",
        flex: 1,
        alignItems: "baseline",
    },
    column: { flex: 1 },
});
