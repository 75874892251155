import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
// import FontAwesome6Icons from "react-native-vector-icons/FontAwesome5Pro";
import FontAwesome6Icons from "react-native-vector-icons/FontAwesome6Pro";
import { FontAwesome6IconVariants } from "react-native-vector-icons/FontAwesome6";

export const availableIcons: {
    [key: string]: {
        name: string;
        component?: any; // Make this match MaterialIcons and what not, sometimes TS sucks
        variant?: FontAwesome6IconVariants;
    };
} = {
    asterisk: {
        name: "asterisk",
        component: FontAwesome6Icons,
    },
    "add-to-layout": {
        name: "grid-2-plus",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    "caret-right": {
        name: "caret-right",
        component: FontAwesome6Icons,
    },
    "caret-left": {
        name: "caret-left",
        component: FontAwesome6Icons,
    },
    "caret-up": {
        name: "caret-up",
        component: FontAwesome6Icons,
    },
    "caret-down": {
        name: "caret-down",
        component: FontAwesome6Icons,
    },
    cart: {
        name: "shopping-basket",
        component: FontAwesome6Icons,
    },
    "chart-mixed": {
        name: "chart-mixed",
        component: FontAwesome6Icons,
    },
    closedCircle: {
        name: "times-circle",
        component: FontAwesome6Icons,
    },
    closedReceipts: {
        name: "library-add-check",
    },
    copy: {
        name: "copy",
        component: FontAwesome6Icons,
    },
    "customer-search": {
        name: "account-search",
        component: MaterialCommunityIcons,
    },
    counter: {
        name: "counter",
        component: MaterialCommunityIcons,
    },
    delete: {
        name: "trash-alt",
        component: FontAwesome6Icons,
    },
    "desktop-classic": {
        name: "desktop-classic",
        component: MaterialCommunityIcons,
    },
    eye: {
        name: "eye",
        component: FontAwesome6Icons,
    },
    help: {
        name: "life-ring",
        component: FontAwesome6Icons,
    },
    label: {
        name: "tags",
        component: FontAwesome6Icons,
    },
    mobile: {
        name: "laptop-mobile",
        component: FontAwesome6Icons,
    },
    unknown: {
        name: "exclamation-circle",
        component: FontAwesome6Icons,
    },
    error: {
        name: "exclamation-circle",
        component: FontAwesome6Icons,
    },
    payment: {
        name: "piggy-bank",
        component: FontAwesome6Icons,
    },
    save: {
        name: "save",
    },
    department: {
        name: "city",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    close: {
        name: "close",
    },
    gauge: {
        name: "gauge",
        component: FontAwesome6Icons,
    },
    language: {
        name: "language",
        component: FontAwesome6Icons,
    },
    menu: {
        name: "bars",
        component: FontAwesome6Icons,
        variant: "light",
    },
    products: {
        name: "basket-shopping",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    park: {
        name: "pause",
        component: FontAwesome6Icons,
    },
    parkCircle: {
        name: "pause-circle",
        component: FontAwesome6Icons,
    },
    cash: {
        name: "money-bill",
        component: FontAwesome6Icons,
    },
    card: {
        name: "credit-card",
    },
    user: {
        name: "user-large",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    users: {
        name: "users",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    return: {
        name: "undo",
        component: FontAwesome6Icons,
    },
    running: {
        name: "run-fast",
        component: MaterialCommunityIcons,
    },
    dashboard: {
        name: "tachometer-alt",
        component: FontAwesome6Icons,
    },
    settings: {
        name: "gear-complex",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    dropdown: {
        name: "arrow-drop-down",
    },
    dropup: {
        name: "arrow-drop-up",
    },
    dropdownCircle: {
        component: FontAwesome6Icons,
        name: "chevron-circle-down",
    },
    dropupCircle: {
        component: FontAwesome6Icons,
        name: "chevron-circle-up",
    },
    default: {
        // used for menu items without icons
        name: "chevron-right",
    },
    basket: {
        name: "shopping-basket",
        component: FontAwesome6Icons,
    },
    cashRegister: {
        name: "cash-register",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    tags: {
        name: "tags",
        component: FontAwesome6Icons,
    },
    offline: {
        name: "cloud-off",
        // component: MaterialIcons,
    },
    drawer: {
        name: "external-link-alt",
        component: FontAwesome6Icons,
    },
    "chevron-left": {
        name: "chevron-left",
        component: FontAwesome6Icons,
    },
    "chevron-right": {
        name: "chevron-right",
        component: FontAwesome6Icons,
    },
    "arrow-down": {
        name: "arrow-down",
        component: FontAwesome6Icons,
    },
    "arrow-up": {
        name: "arrow-up",
        component: FontAwesome6Icons,
    },
    "arrow-right": {
        name: "arrow-right",
        component: FontAwesome6Icons,
    },
    "arrow-left": {
        name: "arrow-left",
        component: FontAwesome6Icons,
    },
    "angle-down": {
        name: "angle-down",
        component: FontAwesome6Icons,
    },
    "angle-up": {
        name: "angle-up",
        component: FontAwesome6Icons,
    },
    "angle-left": {
        name: "angle-left",
        component: FontAwesome6Icons,
    },
    "angle-right": {
        name: "angle-right",
        component: FontAwesome6Icons,
    },
    edit: {
        name: "edit",
        component: FontAwesome6Icons,
    },
    "closed-receipts": {
        name: "times-circle",
        component: FontAwesome6Icons,
    },
    coins: {
        name: "coins",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    merchant: {
        name: "shop",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    order: {
        name: "file",
        component: FontAwesome6Icons,
    },
    shift: {
        name: "calendar-check",
        component: FontAwesome6Icons,
    },
    list: {
        name: "list-alt",
        component: FontAwesome6Icons,
    },
    signIn: {
        name: "sign-in-alt",
        component: FontAwesome6Icons,
    },
    signOut: {
        name: "sign-out-alt",
        component: FontAwesome6Icons,
    },

    warning: {
        name: "exclamation-triangle",
        component: FontAwesome6Icons,
    },

    info: {
        name: "info-circle",
        component: FontAwesome6Icons,
    },
    success: {
        name: "check-circle",
        // component: MaterialIcons,
    },
    check: {
        name: "check",
        // component: MaterialIcons,
    },
    subArrowRight: {
        name: "subdirectory-arrow-right",
        component: MaterialCommunityIcons,
    },
    grip: {
        name: "grip-vertical",
        component: FontAwesome6Icons,
    },
    times: {
        name: "times",
        component: FontAwesome6Icons,
    },
    plus: {
        name: "plus",
        component: FontAwesome6Icons,
    },
    minus: {
        name: "minus",
        component: FontAwesome6Icons,
    },
    "plus-circle": {
        name: "plus-circle",
        component: FontAwesome6Icons,
    },
    "minus-circle": {
        name: "minus-circle",
        component: FontAwesome6Icons,
    },
    percentage: {
        name: "percentage",
        component: FontAwesome6Icons,
    },
    discount: {
        name: "percent",
        component: FontAwesome6Icons,
    },
    "return-item": {
        name: "undo",
        component: FontAwesome6Icons,
    },
    table: {
        name: "table",
        component: FontAwesome6Icons,
    },
    "times-circle": {
        name: "times-circle",
        component: FontAwesome6Icons,
    },
    backspace: {
        name: "delete-left",
        component: FontAwesome6Icons,
    },
    "arrows-alt-v": {
        name: "arrows-alt-v",
        component: FontAwesome6Icons,
    },
    camera: {
        name: "camera",
        component: FontAwesome6Icons,
    },
    overview: {
        name: "chart-simple",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    globe: {
        name: "globe",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    "money-check-alt": {
        name: "money-check-alt",
        component: FontAwesome6Icons,
    },
    sort: {
        name: "sort",
        component: FontAwesome6Icons,
    },
    "file-invoice-dollar": {
        name: "file-invoice-dollar",
        component: FontAwesome6Icons,
    },
    admin: {
        name: "user-secret",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    reseller: {
        name: "user-large",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    "send-to": {
        name: "share-square",
        component: FontAwesome6Icons,
    },
    sync: {
        name: "sync",
        component: FontAwesome6Icons,
    },
    network: {
        name: "wifi",
        component: FontAwesome6Icons,
    },
    nfc: {
        name: "nfc",
        component: MaterialCommunityIcons,
    },
    barcode: {
        name: "barcode",
        component: MaterialCommunityIcons,
    },
    customers: {
        name: "address-card",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    "id-card": {
        name: "id-card",
        component: FontAwesome6Icons,
    },
    receipt: {
        name: "receipt",
        component: FontAwesome6Icons,
    },
    split: {
        name: "call-split",
    },
    printer: {
        name: "printer",
        component: MaterialCommunityIcons,
    },
    catalog: {
        name: "table",
        component: FontAwesome6Icons,
    },
    calendar: {
        name: "calendar-alt",
        component: FontAwesome6Icons,
    },
    "calendar-clock": {
        name: "calendar-clock",
        component: MaterialCommunityIcons,
    },
    "point-of-sale": {
        name: "point-of-sale",
    },
    search: {
        name: "search",
        component: FontAwesome6Icons,
    },
    "search-plus": {
        name: "magnifying-glass-plus",
        component: FontAwesome6Icons,
    },
    "search-minus": {
        name: "magnifying-glass-minus",
        component: FontAwesome6Icons,
    },
    square: {
        name: "square",
        component: FontAwesome6Icons,
    },
    "square-check": {
        name: "check-square",
        component: FontAwesome6Icons,
    },
    store: {
        name: "store",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    tasks: {
        name: "list-check",
        component: FontAwesome6Icons,
        variant: "solid",
    },
    undo: {
        name: "undo-alt",
        component: FontAwesome6Icons,
    },
};
