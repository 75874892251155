import { t } from "i18next";
import {
    GridDimensions,
    ILayoutProduct,
    IProductGroup,
    ProductButton,
    ProductSection,
} from "lib";
import React, { createContext, useReducer } from "react";
import {
    ProductLayoutActionType,
    productLayoutReducer,
    ProductLayoutReducerState,
} from "./edit/product-layout-operation-reducer";
import { getMaxDimensionsForCoordinate } from "./functions";
import { LayoutActionType } from "./layout-operation-reducer";

export const LayoutButtonsContext =
    createContext<ProductLayoutReducerState | null>(null);
export const LayoutDispatchContext = createContext<React.Dispatch<
    LayoutActionType | ProductLayoutActionType
> | null>(null);

export function ProductLayoutProvider({
    sections,
    knownProducts,
    knownProductGroups,
    gridDimensions,
    onLayoutUpdate,
    children,
}: {
    sections: ProductSection[];
    knownProducts: ILayoutProduct[];
    knownProductGroups: Pick<IProductGroup, "id" | "color">[];
    gridDimensions: GridDimensions;
    onLayoutUpdate: (value: any) => void;
    children: React.ReactNode;
}) {
    const [layoutState, dispatch] = useReducer(
        productLayoutReducer,
        {
            sections,
            knownProducts,
            knownProductGroups,
            dimensions: gridDimensions,
            currentSectionIndex: 0,
            buttonMetaData: {},
            onLayoutUpdate,
        },
        createInitialState
    );

    return (
        <LayoutButtonsContext.Provider value={layoutState}>
            <LayoutDispatchContext.Provider value={dispatch}>
                {children}
            </LayoutDispatchContext.Provider>
        </LayoutButtonsContext.Provider>
    );
}

function createInitialState({
    sections,
    knownProducts,
    knownProductGroups,
    dimensions,
    currentSectionIndex,
    buttonMetaData,
    onLayoutUpdate,
}: ProductLayoutReducerState) {
    let returnValue = {
        sections,
        knownProducts,
        knownProductGroups,
        dimensions,
        currentSectionIndex,
        buttonMetaData,
        onLayoutUpdate,
    };

    returnValue.sections.map(section =>
        section.buttons.map(button => {
            if (button.buttonType === "PRODUCT") {
                const buttonProduct = knownProducts.find(
                    productItr =>
                        productItr.id === (button as ProductButton).productId
                );
                if (!buttonProduct) {
                    return button;
                }

                returnValue.buttonMetaData[button.id] = {
                    color: !button.color
                        ? !buttonProduct.group?.color
                            ? "#aaaaaa"
                            : buttonProduct.group?.color
                        : button.color,
                    text: !button.label
                        ? !buttonProduct.buttonText
                            ? !buttonProduct.name
                                ? t("backoffice.layout.no_text", "No text")
                                : buttonProduct.name
                            : buttonProduct.buttonText
                        : button.label,
                };
            } else if (button.buttonType === "FUNCTION") {
                returnValue.buttonMetaData[button.id] = {
                    ...returnValue.buttonMetaData[button.id],

                    text:
                        button.label ||
                        t("backoffice.layout.no_text", "No text"),
                };

                if (
                    button.function === "CUSTOM_ITEM" ||
                    button.function === "VENUE_ACCESS_TICKETS"
                ) {
                    const foundGroup = knownProductGroups.find(
                        groupItr => groupItr.id === button.productGroupId
                    );

                    if (!foundGroup) {
                        return button;
                    }

                    returnValue.buttonMetaData[button.id] = {
                        ...returnValue.buttonMetaData[button.id],

                        color: !button.color
                            ? !foundGroup.color
                                ? "#abcdef"
                                : foundGroup.color
                            : button.color,
                    };
                }
            } else {
                return;
            }
        })
    );

    returnValue.sections[0].buttons.map(button => {
        if (!button.id) {
            console.warn("Button does not have an id... Back off.");
            return;
        }

        const updateButton = { ...button };

        if (button.buttonType === "PRODUCT") {
            const buttonProduct = knownProducts.find(
                productItr =>
                    productItr.id === (button as ProductButton).productId
            );
            if (buttonProduct) {
                returnValue.buttonMetaData[button.id] = {
                    ...returnValue.buttonMetaData[button.id],

                    color: !button.color
                        ? !buttonProduct.group?.color
                            ? "#aaaaaa"
                            : buttonProduct.group?.color
                        : button.color,
                    text: !button.label
                        ? !buttonProduct.buttonText
                            ? !buttonProduct.name
                                ? t("backoffice.layout.no_text", "No text")
                                : buttonProduct.name
                            : buttonProduct.buttonText
                        : button.label,
                };

                (updateButton as ProductButton).amount = buttonProduct.amount;
            }
        } else if (button.buttonType === "FUNCTION") {
            if (
                button.function === "CUSTOM_ITEM" ||
                button.function === "VENUE_ACCESS_TICKETS"
            ) {
                const foundGroup = knownProductGroups.find(
                    groupItr => groupItr.id === button.productGroupId
                );

                if (foundGroup) {
                    returnValue.buttonMetaData[button.id] = {
                        ...returnValue.buttonMetaData[button.id],

                        color: !button.color
                            ? !foundGroup.color
                                ? "#aaaaaa"
                                : foundGroup.color
                            : button.color,
                        text: button.label,
                    };
                }
            } else if (button.function === "BUY_ACCOUNT_FUNDS") {
                returnValue.buttonMetaData[button.id] = {
                    ...returnValue.buttonMetaData[button.id],

                    color: !button.color ? "#aaaaaa" : button.color,
                };
            }
        }

        // Calculate maxWidth / maxHeight and store it in buttonMetaData

        const { width: maxWidth, height: maxHeight } =
            getMaxDimensionsForCoordinate(
                {
                    x: button.x,
                    y: button.y,
                },
                dimensions,
                returnValue.sections[returnValue.currentSectionIndex]?.buttons,
                []
            );

        returnValue.buttonMetaData[button.id] = {
            ...returnValue.buttonMetaData[button.id],

            maxWidthValue: maxWidth,
            maxHeightValue: maxHeight,
        };

        return button;
    });

    return returnValue;
}
