import {
    ModalWrapper,
    Spacer,
    StyleFunction,
    useModal,
    useThemedStyle,
} from "@venuepos/react-common";
import type {
    FunctionButtonInput,
    ILayoutProduct,
    LayoutButton,
    LayoutButtonCreate,
    FunctionButton,
    ProductButton,
    ProductLayoutButtonType,
    LayoutButtonMetaData,
} from "lib";
import React, { useCallback, useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { Divider } from "react-native-paper";
import { ButtonTypeSelector } from "./button-type-selector";
import { FunctionButtonForm } from "./function-button/function-button-form";
import { ProductButtonMainForm } from "./product-button/product-button-main-form";
import { ProductSelector } from "./product-button/product-selector";

export type AddButtonModalOutput =
    | {
          buttonType: "PRODUCT";
          formValues: ProductButton;
          productData: ILayoutProduct;
      }
    | {
          buttonType: "PRODUCT";
          formValues: null; // If the values are returned from the "select products" section, then there are no formValues
          productData: ILayoutProduct[];
      }
    | {
          buttonType: "FUNCTION";
          formValues: FunctionButton;
      };

export type AddButtonModalProps = {
    button: LayoutButtonCreate | ProductButton | FunctionButton;

    buttonMetaData: LayoutButtonMetaData;

    // If a product has already been selected for this button, this argument is defined.
    product?: ILayoutProduct;

    // The set of products, that has already been added to this layout.
    addedProducts: ILayoutProduct["id"][];

    onClose: (value?: AddButtonModalOutput) => void;
};

export function AddButtonModal({
    button,
    buttonMetaData,
    product,
    addedProducts,
    onClose,
}: AddButtonModalProps) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [buttonType, setButtonType] = useState<string | null>(
        button.buttonType || null
    );
    const [productId, setProductId] = useState<string>(
        (button as ProductButton).productId
            ? (button as ProductButton).productId
            : ""
    );
    const [buttonProduct, setButtonProduct] = useState<ILayoutProduct | null>(
        product || null
    );
    const [buttonColor, setButtonColor] = useState<string | null>(button.color);
    const [buttonLabel, setButtonLabel] = useState<string>(button.label);

    const editFunctionButton = useCallback(
        (formValues: FunctionButtonInput) => {
            if (!formValues.function) {
                onClose();
            } else {
                onClose({
                    buttonType: "FUNCTION",
                    formValues: { ...formValues, buttonType: "FUNCTION" },
                } as {
                    buttonType: "FUNCTION";
                    formValues: FunctionButton;
                });
            }
        },
        [onClose]
    );

    function handleSelectProduct({
        selectedProduct,
        selectedButtonColor,
        selectedButtonLabel,
    }: {
        selectedProduct: ILayoutProduct;
        selectedButtonColor: string;
        selectedButtonLabel: string;
    }) {
        setProductId(selectedProduct.id);
        setButtonProduct(selectedProduct);
        setButtonColor(selectedButtonColor);
        setButtonLabel(selectedButtonLabel);
    }

    const handleAddProductsToLayout = useCallback(
        (selectedProducts: ILayoutProduct[]) => {
            onClose({
                buttonType: "PRODUCT",
                formValues: null,
                productData: selectedProducts,
            });
        },
        [onClose]
    );

    const handleButtonTypeSelect = useCallback(
        (selectedType: ProductLayoutButtonType) => {
            setButtonType(selectedType);
        },
        []
    );

    return (
        <ModalWrapper
            title={t("backoffice.layout.edit_button", "Edit button")}
            onClose={() => onClose(undefined)}
        >
            <View style={styles.container} testID="layout:editButton">
                <Divider />

                <Spacer space={2} />
                {/* If no button type has been selected yet, then let the user select either Product or Function */}
                {!buttonType && !productId ? (
                    <ButtonTypeSelector
                        onButtonTypeSelect={handleButtonTypeSelect}
                    />
                ) : null}

                {/* If no product has been selected yet, then present the ProductSelector */}
                {buttonType === "PRODUCT" && !productId ? (
                    <ProductSelector
                        addedProducts={addedProducts}
                        onSelectProduct={handleSelectProduct}
                        onAddProductsToLayout={handleAddProductsToLayout}
                    />
                ) : null}

                {/* If a product has been selected, then present the Product button Main form */}
                {buttonType === "PRODUCT" && productId ? (
                    <ProductButtonMainForm
                        button={button as ProductButton}
                        buttonProduct={buttonProduct!}
                        buttonColor={buttonColor}
                        buttonLabel={buttonLabel}
                        onClose={onClose}
                        metaData={buttonMetaData}
                    />
                ) : null}

                {/* If this is a Function button, then present the Function button Main form */}
                {buttonType === "FUNCTION" ? (
                    <FunctionButtonForm
                        onSubmit={formValues => {
                            editFunctionButton(
                                formValues as FunctionButtonInput & LayoutButton
                            );
                        }}
                        values={button as LayoutButton & FunctionButtonInput}
                        metaData={buttonMetaData}
                    />
                ) : null}
            </View>
        </ModalWrapper>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    container: {
        padding: theme.spacingScale * 2,
        height: dimensions.height - theme.spacingScale * 10,
        width:
            dimensions.width <= theme.breakpoint.portraitWidth
                ? dimensions.width - theme.spacingScale * 2
                : 800,
        borderBottomEndRadius: theme.borderRadius,
        borderBottomStartRadius: theme.borderRadius,
    },
});

export function useAddButtonModal() {
    const { render } = useModal<AddButtonModalOutput | undefined>();
    return async (props: Omit<AddButtonModalProps, "onClose">) =>
        render(onClose => <AddButtonModal {...props} onClose={onClose} />);
}
