import {
    LayoutButton,
    GridDimensions,
    ButtonDimensions,
    ButtonPosition,
} from "lib";

/**
 * This function will take a coordinate and determine how tall a button on this position can be.
 * This function refers to the global `rows` value, which helps determine how big the grid is
 * and thus the maximum value of the return value.
 * It also uses the `buttons` array to check for any other buttons below the current position.
 *
 * @param xCoordinate number The x-position in the grid (starting from the top left as 0,0)
 * @param yCoordinate number The y-position in the grid (starting from the top left as 0,0)
 * @param ignoreButtons string[] The id's of the button we should ignore in the search
 */
const getMaxHeightForCoordinate = (
    xCoordinate: LayoutButton["x"],
    yCoordinate: LayoutButton["y"],
    gridDimensions: GridDimensions,
    buttons: LayoutButton[],
    ignoreButtons: LayoutButton["id"][]
) => {
    if (!buttons) {
        return gridDimensions.rows;
    }

    // Check if there are buttons below in this column or to the left, which reach into this column
    let buttonsBelowThis = buttons.filter(
        button =>
            !ignoreButtons.includes(button.id as string) &&
            button.y > yCoordinate &&
            button.y < gridDimensions.rows &&
            (button.x === xCoordinate ||
                (button.x < xCoordinate &&
                    button.x + button.width - 1 >= xCoordinate))
    );

    let closestButtonBelow = buttonsBelowThis.reduce(
        /**
         * Reducer to find the nearest button in the grid on the y-axis (below the current position)
         */
        (accumulator: LayoutButton, currentValue: LayoutButton) =>
            accumulator.y < currentValue.y ? accumulator : currentValue,
        {
            x: gridDimensions.columns,
            y: gridDimensions.rows,
        } as LayoutButton
    );

    return closestButtonBelow.y
        ? Math.min(
              gridDimensions.rows - yCoordinate,
              closestButtonBelow.y - yCoordinate
          )
        : gridDimensions.rows - yCoordinate;
};

/**
 * This function will take a coordinate and determine how wide a button on this position can be.
 * The function refers to the global `cols` value, which helps determine how big the grid is
 * and thus the maximum value of the return value.
 * It also refers to the `buttons` array to check for any other buttons to the right of the current position.
 *
 * @param xCoordinate number The x-position in the grid (starting from the top left as 0,0)
 * @param yCoordinate number The y-position in the grid (starting from the top left as 0,0)
 * @param ignoreButtons string[] The id's of the button we should ignore in the search
 */
const getMaxWidthForCoordinate = (
    xCoordinate: LayoutButton["x"],
    yCoordinate: LayoutButton["y"],
    gridDimensions: GridDimensions,
    buttons: LayoutButton[],
    ignoreButtons: LayoutButton["id"][]
) => {
    if (!buttons) {
        return gridDimensions.columns;
    }

    // Check if there are any buttons in the same row or rows above (that reach down into our row)
    let buttonsToTheRight = buttons.filter(
        button =>
            !ignoreButtons.includes(button.id as string) &&
            button.x > xCoordinate &&
            button.x < gridDimensions.columns &&
            (button.y === yCoordinate ||
                (button.y < yCoordinate &&
                    button.y + button.height - 1 >= yCoordinate))
    );

    let closestButtonToTheRight = buttonsToTheRight.reduce(
        /**
         * Reducer to find the nearest button in the grid on the x-axis (to the right of the current position)
         */
        (accumulator: LayoutButton, currentValue: LayoutButton) =>
            accumulator.x < currentValue.x ? accumulator : currentValue,
        {
            x: gridDimensions.columns,
            y: gridDimensions.rows,
        } as LayoutButton
    );

    return closestButtonToTheRight.x
        ? Math.min(
              gridDimensions.columns - xCoordinate,
              closestButtonToTheRight.x - xCoordinate
          )
        : gridDimensions.columns - xCoordinate;
};

export const getMaxDimensionsForCoordinate = (
    position: ButtonPosition,
    gridDimensions: GridDimensions,
    buttons: LayoutButton[],
    ignoreButtons?: LayoutButton["id"][]
): ButtonDimensions => {
    const maxWidth = getMaxWidthForCoordinate(
        position.x,
        position.y,
        gridDimensions,
        buttons,
        ignoreButtons || []
    );
    const maxHeight = getMaxHeightForCoordinate(
        position.x,
        position.y,
        gridDimensions,
        buttons,
        ignoreButtons || []
    );

    return { width: maxWidth, height: maxHeight };
};
