import {
    Button,
    Loading,
    ModalWrapper,
    Spacer,
    StyleFunction,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { IAccount, ValidationErrorData } from "lib";
import { useTranslation } from "locales";
import { useCallback, useEffect, useRef, useState } from "react";
import React, { View } from "react-native";
import { useAccessToken } from "../../../hooks";
import { ValidationErrorsList } from "../../product/validation-errors-list";

type ReissueGiftcardsModalProps = {
    selectedGiftcards: IAccount["id"][];
    onClose: (value?: boolean) => void;
};

export function ReissueGiftcardsModal(props: ReissueGiftcardsModalProps) {
    const accessToken = useAccessToken();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const [successful, setSuccessful] = useState<boolean>(false);
    const [importLoading, setImportLoading] = useState<boolean>(false);
    const [importState, setImportState] = useState<string | undefined>();

    const [formData, setFormData] = useState<FormData>(new FormData());

    const [validationErrors, setValidationErrors] = useState<
        { errors: ValidationErrorData[]; rowIndex: number }[]
    >([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = useCallback(async () => {
        if (
            !inputRef.current ||
            !inputRef.current.files ||
            !inputRef.current.files[0]
        ) {
            return;
        }

        const fileName = inputRef.current.files && inputRef.current.files[0];

        if (!fileName) {
            return;
        }

        formData.append("reissue-import", fileName);

        setFormData(formData);
    }, [formData]);

    const handleFileUpload = useCallback(async () => {
        if (!formData || !accessToken) {
            return;
        }

        setImportLoading(true);

        // When a new file is uploaded, reset the various state variables
        setSuccessful(false);
        setImportState(undefined);
        setValidationErrors([]);

        await fetch("/api/import/giftcards/reissue", {
            method: "POST",
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        }).then(async response => {
            const jsonResponse = await response.json();

            // We are done loading.
            setImportLoading(false);

            if (jsonResponse.error) {
                // The error message is likely to be this:
                // i18next t("import.invalid_file_2", "Invalid file type: File type is not allowed. Please select another file and try again.")

                setImportState(t(jsonResponse.error));
                if (jsonResponse.validationErrors) {
                    setValidationErrors(jsonResponse.validationErrors);
                } else {
                    setValidationErrors([]);
                }
                return;
            }

            setImportState(
                t(
                    "import.giftcards.reissue.successful",
                    "Import successful.\n\n{{ itemsHandled }} giftcards were handled.",
                    {
                        itemsHandled: jsonResponse.giftcardsReissued,
                    }
                )
            );
            setSuccessful(true);
        });
    }, [accessToken, formData, t]);

    useEffect(() => {
        if (inputRef.current === null) {
            return;
        }
        const input = inputRef.current;
        // input.click();
        input.addEventListener("change", handleFileChange);
        return () => {
            input.removeEventListener("change", handleFileChange);
        };
    }, [inputRef, handleFileChange]);

    return (
        <ModalWrapper
            title={t(
                "backoffice.gift_card.reissue_multiple_header",
                "Reissue multiple giftcards?"
            )}
            onClose={() => props.onClose(undefined)}
        >
            <View style={styles.container}>
                <Text>
                    {t(
                        "backoffice.gift_card.issue_multiple_explain",
                        "Re-issuing of giftcards is done in two steps:\n1) Remove the related tag from the giftcards.\n2) Assign the tags to a new account.\n\nThe first step is what happens in this window. The second step can be performed with spreadsheet and the Import function.\n\nThis is where you upload a spreadsheet with the ids of the giftcard accounts, which will have their tags removed and then be closed."
                    )}
                </Text>
                <Spacer space={1} />
                {!successful ? (
                    <input
                        name="file"
                        ref={inputRef}
                        type="file"
                        accept=".xlsx,.csv"
                        style={styles.fileInput as any}
                    />
                ) : null}

                {importLoading ? (
                    <Loading
                        message={t(
                            "backoffice.giftcards.reissuing",
                            "Re-issuing giftcards..."
                        )}
                    />
                ) : null}

                {importState ? (
                    <Text style={successful ? styles.successful : styles.error}>
                        {importState}
                    </Text>
                ) : null}
                <ValidationErrorsList errors={validationErrors} />

                <Spacer space={2} />
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={handleFileUpload}
                        size="small"
                        variant="outline"
                    >
                        {t(
                            "backoffice.gift_card.reissue_upload",
                            "Reissue uploaded"
                        )}
                    </Button>
                    <Spacer space={1} />
                    <Button
                        onPress={() => {
                            props.onClose(true);
                        }}
                        size="small"
                        variant="outline"
                        disabled={props.selectedGiftcards.length === 0}
                    >
                        {t(
                            "backoffice.gift_card.reissue_selected",
                            "Reissue selected"
                        )}
                    </Button>
                    <Spacer space={1} />
                    <Button
                        style={styles.button}
                        onPress={() => {
                            props.onClose(false);
                        }}
                        size="small"
                    >
                        {t("common.close", "Close")}
                    </Button>
                </View>
            </View>
            <Spacer space={2} />
        </ModalWrapper>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        maxWidth: theme.dimensions.maxFormWidth,
        marginHorizontal: theme.spacingScale * 2,
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    error: {
        color: theme.colors.error,
    },
    fileInput: {
        marginBottom: theme.spacingScale * 2,
    },
});
