// Hoverable inspiration: https://codesandbox.io/s/o9q8vy70l5?file=/src/Hoverable.js
import React, { ReactNode } from "react";
import {
    StyleProp,
    StyleSheet,
    TouchableOpacity,
    View,
    ViewStyle,
} from "react-native";

import { StyleFunction, useTheme, useThemedStyle } from "../../theme";
import { Hoverable } from "../hoverable";

export function DataGridRow({
    children,
    onPress,
    style,
    testID,
}: {
    children: ReactNode;
    onPress?: () => void;
    style?: StyleProp<ViewStyle>;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();

    const bgColor =
        style !== undefined &&
        StyleSheet.flatten(style as ViewStyle).backgroundColor !== undefined
            ? StyleSheet.flatten(style as ViewStyle).backgroundColor
            : undefined;

    return (
        <Hoverable>
            {isHovered => (
                <TouchableOpacity
                    style={[
                        styles.container,
                        style,
                        {
                            backgroundColor: isHovered
                                ? theme.colors.grey100
                                : bgColor,
                        },
                    ]}
                    onPress={onPress}
                    disabled={!onPress}
                    testID={testID}
                >
                    <View id="row" style={styles.content} testID={testID}>
                        {children}
                    </View>
                </TouchableOpacity>
            )}
        </Hoverable>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        borderStyle: "solid",
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: theme.colors.grey100,
        minHeight: 48,
        paddingRight: theme.spacingScale / 2,
        paddingLeft: theme.spacingScale * 1.5,
    },
    content: {
        flex: 1,
        flexDirection: "row",
    },
});
