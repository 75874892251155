import {
    CheckBox,
    DataTable,
    IconButton,
    StyleFunction,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { formatAmount, formatDateTime, SupportedCurrenciesType } from "lib";
import React, { useCallback, useMemo } from "react";
import { Link } from "@react-navigation/native";
import { useTranslation } from "locales";

import type { GQAccount, GQGiftCardsQuery } from "graphql-sdk";
import type { AvailableLocale } from "locales";
import { StyleProp, ViewStyle } from "react-native";
export function AccountRow({
    item,
    onView,
    onEdit,
    locale,
    currency,
    selected,
    onMultiSelectChange,
    style,
}: {
    item: GQGiftCardsQuery["giftCards"]["data"][0];
    onView: () => void;
    onEdit: () => void;
    locale: AvailableLocale;
    currency: SupportedCurrenciesType;
    selected: boolean;
    onMultiSelectChange: (
        selected: boolean,
        itemIds: GQAccount["id"][]
    ) => void;
    style?: StyleProp<ViewStyle>;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const handleMultiSelectChange = useCallback(
        value => onMultiSelectChange(value, [item.id]),
        [item.id, onMultiSelectChange]
    );

    const labelText = useMemo(
        () =>
            item.tags.data.reduce((acc, tagItem, index) => {
                return (
                    acc +
                    (tagItem.name || tagItem.identifier) +
                    (index < item.tags.data.length - 1 ? ", " : "")
                );
            }, ""),
        [item.tags.data]
    );

    return (
        <DataTable.Row style={style}>
            <DataTable.Cell style={styles.iconColumn}>
                <CheckBox
                    value={selected}
                    onValueChange={handleMultiSelectChange}
                    style={styles.checkbox}
                    testID={`check:${item.name}`}
                />
            </DataTable.Cell>
            <DataTable.Cell>
                <Text
                    style={[
                        styles.textLink,
                        !item.name ? styles.noName : undefined,
                    ]}
                    onPress={onView}
                >
                    {item.name || t("common.no_name", "No name")}
                </Text>
            </DataTable.Cell>
            <DataTable.Cell>
                {t(
                    "account.status." + item.status,
                    "account.status." + item.status
                )}
            </DataTable.Cell>
            <DataTable.Cell>
                {item.tags.data.length > 1 ? (
                    labelText
                ) : item.tags.data.length === 1 ? (
                    <Link
                        to={{
                            screen: "TAG_EDIT",
                            params: { id: item.tags.data[0].id },
                        }}
                        style={styles.textLink}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                    >
                        {labelText}
                    </Link>
                ) : null}
            </DataTable.Cell>
            <DataTable.Cell>
                {formatDateTime(item.createdAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell numeric>
                {formatAmount(item.amount, currency, {
                    printCurrency: true,
                    locale: locale,
                })}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.sortIconColumn}>
                <IconButton
                    color={styles.iconColumn.color}
                    name="edit"
                    onPress={onEdit}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    checkbox: {
        marginVertical: 6,
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
        color: theme.colors.secondary,
    },
    sortIconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
        color: theme.colors.secondary,
    },
    textLink: {
        ...theme.styles.link,
    },
    noName: {
        fontStyle: "italic",
    },
});
