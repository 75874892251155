import React, { useCallback, useEffect } from "react";
import { View } from "react-native";
import {
    useTheme,
    useThemedStyle,
    Icon,
    AuthSelectUserRoleAction,
    useMeLazy,
    Button,
    Headline,
    Loading,
    Text,
    StyleFunction,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import type { GQAllAuthUserFieldsFragment } from "graphql-sdk";
import { TouchableOpacity } from "react-native-gesture-handler";

export function SelectUserRole({
    selectUserRole,
    signOut,
}: {
    selectUserRole: AuthSelectUserRoleAction;
    signOut: () => void;
}) {
    const [{ load: loadMe }, me] = useMeLazy();
    const [t] = useTranslation();
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);

    // Load me on mount
    useEffect(() => {
        loadMe().catch(() => {
            // do nothing
        });
    }, [loadMe]);

    const select = useCallback(
        async (roleId: string, merchantId?: string) => {
            await selectUserRole(roleId, merchantId);
            loadMe().catch(() => {
                // do nothing
            });
        },
        [loadMe, selectUserRole]
    );

    // If user only has one merchant role, we should just select that for user
    useEffect(() => {
        if (me && !me.role && me.user && me.user.roles) {
            if (me.user.roles.length === 1) {
                const userRole = me.user.roles[0];
                select(userRole.role.id, userRole.merchant?.id);
            }
        }
    }, [me, select, selectUserRole]);

    // Make sure user and it's roles has been loaded
    if (!me || !me.user || me.user.roles.length <= 1) {
        return <Loading color={theme.colors.textDark} />;
    }

    return (
        <>
            <Headline size="h2">
                {t("authentication.select_merchant", "Select Merchant")}
            </Headline>
            {me.user.roles.map(userRole => (
                <TouchableOpacity
                    key={userRole.role.id + userRole.merchant?.id}
                    onPress={async () => {
                        await select(userRole.role.id, userRole.merchant?.id);
                    }}
                >
                    <UserRole userRole={userRole} />
                </TouchableOpacity>
            ))}
            <Button type="primary" onPress={signOut} style={styles.button}>
                {t("authentication.switch_user", "Switch account")}
            </Button>
        </>
    );
}

function UserRole({
    userRole,
}: {
    userRole: GQAllAuthUserFieldsFragment["roles"][0];
}) {
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);

    return (
        <View style={styles.userRole}>
            <View style={styles.userRoleInfo}>
                {userRole.role.type === "MERCHANT" && userRole.merchant ? (
                    <Text style={styles.roleType}>
                        {userRole.merchant.name}
                    </Text>
                ) : (
                    <View style={styles.userRoleWithIcon}>
                        <Text style={styles.roleType}>
                            {userRole.role.type}
                        </Text>
                        <Icon
                            color={theme.colors.primary}
                            name={
                                userRole.role.type === "ADMIN"
                                    ? "admin"
                                    : "reseller"
                            }
                        />
                    </View>
                )}
                <Text style={styles.role}>{userRole.role.name}</Text>
            </View>
            <Icon color={theme.colors.primary} name="signIn" />
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    userRole: {
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderColor: theme.colors.grey800,
        marginBottom: 20,
        flexDirection: "row",
        alignItems: "center",
    },
    userRoleInfo: {
        flex: 1,
        paddingRight: 10,
    },
    userRoleWithIcon: {
        flexDirection: "row",
        alignItems: "center",
    },
    role: { fontSize: 12 },
    roleType: {
        fontWeight: "bold",
        marginRight: 10,
    },
    button: {
        marginTop: 20,
    },
});
