import { useContext } from "react";
import {
    LayoutButtonsContext,
    LayoutDispatchContext,
} from "./table-layout-provider";

export function useLayoutButtons() {
    return useContext(LayoutButtonsContext);
}

export function useLayoutButtonsDispatch() {
    return useContext(LayoutDispatchContext);
}
