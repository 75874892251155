import React, { useState } from "react";
import { View } from "react-native";
import { useTranslation } from "locales";
import type { LayoutSection } from "lib";
import {
    Button,
    InputControl,
    ModalWrapper,
    Picker,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";

export function SendToSectionModal({
    sections,
    onClose,
}: {
    sections: Pick<LayoutSection, "id" | "label">[];
    onClose: (value?: LayoutSection["id"] | "NEW") => void;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [section, setSection] = useState<LayoutSection["id"]>("NEW");

    return (
        <ModalWrapper
            onClose={() => onClose(undefined)}
            title={t(
                "backoffice.layout.move_to_section_headline",
                "Send to section"
            )}
        >
            <View style={styles.box}>
                <InputControl>
                    <Picker
                        onValueChange={value => {
                            setSection(value);
                        }}
                        selectedValue={section}
                    >
                        <Picker.Item
                            key="NEW"
                            value="NEW"
                            label={t(
                                "backoffice.layout.move_to_new_section",
                                "New section..."
                            )}
                        />
                        {sections.map(sectionItr => (
                            <Picker.Item
                                key={sectionItr.id}
                                value={sectionItr.id}
                                label={sectionItr.label}
                            />
                        ))}
                    </Picker>
                </InputControl>
                <Button onPress={() => onClose(section)}>
                    {t("backoffice.layout.send", "Send")}
                </Button>
            </View>
        </ModalWrapper>
    );
}

const styleFunc: StyleFunction = theme => ({
    box: {
        width: "100%",
        minWidth: 320,
        padding: theme.spacingScale * 2,
    },
});
