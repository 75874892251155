import {
    Alert,
    DataTable,
    Icon,
    IconButton,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    Text,
    useAuth,
    useMerchantConfig,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useInvoicesQuery } from "graphql-sdk";
import {
    SearchDefinition,
    formatDateTime,
    numberFormat,
    parseAmount,
} from "lib";
import { formatAmount } from "lib/src/currencies/currencies";
import React from "react";
import { useTranslation } from "locales";

import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";

import type { AvailableLocale } from "locales";
import { View } from "react-native";
import { useSearchDefinition } from "../../hooks";
type ScreenProps = RootStackScreenProps<"INVOICES">;

export function InvoiceListScreen({ navigation: { navigate } }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.invoice");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "created_at",
        initialSortDirection: "DESC",
    });

    const defaultInvoiceSearch: SearchDefinition = {
        amount: createSearchDefinition("amount"),
        receiptNumber: {
            name: t("searching.receipt_number", "Receipt number"),
            type: "string",
            value: "",
            enabled: false,
        },
        createdAt: createSearchDefinition("createdAt"),
        cashRegisterName: {
            name: t("searching.cash_register_name", "Cash register name"),
            type: "string",
            value: "",
            enabled: false,
        },
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultInvoiceSearch, {
        visible: true,
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const { data, loading, error } = useInvoicesQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });

    function handleShowInvoice(invoiceId: string) {
        navigate("INVOICE", {
            id: invoiceId,
        });
    }

    return (
        <AdminContainer>
            <View style={[theme.styles.row, styles.container]}>
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "receiptNumber"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("receiptNumber")}
                        >
                            {t(
                                "backoffice.invoice.receipt_number",
                                "Receipt number"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "cashRegisterName"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("cashRegisterName")}
                        >
                            {t(
                                "backoffice.invoice.cash_register",
                                "Cash Register"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={
                                sortBy === "amount" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("amount")}
                        >
                            {t("backoffice.invoice.amount", "Amount")}
                        </DataTable.Title>
                        <DataTable.Title numeric>
                            {t("backoffice.invoice.item_count", "Items")}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={
                                sortBy === "createdAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("createdAt")}
                        >
                            {t("backoffice.invoice.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sortIcon} numeric>
                            <Icon color={styles.sortIcon.color} name="sort" />
                        </DataTable.Title>
                    </DataTable.Header>
                    {error ? (
                        <Alert type="error">
                            {t(
                                "receipt.error",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : !loading && data ? (
                        data?.invoices.data.map((invoice, invoiceIndex) => (
                            <DataTable.Row
                                onPress={() =>
                                    invoice && handleShowInvoice(invoice.id)
                                }
                                key={invoice?.id}
                                style={
                                    invoiceIndex % 2 === 1
                                        ? styles.oddRow
                                        : undefined
                                }
                            >
                                <DataTable.Cell>
                                    {invoice && invoice.receiptNumber}
                                </DataTable.Cell>
                                <DataTable.Cell>
                                    {invoice && invoice.shift.cashRegister.name}
                                </DataTable.Cell>
                                <DataTable.Cell numeric>
                                    {formatAmount(
                                        (invoice &&
                                            parseAmount(
                                                invoice.amount / 100
                                            )) ||
                                            0,
                                        currency,
                                        {
                                            locale: locale as AvailableLocale,
                                        }
                                    )}
                                </DataTable.Cell>
                                <DataTable.Cell numeric>
                                    <QuantityElement
                                        invoice={invoice}
                                        locale={locale}
                                    />
                                </DataTable.Cell>
                                <DataTable.Cell numeric>
                                    {invoice &&
                                        formatDateTime(
                                            invoice.createdAt,
                                            locale as AvailableLocale
                                        )}
                                </DataTable.Cell>
                                <DataTable.Cell style={styles.sortIcon} numeric>
                                    <IconButton
                                        color={styles.icon.color}
                                        name="order"
                                        onPress={() =>
                                            invoice &&
                                            handleShowInvoice(invoice.id)
                                        }
                                    />
                                </DataTable.Cell>
                            </DataTable.Row>
                        ))
                    ) : (
                        <Loading />
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.invoices.pagination.pages}
                        itemCount={data?.invoices.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
    },
    sortIcon: {
        color: theme.colors.black,
        flexBasis: theme.spacingScale * 6,
        flexGrow: 0,
        flexShrink: 0,
    },
    icon: {
        color: theme.colors.secondary,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});

function QuantityElement({
    invoice,
    locale,
}: {
    invoice: any;
    locale: string;
}) {
    if (!invoice) {
        return null;
    }

    const quantity = invoice.items?.reduce(
        (sum: number, itr: { quantity: number }) => sum + itr.quantity,
        0
    );
    return (
        <Text>{numberFormat(Math.round(quantity * 100) / 100, 2, locale)}</Text>
    );
}
