import { ReactNode, useMemo } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    ActivityIndicator,
    StyleProp,
    StyleSheet,
    TextStyle,
    TouchableOpacity,
    View,
    ViewStyle,
} from "react-native";
import { ITheme, StyleFunction, useTheme, useThemedStyle } from "../../theme";
import { Icon, IconSizes } from "../icon";
import { Text } from "../text";

export type ButtonSizes = "tiny" | "small" | "normal" | "large" | "huge";

export type ButtonTypes = "primary" | "secondary" | "warning";

export type ButtonVariants =
    | "normal"
    | "invert"
    | "text"
    | "outline"
    | "white_outline";

export type ButtonProps = {
    children: ReactNode;
    size?: ButtonSizes;
    type?: ButtonTypes;
    shadow?: "shadow";
    style?: StyleProp<any>;
    iconName?: string;
    loading?: boolean;
    disabled?: boolean;
    variant?: ButtonVariants;
    textStyle?: StyleProp<TextStyle>;
    onPress: () => any;
    testID?: string;
};

export function Button(props: ButtonProps) {
    const { children } = props;
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);

    const icon = useCallback(
        (color: string) => {
            if (!props.iconName) {
                return null;
            }
            let iconSize: IconSizes = "normal";
            switch (props.size) {
                case "tiny":
                    iconSize = "tiny";
                    break;

                case "small":
                    iconSize = "small";
                    break;

                case "huge":
                case "large":
                    iconSize = "large";
                    break;

                case "normal":
                    iconSize = "normal";
                    break;
                default:
                // empty by default
            }
            return (
                <Icon
                    color={color}
                    name={props.iconName}
                    size={iconSize}
                    style={styles.icon}
                />
            );
        },
        [props.iconName, props.size, styles.icon]
    );

    const content = useCallback(
        (textColor: string) => {
            if (props.loading) {
                return <ActivityIndicator size="large" color={textColor} />;
            }

            return (
                <View style={styles.iconContainer}>
                    {icon(textColor)}
                    <ButtonText {...props} color={textColor}>
                        {children}
                    </ButtonText>
                </View>
            );
        },
        [children, icon, props, styles.iconContainer]
    );

    return (
        <ButtonContainer {...props} theme={theme}>
            {content}
        </ButtonContainer>
    );
}

function ButtonContainer({
    children,
    onPress,
    theme,
    type,
    shadow,
    variant,
    style,
    size,
    disabled,
    testID,
}: {
    children: (textColor: string) => React.ReactElement;
    theme: ITheme;
} & Pick<
    ButtonProps,
    | "variant"
    | "type"
    | "onPress"
    | "style"
    | "size"
    | "disabled"
    | "shadow"
    | "testID"
>) {
    const styles = useThemedStyle(styleFunc);
    const { colors } = theme;
    let color1 = colors.secondary;
    if (type === "primary") {
        color1 = colors.primary;
    } else if (type === "warning") {
        color1 = colors.error;
    }
    let color2 = colors.white;

    const [disable, setDisable] = useState(false);
    const isUnmounting = useRef<boolean>(false);

    // Ensures that we don't call setState when unmounted
    useEffect(() => {
        return () => {
            isUnmounting.current = true;
        };
    }, []);

    // use grayscale for disabled buttons
    if (disabled) {
        color1 = colors.grey500;
        color2 = colors.grey250;
    }

    let backgroundColor = color1;
    let borderColor = color2;
    let borderWidth = 0;
    let textColor = color2;
    let paddingHorizontal = 34;

    const extraStyles: ViewStyle[] = useMemo(() => [], []);

    switch (variant) {
        case "invert":
            backgroundColor = color2;
            borderColor = color1;
            textColor = color1;
            borderWidth = 0;
            break;
        case "outline":
            backgroundColor = color2;
            borderColor = color1;
            textColor = color1;
            borderWidth = StyleSheet.hairlineWidth;
            break;
        case "text":
            backgroundColor = "transparent";
            textColor = disabled ? theme.colors.grey250 : color1;
            borderWidth = 0;
            paddingHorizontal = 0;
            break;
        case "white_outline":
            backgroundColor = "transparent";
            borderColor = theme.colors.white;
            textColor = theme.colors.textLight;
            borderWidth = StyleSheet.hairlineWidth;
            break;
        default: {
            // empty by default
        }
    }

    switch (size) {
        case "tiny":
            extraStyles.push(styles.tinyButton);
            if (
                styles.tinyButton.height !== undefined &&
                typeof styles.tinyButton.height === "number"
            ) {
                extraStyles.push({
                    borderRadius: styles.tinyButton.height / 2,
                });
            }
            break;
        case "small":
            extraStyles.push(styles.smallButton);
            if (
                styles.smallButton.height !== undefined &&
                typeof styles.smallButton.height === "number"
            ) {
                extraStyles.push({
                    borderRadius: styles.smallButton.height / 2,
                });
            }
            break;
        case "huge":
            extraStyles.push(styles.hugeButton);
            if (
                styles.hugeButton.height !== undefined &&
                typeof styles.hugeButton.height === "number"
            ) {
                extraStyles.push({
                    borderRadius: styles.hugeButton.height / 2,
                });
            }
            break;

        case "normal":
        default: {
            if (
                styles.button.height !== undefined &&
                typeof styles.button.height === "number"
            ) {
                extraStyles.push({
                    borderRadius: styles.button.height / 2,
                });
            }
        }
    }

    if (shadow === "shadow") {
        extraStyles.push(styles.shadow);
    }

    const flattenedStyles = useMemo(
        () =>
            StyleSheet.flatten([
                styles.button,
                extraStyles,
                {
                    backgroundColor,
                    borderColor,
                    borderWidth,
                },
                { paddingHorizontal },
                style,
            ]),
        [
            backgroundColor,
            borderColor,
            borderWidth,
            extraStyles,
            paddingHorizontal,
            style,
            styles.button,
        ]
    );

    const handlePress = useCallback(async () => {
        setDisable(true);
        await Promise.resolve(onPress());
        if (!isUnmounting.current) {
            setDisable(false);
        }
    }, [setDisable, onPress]);

    return (
        <TouchableOpacity
            onPress={handlePress}
            style={flattenedStyles}
            disabled={disabled || disable}
            testID={testID}
        >
            {children(textColor)}
        </TouchableOpacity>
    );
}

function ButtonText({
    color,
    children,
    variant,
    textStyle,
    size,
    disabled,
}: {
    color: string;
    children: ReactNode;
} & Pick<ButtonProps, "variant" | "textStyle" | "size" | "disabled">) {
    const styles = useThemedStyle(styleFunc);

    let borderWidth = 0;
    if (variant === "outline" || variant === "white_outline") {
        // The button variants, that use a distinct border width, should subtract that border width
        // from the line height of the text inside, so the text can be aligned centered.
        borderWidth = StyleSheet.hairlineWidth;
    }

    let sizeStyling: StyleProp<TextStyle>;
    switch (size) {
        case "tiny": {
            sizeStyling = styles.tinyButtonText;

            if (
                borderWidth !== 0 &&
                styles.tinyButton.height !== undefined &&
                typeof styles.tinyButton.height === "number"
            ) {
                sizeStyling = {
                    ...sizeStyling,
                    lineHeight: styles.tinyButton.height - borderWidth * 2,
                };
            }

            break;
        }
        case "small": {
            sizeStyling = styles.smallButtonText;
            if (
                borderWidth !== 0 &&
                styles.smallButton.height !== undefined &&
                typeof styles.smallButton.height === "number"
            ) {
                sizeStyling = {
                    ...sizeStyling,
                    lineHeight: styles.smallButton.height - borderWidth * 2,
                };
            }

            break;
        }
        case "huge": {
            sizeStyling = styles.hugeButtonText;
            if (
                borderWidth !== 0 &&
                styles.hugeButton.height !== undefined &&
                typeof styles.hugeButton.height === "number"
            ) {
                sizeStyling = {
                    ...sizeStyling,
                    lineHeight: styles.hugeButton.height - borderWidth * 2,
                };
            }

            break;
        }
        default: {
            if (
                borderWidth !== 0 &&
                styles.button.height !== undefined &&
                typeof styles.button.height === "number"
            ) {
                sizeStyling = {
                    lineHeight: styles.button.height - borderWidth * 2,
                };
            }
        }
    }

    const flattenedStyles = useMemo(
        () =>
            StyleSheet.flatten([
                styles.buttonText,
                sizeStyling,
                { color },
                disabled ? styles.italicFont : undefined,
                textStyle,
            ]),
        [
            color,
            disabled,
            sizeStyling,
            styles.buttonText,
            styles.italicFont,
            textStyle,
        ]
    );

    return (
        <Text numberOfLines={1} style={flattenedStyles}>
            {variant === "text" && typeof children === "string"
                ? children.toUpperCase()
                : children}
        </Text>
    );
}

const styleFunc: StyleFunction = theme => ({
    button: {
        flexDirection: "row",
        height: 48,
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 34,
    },
    buttonText: {
        ...theme.fonts.medium,
        fontSize: 16,
    },

    tinyButton: {
        height: 24,
    },
    tinyButtonText: {
        fontSize: 10,
    },

    smallButton: {
        height: 32,
    },
    smallButtonText: {
        fontSize: 12,
    },

    hugeButton: {
        height: 56,
    },
    hugeButtonText: {
        fontSize: 20,
    },

    iconContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        paddingRight: 8,
    },

    shadow: {
        shadowColor: theme.colors.semiTransparentBlack,
        shadowOffset: {
            width: 5,
            height: 5,
        },
        shadowOpacity: 1,
        shadowRadius: 3.84,

        elevation: 10,
    },
    italicFont: {
        fontStyle: "italic",
        fontWeight: "bold",
    },
});
