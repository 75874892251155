import React, { useCallback } from "react";
import {
    StyleFunction,
    useThemedStyle,
    useForm,
    Form,
    Loading,
    InputControl,
    Picker,
    TextInput,
    Button,
    Spacer,
    RequiredText,
    Headline,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import { View } from "react-native";
import { Divider } from "react-native-paper";
import { BuyAccountFundsSubForm } from "./buy-account-funds-sub-form";
import { VenueAccessTicketsSubForm } from "./venue-access-tickets-sub-form";
import { ButtonSizeForm } from "../../../../button-size-form";
import { CustomItemSubForm } from "./custom-item-sub-form";
import {
    BuyAccountFundsButtonType,
    CustomItemButtonType,
    defaultFunctionButtonSchema,
    FunctionButtonInput,
    LayoutButtonMetaData,
    VenueAccessTicketButtonType,
} from "lib";

export function FunctionButtonForm({
    onSubmit: submitCallback,
    values: defaultValues,
    metaData,
}: {
    onSubmit: (button: FunctionButtonInput) => void;
    values: FunctionButtonInput;
    metaData: LayoutButtonMetaData;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const form = useForm(defaultFunctionButtonSchema, defaultValues);
    const [{ values, errors }, { setValue, handleSubmit }] = form;

    const onSubmit = useCallback(() => {
        if (!values) {
            return;
        }

        submitCallback(values);
    }, [submitCallback, values]);

    if (!values) {
        return <Loading />;
    }

    return (
        <>
            <ButtonSizeForm form={form as any} metaData={metaData} />
            <View style={[styles.onTop, styles.columnContainer]}>
                <InputControl
                    error={errors.function}
                    style={styles.firstColumn}
                >
                    <Picker
                        required
                        label={t(
                            "layout.function_button_form.function",
                            "Function"
                        )}
                        onValueChange={value => {
                            setValue(
                                "function",
                                value as FunctionButtonInput["function"]
                            );
                        }}
                        selectedValue={values.function || undefined}
                    >
                        <Picker.Item
                            label={t(
                                "layout.function_button_form.types.none",
                                "Select"
                            )}
                            value=""
                        />
                        <Picker.Item
                            value="CUSTOM_ITEM"
                            label={t(
                                "layout.function_button_form.types.custom_item",
                                "Custom item"
                            )}
                        />
                        <Picker.Item
                            value="BUY_ACCOUNT_FUNDS"
                            label={t(
                                "layout.function_button_form.types.buy_account_funds",
                                "Buy account funds"
                            )}
                        />
                        <Picker.Item
                            value="VENUE_ACCESS_TICKETS"
                            label={t(
                                "layout.function_button_form.types.venue_access_tickets",
                                "Buy ticket from Venue Access"
                            )}
                        />
                    </Picker>
                </InputControl>
                <Spacer space={2} />
                <InputControl error={errors.label} style={styles.lastColumn}>
                    <TextInput
                        required
                        label={t("layout.function_button_form.label", "Label")}
                        defaultValue={values.label}
                        onChangeText={value => {
                            setValue("label", value);
                        }}
                    />
                </InputControl>
            </View>
            <Divider />
            <Spacer space={2} />
            <SubForm form={form} />
            <Button type="primary" onPress={handleSubmit(onSubmit)}>
                {t("layout.function_button_form.types.apply", "Apply")}
            </Button>
            <RequiredText />
        </>
    );
}

function SubForm({ form }: { form: Form<FunctionButtonInput> }) {
    const [t] = useTranslation();
    const [{ values }] = form;

    switch (values?.function) {
        case "CUSTOM_ITEM":
            return (
                <>
                    <Headline size="h5">
                        {t(
                            "layout.function_button_form.sub_form_header",
                            "Settings for: {{ function }}",
                            {
                                replace: {
                                    function: t(
                                        "layout.function_button_form.types.custom_item",
                                        "Custom item"
                                    ),
                                },
                            }
                        )}
                    </Headline>
                    <CustomItemSubForm
                        form={form as Form<CustomItemButtonType>}
                    />
                    <Divider />
                    <Spacer space={2} />
                </>
            );
        case "BUY_ACCOUNT_FUNDS":
            return (
                <>
                    <Headline size="h5">
                        {t(
                            "layout.function_button_form.sub_form_header",
                            "Settings for: {{ function }}",
                            {
                                replace: {
                                    function: t(
                                        "layout.function_button_form.types.buy_account_funds",
                                        "Buy account funds"
                                    ),
                                },
                            }
                        )}
                    </Headline>
                    <BuyAccountFundsSubForm
                        form={form as Form<BuyAccountFundsButtonType>}
                    />
                    <Divider />
                    <Spacer space={2} />
                </>
            );
        case "VENUE_ACCESS_TICKETS":
            return (
                <>
                    <Headline size="h5">
                        {t(
                            "layout.function_button_form.sub_form_header",
                            "Settings for: {{ function }}",
                            {
                                replace: {
                                    function: t(
                                        "layout.function_button_form.types.venue_access_tickets",
                                        "Buy ticket from Venue Access"
                                    ),
                                },
                            }
                        )}
                    </Headline>
                    <VenueAccessTicketsSubForm
                        form={form as Form<VenueAccessTicketButtonType>}
                    />
                    <Divider />
                    <Spacer space={2} />
                </>
            );
        default: {
            return null;
        }
    }
}

const styleFunc: StyleFunction = () => ({
    onTop: { zIndex: 2 },
    columnContainer: {
        flexDirection: "row",
    },
    firstColumn: {
        flex: 1,
    },
    lastColumn: {
        flex: 1,
    },
});
