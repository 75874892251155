// Inspiration: https://github.com/callstack/react-native-paper/blob/master/src/components/IconButton.tsx

import React, { useMemo } from "react";
import { StyleProp, ViewStyle, StyleSheet, TextStyle } from "react-native";
import { useTheme } from "../../theme";
import { Icon, IconProps } from "../icon";
import { Tooltip } from "react-native-paper";
import { TouchableOpacity } from "react-native";

type IconButtonProps = IconProps & {
    disabled?: boolean;
    onPress: () => void;
    tooltip?: string;
    style?: StyleProp<ViewStyle | TextStyle>;
};

export function IconButton({
    name,
    color,
    size,
    variant,
    disabled,
    style,
    onPress,
    tooltip,
    testID,
}: IconButtonProps) {
    const { colors } = useTheme();

    const component = useMemo(() => {
        const icon = (
            <Icon
                name={name}
                size={size}
                color={
                    StyleSheet.flatten(style as TextStyle)?.color ||
                    (disabled ? colors.grey500 : color)
                }
                variant={variant}
            />
        );

        return (
            <TouchableOpacity
                onPress={onPress}
                style={[styles.container, style]}
                disabled={disabled}
                testID={testID}
            >
                {icon}
            </TouchableOpacity>
        );
    }, [
        color,
        colors.grey500,
        disabled,
        name,
        onPress,
        size,
        style,
        testID,
        variant,
    ]);

    return tooltip ? (
        <Tooltip title={tooltip} leaveTouchDelay={0}>
            {component}
        </Tooltip>
    ) : (
        component
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 6,
    },
});
