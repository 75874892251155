import { Button, Form, Loading, Spacer } from "@venuepos/react-common";
import { useTranslation } from "locales";
import React, { useCallback } from "react";
import { View } from "react-native";

import type {
    GridDimensions,
    ILayoutInput,
    ILayoutProduct,
    IProductGroup,
    ProductSection,
} from "lib";
import { ProductLayoutProvider } from "../product-layout-provider";
import { LayoutGridSection } from "./layout-grid-section";
import { SectionTabs } from "./section-tabs";

export function LayoutEditor({
    form,
    onSubmit,
    productData,
    productGroupData,
    dimensions,
}: {
    form: Form<ILayoutInput>;
    onSubmit: () => Promise<void>;
    productData: ILayoutProduct[];
    productGroupData: Pick<IProductGroup, "id" | "color">[];
    dimensions: GridDimensions;
}) {
    const [t] = useTranslation();
    const [{ values }, { setValue }] = form;

    const handleLayoutUpdate = useCallback(
        (value: any) => {
            setValue("data", value);
        },
        [setValue]
    );

    if (!values?.data) {
        return <Loading />;
    }

    return (
        <View testID="layout:editScreen">
            <ProductLayoutProvider
                gridDimensions={dimensions}
                knownProducts={productData}
                knownProductGroups={productGroupData}
                sections={values.data as ProductSection[]}
                onLayoutUpdate={handleLayoutUpdate}
            >
                <SectionTabs />
                <LayoutGridSection />
                <Spacer />
                <Button onPress={onSubmit} testID="layout:saveLayout">
                    {t("common.save", "Save")}
                </Button>
            </ProductLayoutProvider>
        </View>
    );
}
