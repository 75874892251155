import React from "react";
import { useTranslation } from "locales";
import {
    Form,
    InputControl,
    TextInput,
    Button,
    StyleFunction,
    useThemedStyle,
    RequiredText,
} from "@venuepos/react-common";
import type { ILayoutInput } from "lib";
import { View } from "react-native";

export function LayoutForm(props: {
    form: Form<ILayoutInput>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    if (!values) {
        return null;
    }

    return (
        <View style={styles.container}>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    required={true}
                />
            </InputControl>

            <Button onPress={handleSubmit(props.onSubmit)}>
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        width: "100%",
        maxWidth: theme.dimensions.maxFormWidth,
        alignSelf: "flex-start",
    },
});
