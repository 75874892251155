import { Button, Form, Loading, Spacer, Text } from "@venuepos/react-common";
import type { GridDimensions, ILayoutInput, TableSection } from "lib";
import { useTranslation } from "locales";
import { useCallback } from "react";
import "react-grid-layout/css/styles.css";
import React from "react";
import { View } from "react-native";
import "react-resizable/css/styles.css";
import { LayoutGridSection } from "../layout-grid-section";
import { TableLayoutProvider } from "../table-layout-provider";
import { SectionTabs } from "./section-tabs";

export function LayoutEditor({
    form,
    onSubmit,
    dimensions,
}: {
    form: Form<ILayoutInput>;
    onSubmit: () => void;
    dimensions: GridDimensions;
}) {
    const [t] = useTranslation();
    const [{ values }, { setValue }] = form;

    const handleLayoutUpdate = useCallback(
        (value: any) => {
            setValue("data", value);
        },
        [setValue]
    );

    if (!values?.data) {
        return <Loading />;
    }

    return (
        <View testID={`tableLayout:editScreen`}>
            <TableLayoutProvider
                gridDimensions={dimensions}
                sections={values.data as TableSection[]}
                onLayoutUpdate={handleLayoutUpdate}
            >
                <SectionTabs />
                <LayoutGridSection />
                <Spacer />
                <Button onPress={onSubmit}>
                    <Text>{t("common.save", "Save")}</Text>
                </Button>
            </TableLayoutProvider>
        </View>
    );
}
