import {
    Alert,
    Loading,
    Surface,
    Tabs,
    useAuth,
    useForm,
} from "@venuepos/react-common";
import { useLayoutLazyQuery, useLayoutSaveMutation } from "graphql-sdk";
import { GridDimensions, ILayoutInput, schemaEditLayout } from "lib";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "locales";

import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { rinseLayoutData } from "../../functions";
import { LayoutForm } from "../../layout-form";
import { LayoutScreen } from "../../layout-screen";
import { LayoutEditor } from "./layout-editor";

type ScreenProps = RootStackScreenProps<"TABLE_LAYOUT_EDIT">;

export function TableLayoutEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.layout.write");

    const [t] = useTranslation();
    const { handleMutationError } = useHandleMutationError();
    const [layoutEdit] = useLayoutSaveMutation();
    const layoutId = route.params.id;

    const [getLayout, layoutQuery] = useLayoutLazyQuery({
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const form = useForm<ILayoutInput>(schemaEditLayout, null);
    const [{ values }, { setDefaultValues }] = form;

    useEffect(() => {
        if (!layoutId) {
            navigate("LAYOUTS");
        } else {
            getLayout({ variables: { id: layoutId } });
        }
    }, [layoutId, getLayout, navigate]);

    useEffect(() => {
        if (!layoutQuery.data?.layout) {
            return;
        }

        setDefaultValues({
            name: layoutQuery.data.layout.name,
            data: JSON.parse(layoutQuery.data.layout.data),
        });
    }, [layoutId, layoutQuery.data, layoutQuery.loading, setDefaultValues]);

    const redirect = useCallback(() => {
        // If the page was loaded with a referrer, then return to that referrer.
        // The referrer could be e.g. CASH_REGISTER_EDIT with a specific id, but could also be to the general MERCHANT_SETTINGS,
        // so the id could be missing from the referrer argument
        if (!route.params.referrer || !route.params.referrer.route) {
            return;
        }

        if (route.params.referrer.route === "MERCHANT_SETTINGS") {
            navigate("MERCHANT_SETTINGS");
        }
    }, [navigate, route.params.referrer]);

    // Used with the regular form (layout name, type, etc.)
    const handleSubmit = useCallback(async () => {
        if (!layoutQuery.data?.layout || !values) {
            return;
        }

        // remove metaData object from buttons. There is no need to save them.
        const updatedNewLayoutData = values.data.map(rinseLayoutData);

        await handleMutationError(
            async () => {
                await layoutEdit({
                    variables: {
                        id: layoutId,
                        layout: {
                            name: values.name,
                            data: JSON.stringify(updatedNewLayoutData),
                        },
                    },
                });
            },
            t("common.saved", "Saved"),
            redirect
        );
    }, [
        layoutQuery.data?.layout,
        values,
        handleMutationError,
        t,
        redirect,
        layoutEdit,
        layoutId,
    ]);

    const gridDimensions: GridDimensions = useMemo(() => {
        if (!layoutQuery.data?.layout) {
            return { columns: 1, rows: 1 };
        }

        return {
            columns: layoutQuery.data.layout.columns,
            rows: layoutQuery.data.layout.rows,
        };
    }, [layoutQuery.data?.layout]);

    return (
        <LayoutScreen>
            <Surface>
                {layoutQuery.error ? (
                    <Alert type="error">
                        {t(
                            "backoffice.error.from_server",
                            "There was an error: {{errorText}}",
                            {
                                errorText: layoutQuery.error.message,
                            }
                        )}
                    </Alert>
                ) : null}
                {layoutQuery.loading ? <Loading /> : null}
                {layoutQuery.data?.layout ? (
                    <Tabs
                        labels={[
                            t("backoffice.layouts.layout", "Layout"),
                            t("backoffice.details", "Details"),
                        ]}
                    >
                        <LayoutEditor
                            form={form}
                            onSubmit={handleSubmit}
                            dimensions={gridDimensions}
                        />
                        <LayoutForm
                            form={form}
                            onSubmit={handleSubmit}
                            submitButton={["common.save", "Save"]}
                        />
                    </Tabs>
                ) : null}
            </Surface>
        </LayoutScreen>
    );
}
