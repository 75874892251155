import React, { createContext } from "react";
import { GridDimensions, TableSection } from "lib";
import { useReducer } from "react";
import {
    TableLayoutActionType,
    tableLayoutReducer,
    TableLayoutReducerState,
} from "./edit/table-layout-operations-reducer";
import { LayoutActionType } from "../layout-operation-reducer";

export const LayoutButtonsContext =
    createContext<TableLayoutReducerState | null>(null);
export const LayoutDispatchContext = createContext<React.Dispatch<
    LayoutActionType | TableLayoutActionType
> | null>(null);

export function TableLayoutProvider({
    sections,
    gridDimensions,
    onLayoutUpdate,
    children,
}: {
    sections: (TableSection & Partial<{ renaming: boolean }>)[];
    gridDimensions: GridDimensions;
    onLayoutUpdate: (value: any) => void;
    children: React.ReactNode;
}) {
    const [layoutState, dispatch] = useReducer(tableLayoutReducer, {
        sections,
        dimensions: gridDimensions,
        currentSectionIndex: 0,
        onLayoutUpdate,
    });

    return (
        <LayoutButtonsContext.Provider value={layoutState}>
            <LayoutDispatchContext.Provider value={dispatch}>
                {children}
            </LayoutDispatchContext.Provider>
        </LayoutButtonsContext.Provider>
    );
}
