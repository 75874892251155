import React, { useRef, useEffect, useCallback, useMemo } from "react";
import {
    Animated,
    Platform,
    Pressable,
    StyleProp,
    StyleSheet,
    TouchableOpacity,
    View,
    ViewStyle,
} from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import { StyleFunction, useTheme, useThemedStyle } from "../../theme";
import { Color } from "react-color";
import { useToast } from "../toast";
import { t } from "i18next";

export type ColorIndicatorStatus = "success" | "warning" | "error" | "inactive";

export function ColorIndicator({
    status,
    color,
    size,
    animate = true,
    copyOnClick = false,
    style,
}: {
    status?: ColorIndicatorStatus;
    color?: string;
    size?: "small" | "normal" | "large";
    animate?: boolean;
    copyOnClick?: boolean;
    style?: StyleProp<ViewStyle>;
}) {
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const toast = useToast();

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        Animated.timing(fadeAnim, {
            useNativeDriver: Platform.OS !== "web",
            toValue: 1,
            duration: 600,
        }).start();
    };

    let indicatorColor: Extract<Color, string>;

    if (!color) {
        switch (status) {
            case "success": {
                indicatorColor = theme.colors.success;

                break;
            }

            case "warning": {
                indicatorColor = theme.colors.warning;

                break;
            }

            case "error": {
                indicatorColor = theme.colors.error;

                break;
            }
            case "inactive": {
                indicatorColor = theme.colors.grey500;

                break;
            }
            default: {
                indicatorColor = theme.colors.white;
                break;
            }
        }
    } else {
        indicatorColor = color;
    }

    let sizeStyles: ViewStyle;
    switch (size) {
        case "small": {
            sizeStyles = styles.smallIndicator;
            break;
        }
        case "large": {
            sizeStyles = styles.largeIndicator;
            break;
        }
        case "normal":
        default: {
            sizeStyles = styles.normalIndicator;
        }
    }

    const flattenedStyles = StyleSheet.flatten([
        styles.baseIndicator,
        sizeStyles,
        { backgroundColor: indicatorColor },
        style,
    ]);

    const handleClick = useCallback(() => {
        if (!copyOnClick) {
            return;
        }

        Clipboard.setString(indicatorColor);
        toast.info(
            t(
                "backoffice.info.color_copied",

                "Color code {{ colorCode }} copied",
                { replace: { colorCode: indicatorColor } }
            )
        );
    }, [copyOnClick, indicatorColor, toast]);

    const buttonContent = useMemo(() => {
        if (!animate) {
            return <View style={flattenedStyles} />;
        }

        return (
            <Animated.View
                style={[
                    flattenedStyles,
                    {
                        opacity: fadeAnim,
                    },
                ]}
            />
        );
    }, [animate, fadeAnim, flattenedStyles]);

    useEffect(fadeIn);

    if (!copyOnClick) {
        return <Pressable>{buttonContent}</Pressable>;
    } else {
        return (
            <TouchableOpacity onPress={handleClick}>
                {buttonContent}
            </TouchableOpacity>
        );
    }
}

const styleFunc: StyleFunction = theme => ({
    baseIndicator: {
        borderColor: theme.colors.semiTransparentBlack,
        borderWidth: StyleSheet.hairlineWidth,
    },

    normalIndicator: {
        borderRadius: theme.spacingScale / 2,
        width: theme.spacingScale,
        height: theme.spacingScale,
    },

    smallIndicator: {
        borderRadius: theme.spacingScale / 4,
        width: theme.spacingScale / 2,
        height: theme.spacingScale / 2,
    },

    largeIndicator: {
        borderRadius: theme.spacingScale,
        width: theme.spacingScale * 2,
        height: theme.spacingScale * 2,
    },
});
