import React, {
    createContext,
    useContext,
    ReactNode,
    useEffect,
    useState,
    useCallback,
} from "react";
import type { IToast } from ".";
import { produce } from "immer";
import { Animated, Platform } from "react-native";
import { useTheme } from "../../theme";
import { getUnixTime } from "date-fns";

const DEFAULT_EXPIRE_SEC = 5;

let toastIdCounter: number = 0;
const ToastContext = createContext<IToastContext | null>(null);

type ToastOptions = Partial<{
    duration: IToast["expires"];
    showClose: IToast["showClose"];
    title: IToast["title"];
}>;

type ToastFunc = (message: string, options?: ToastOptions) => void;

export interface IToastContext {
    info: ToastFunc;
    warning: ToastFunc;
    error: ToastFunc;
    success: ToastFunc;
    onRemove: (toastId: string) => void;
    stack: IToast[];
}

const defaultFunc = () => {
    // deliberately empty
};

export function useToast(): IToastContext {
    const context = useContext(ToastContext);

    // We want these callbacks to be available regardless of whether the context is ready
    return (
        context ?? {
            stack: [],
            info: defaultFunc,
            warning: defaultFunc,
            error: defaultFunc,
            success: defaultFunc,
            onRemove: defaultFunc,
        }
    );
}

export function ProvideToast({ children }: { children: ReactNode }) {
    const [stack, setStack] = useState<IToast[]>([]);
    const { colors } = useTheme();

    const add = (toast: Partial<IToast>) => {
        if (!toast) {
            throw new Error("toast object cannot be empty");
        }

        // If the same message is already at the bottom of the stack, do not add another.
        if (
            stack.length > 0 &&
            stack.slice(0, 1)[0].message === toast.message
        ) {
            return;
        }

        const expireTime =
            toast.expires !== 0
                ? getUnixTime(new Date()) +
                  (toast.expires || DEFAULT_EXPIRE_SEC)
                : undefined;
        const ani = new Animated.Value(0);
        const nextToastId = toastIdCounter++;

        setStack(
            produce(draft => {
                draft.unshift({
                    ...toast,
                    id: nextToastId.toString(),
                    expires: expireTime,
                    animated: ani,
                } as IToast);
            })
        );

        // Animate the toast: Enter the screen.
        Animated.timing(ani, {
            toValue: 1,
            duration: 100,
            delay: 1, // Wait for the next render to animate.
            useNativeDriver: Platform.OS !== "web",
        }).start();
    };

    const info = (message: string, options?: ToastOptions) => {
        add({
            message,
            type: "default",
            iconName: "info",
            iconColor: colors.black,
            title: options?.title,
            expires: options?.duration,
            showClose: options?.showClose,
        });
    };

    const warning = (message: string, options?: ToastOptions) => {
        add({
            message,
            type: "warning",
            iconName: "warning",
            iconColor: colors.white,
            title: options?.title,
            expires: options?.duration,
            showClose: options?.showClose,
        });
    };

    const success = (message: string, options?: ToastOptions) => {
        add({
            message,
            type: "success",
            iconName: "success",
            iconColor: colors.white,
            title: options?.title,
            expires: options?.duration,
            showClose: options?.showClose,
        });
    };

    const error = (message: string, options?: ToastOptions) => {
        add({
            message,
            type: "error",
            iconName: "unknown",
            iconColor: colors.white,
            title: options?.title,
            expires: options?.duration,
            showClose: options?.showClose,
        });
    };

    const remove = useCallback((toastId: IToast["id"]) => {
        setStack(
            produce((draft: IToast[]) => {
                const idx = draft.findIndex(i => i.id === toastId);
                if (idx > -1) {
                    draft.splice(idx, 1);
                }
            })
        );
    }, []);

    // Cleanup mechanism
    useEffect(() => {
        if (stack.length === 0) {
            // No need for a timer if we're empty
            return;
        }

        const interval = setInterval(() => {
            const currentTime = getUnixTime(new Date());
            const expiredItems = stack.filter(
                item => item.expires && item.expires < currentTime
            );

            expiredItems.forEach(item => {
                Animated.timing(item.animated, {
                    toValue: 0,
                    duration: 250,
                    useNativeDriver: Platform.OS !== "web",
                }).start(() => {
                    remove(item.id);
                });
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [stack, remove]);

    return (
        <ToastContext.Provider
            value={{ info, warning, success, error, onRemove: remove, stack }}
        >
            {children}
        </ToastContext.Provider>
    );
}
