import React, { useCallback, useMemo } from "react";
import SliderImpl from "@react-native-community/slider";
import { useTheme } from "../../theme";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { InputLabel, Spacer, StyleFunction, Text, useThemedStyle } from "../..";

type SliderProps = {
    label?: string;
    value: number | undefined;
    minimumValue?: number;
    maximumValue?: number;
    minimumTrackTintColor?: string | undefined;
    maximumTrackTintColor?: string | undefined;
    thumbTintColor?: string | undefined;
    step?: number | undefined;
    onValueChange?: (value: number) => void;
    containerStyle?: StyleProp<ViewStyle>;
    markerStyle?: StyleProp<TextStyle>;
    marks?: any[];
};

export function Slider(props: SliderProps, key: string | number) {
    const styleSheet = useTheme();
    const styles = useThemedStyle(styleFunc);

    const renderMark = useCallback(
        (mark: { name: string; value: number }, index: number) => {
            const markStyle = [
                styles.mark,
                props.markerStyle,
                {
                    color:
                        props.value === mark.value
                            ? props.minimumTrackTintColor
                            : props.maximumTrackTintColor,
                },
            ];

            return (
                <Text key={`slider_mark_${key}_${index}`} style={markStyle}>
                    {mark.name}
                </Text>
            );
        },
        [
            key,
            props.markerStyle,
            props.maximumTrackTintColor,
            props.minimumTrackTintColor,
            props.value,
            styles.mark,
        ]
    );

    const renderMarks = useMemo(() => {
        if (!props.marks || !props.marks.length) {
            return;
        }

        return <View style={styles.marks}>{props.marks.map(renderMark)}</View>;
    }, [props.marks, renderMark, styles.marks]);

    if (!props.minimumValue || !props.maximumValue) {
        return null;
    }

    return (
        <View
            style={[
                props.containerStyle,
                !props.marks?.length ? null : styles.withMarks,
            ]}
        >
            {props.label ? (
                <>
                    <InputLabel>{props.label}</InputLabel>
                    <Spacer space={0.5} />
                </>
            ) : null}
            <SliderImpl
                key={key}
                value={props.value}
                minimumValue={props.minimumValue || 0}
                maximumValue={props.maximumValue || 1}
                minimumTrackTintColor={
                    props.minimumTrackTintColor || styleSheet.colors.grey500
                }
                maximumTrackTintColor={
                    props.maximumTrackTintColor || styleSheet.colors.lightGrey
                }
                thumbTintColor={
                    props.thumbTintColor || styleSheet.colors.primary
                }
                step={props.step || 1}
                onSlidingComplete={props.onValueChange}
            />
            {renderMarks ? (
                <>
                    {renderMarks}
                    <Spacer space={0.5} />
                </>
            ) : null}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    marks: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        marginHorizontal: theme.spacingScale / 2,
    },
    mark: {
        ...theme.fonts.medium,
        fontSize: 12,

        minWidth: theme.spacingScale,
        textAlign: "center",
    },
});
