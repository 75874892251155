import {
    Form,
    InputControl,
    Slider,
    Spacer,
    StyleFunction,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import type { LayoutButton, LayoutButtonMetaData } from "lib";
import React, { useMemo } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

export function ButtonSizeForm({
    form,
    metaData,
}: {
    form: Form<Pick<LayoutButton, "height" | "width">>;
    metaData: LayoutButtonMetaData;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue }] = form;

    const widthMarks = useMemo(() => {
        if (!metaData.maxWidthValue) {
            return;
        }

        const marks = [{ name: "1", value: 1 }];
        let currentMark = 2;

        while (currentMark <= metaData.maxWidthValue) {
            marks.push({ name: String(currentMark), value: currentMark });
            currentMark++;
        }

        return marks;
    }, [metaData.maxWidthValue]);

    const heightMarks = useMemo(() => {
        if (!metaData.maxHeightValue) {
            return;
        }

        const marks = [{ name: "1", value: 1 }];
        let currentMark = 2;

        while (currentMark <= metaData.maxHeightValue) {
            marks.push({ name: String(currentMark), value: currentMark });
            currentMark++;
        }

        return marks;
    }, [metaData.maxHeightValue]);

    if (!values || !metaData) {
        return null;
    }

    return (
        <View style={styles.rowContainer}>
            <InputControl
                error={errors.height}
                style={[styles.rowContent, styles.notLastCell]}
                description={t(
                    "backoffice.layout.button.height_placeholder",
                    "How many rows tall is the button?"
                )}
            >
                {metaData.maxHeightValue && metaData.maxHeightValue > 1 ? (
                    <Slider
                        label={t(
                            "backoffice.layout.button.height",
                            "Button height"
                        )}
                        key="slider_height"
                        value={values.height}
                        minimumValue={1}
                        maximumValue={metaData.maxHeightValue}
                        onValueChange={value => {
                            setValue("height", value);
                        }}
                        containerStyle={styles.sliderContainerStyle}
                        marks={heightMarks}
                    />
                ) : (
                    <TextInput
                        disabled={true}
                        label={t(
                            "backoffice.layout.button.height",
                            "Button height"
                        )}
                        defaultValue={(values.height || 1).toString(10)}
                    />
                )}
            </InputControl>
            <Spacer space={2} />
            <InputControl
                error={errors.width}
                style={[styles.rowContent]}
                description={t(
                    "backoffice.layout.button.width_placeholder",
                    "How many columns wide is the button?"
                )}
            >
                {metaData.maxWidthValue && metaData.maxWidthValue > 1 ? (
                    <Slider
                        label={t(
                            "backoffice.layout.button.width",
                            "Button width"
                        )}
                        key="slider_width"
                        value={values.width}
                        minimumValue={1}
                        maximumValue={metaData.maxWidthValue}
                        onValueChange={value => {
                            setValue("width", value);
                        }}
                        containerStyle={styles.sliderContainerStyle}
                        marks={widthMarks}
                    />
                ) : (
                    <TextInput
                        disabled={true}
                        label={t(
                            "backoffice.layout.button.width",
                            "Button width"
                        )}
                        defaultValue={(values.width || 1).toString(10)}
                    />
                )}
            </InputControl>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContent: {
        flex: 1,
        visibility: "visible",
    },
    rowContainer: {
        flexDirection: "row",
    },
    notLastCell: {
        paddingEnd: theme.spacingScale,
    },
    sliderLabel: { marginBottom: theme.spacingScale * 2 },
    sliderContainerStyle: {
        flex: 1,
    },
});
