import React, { useCallback, useEffect } from "react";
import { View } from "react-native";
import { useTranslation } from "locales";
import {
    useAuth,
    usePagination,
    useThemedStyle,
    DataTable,
    Button,
    Alert,
    Loading,
    Icon,
    useMerchantConfig,
    Surface,
    Spacer,
    useConfirm,
    useSearch,
    useTheme,
} from "@venuepos/react-common";
import type { StyleFunction } from "@venuepos/react-common";
import { useCustomerDeleteMutation, useCustomersQuery } from "graphql-sdk";
import type { GQCustomersQuery } from "graphql-sdk";
import { CustomerItem } from "./customer-item";
import { useAdminSession } from "../../../session";
import type { AvailableLocale } from "locales";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { RootStackParamList } from "../../../navigation";
import { useHandleMutationError, useSearchDefinition } from "../../../hooks";
import { SearchDefinition } from "lib";

export function CustomersTab() {
    const auth = useAuth();
    auth.enforce("merchant.customer");

    const { navigate } = useNavigation();
    const route = useRoute<RouteProp<RootStackParamList, "CUSTOMERS">>();
    const confirm = useConfirm();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const { createSearchDefinition } = useSearchDefinition();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    const defaultCustomerSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        group: {
            name: t("searching.group", "Group"),
            type: "string",
            value: "",
            enabled: false,
        },
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultCustomerSearch, {
        visible: true,
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);

    // GraphQL
    const { data, error, loading, refetch } = useCustomersQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    // Actions

    const handleEdit = useCallback(
        (id: string) => {
            navigate("CUSTOMER_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const [deleteCustomer] = useCustomerDeleteMutation();
    const { handleMutationError } = useHandleMutationError();

    const handleDelete = useCallback(
        async (customerId: string) => {
            if (
                !(await confirm(
                    t("backoffice.customer.delete", "Delete Customer?"),
                    t(
                        "backoffice.customer.delete_explain",
                        "If you choose to delete this customer, you will not be able to use related accounts, tags, etc. \n\nAre you sure, you want to delete?"
                    )
                ))
            ) {
                return;
            }

            await handleMutationError(
                async () =>
                    await deleteCustomer({ variables: { id: customerId! } }),
                t("common.deleted", "Deleted"),
                () => {
                    navigate("ACCOUNT_PAYMENTS", { refetch: true });
                }
            );
        },
        [confirm, deleteCustomer, handleMutationError, navigate, t]
    );

    return (
        <>
            <View style={[theme.styles.row, styles.container]}>
                <Button
                    type="secondary"
                    onPress={() => {
                        navigate("CUSTOMER_CREATE");
                    }}
                    testID="customerAccount:create"
                >
                    {t("common.create", "Create")}
                </Button>
                <Spacer space={1} />
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "groupName"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("groupName")}
                        >
                            {t(
                                "backoffice.common.customer_group",
                                "Customer group"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "createdAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("createdAt")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title numeric>
                            {t("common.balance", "Balance")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={styles.iconColumn.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        data?.customers.data.map(
                            (
                                item: GQCustomersQuery["customers"]["data"][0],
                                index
                            ) => (
                                <CustomerItem
                                    key={item.id}
                                    item={item}
                                    onEdit={() => handleEdit(item.id)}
                                    currency={currency}
                                    locale={locale as AvailableLocale}
                                    onDelete={() => {
                                        handleDelete(item.id);
                                    }}
                                    style={
                                        index % 2 ? styles.oddRow : undefined
                                    }
                                />
                            )
                        )
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.customers.pagination.pages}
                        itemCount={data?.customers.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    iconColumn: {
        flexBasis: 100,
        flexGrow: 0,
        flexShrink: 0,
        color: theme.colors.black,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
