import React, { useMemo } from "react";
import GridLayout from "react-grid-layout";
import { GridDimensions } from "lib";
import {
    CONTAINER_PADDING,
    ITEM_MARGIN,
    ITEM_MARGIN_VERTICAL,
} from "../layout-grid-section";

export const VisualGrid = React.memo(
    ({
        dimensions,
        height,
        width,
    }: {
        dimensions: GridDimensions;
        height: number;
        width: number;
    }) => {
        const visualGridItems = useMemo(() => {
            return [...Array(dimensions.rows * dimensions.columns)]
                .fill("")
                .map((_, index) => (
                    <div
                        key={index.toString()}
                        data-grid={{
                            i: index.toString(),
                            x: index % dimensions.columns,
                            y: Math.floor(index / dimensions.columns),
                            w: 1,
                            h: 1,
                        }}
                        style={cssStyles.gridItem}
                    >
                        <div />
                    </div>
                ));
        }, [dimensions.columns, dimensions.rows]);

        const gridStyle = useMemo(() => {
            return { ...cssStyles.gridLayout, ...{ height: height } };
        }, [height]);

        return (
            <GridLayout
                className="visual_grid"
                containerPadding={CONTAINER_PADDING}
                margin={ITEM_MARGIN}
                cols={dimensions.columns}
                rowHeight={height / dimensions.rows - ITEM_MARGIN_VERTICAL * 2}
                width={width}
                compactType={null}
                autoSize={false}
                isBounded={true}
                isDraggable={false}
                isResizable={false}
                style={gridStyle}
            >
                {visualGridItems}
            </GridLayout>
        );
    },
    (newProps, oldProps) =>
        newProps.dimensions.columns === oldProps.dimensions.columns &&
        newProps.dimensions.rows === oldProps.dimensions.rows &&
        newProps.height === oldProps.height
);

// Styles used with the React / HTML elements
const cssStyles: { [key: string]: React.CSSProperties } = {
    gridItem: {
        borderColor: "lightgray",
        borderStyle: "dashed",
        borderWidth: 1,
    },
    gridLayout: {
        position: "absolute",
    },
};
