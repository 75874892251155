import React from "react";
import { View } from "react-native";
import { StyleFunction, Switch, useThemedStyle } from "@venuepos/react-common";

// Handles type: boolean
export function EntityFormSwitchInput({
    higherOrder,
    value,
    onValueChange,
    disabled,
    testID,
}: {
    higherOrder: any;
    value: any;
    onValueChange: (v: any) => void;
    disabled: boolean;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);

    if (!disabled) {
        return (
            <View style={styles.switch}>
                <Switch
                    value={value}
                    onValueChange={onValueChange}
                    testID={testID}
                />
            </View>
        );
    } else {
        return (
            <View style={styles.switch}>
                <Switch
                    value={higherOrder}
                    key="disabled"
                    disabled={true}
                    testID={testID}
                />
            </View>
        );
    }
}

const styleFunc: StyleFunction = theme => ({
    switch: {
        paddingVertical: theme.spacingScale,
    },
});
