import {
    StyleFunction,
    useConfirm,
    useThemedStyle,
} from "@venuepos/react-common";
import { v4 as uuidv4 } from "uuid";
import type { GridDimensions, LayoutTableButton } from "lib";
import React, { useCallback, useMemo } from "react";
import GridLayout from "react-grid-layout";
import { useTranslation } from "locales";
import { StyleSheet, View } from "react-native";
import { VisualGrid } from "./visual-grid";
import { VisualLayoutButton } from "./visual-layout-button";
import { useLayoutButtons, useLayoutButtonsDispatch } from "../buttons-context";
import {
    TableLayoutAction,
    tableLayoutReducer,
} from "./table-layout-operations-reducer";
import { useEditButtonModal } from "./edit-button-modal";
import {
    ITEM_MARGIN_VERTICAL,
    CONTAINER_PADDING_VERTICAL,
    CONTAINER_PADDING,
    ITEM_MARGIN,
} from "../layout-grid-section";

const newButtonDefaultWidth = 2;
const newButtonDefaultHeight = 2;
const dropItem = {
    i: "dropElement",
    w: newButtonDefaultWidth,
    h: newButtonDefaultHeight,
};

export function GridEditor({
    dimensions,
    gridHeight,
    gridWidth,
}: {
    dimensions: GridDimensions;
    gridHeight: number;
    gridWidth: number;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const confirm = useConfirm();
    const renderEditButtonModal = useEditButtonModal();

    const dispatch = useLayoutButtonsDispatch();
    const layoutData = useLayoutButtons();

    /**
     * Callback for dropping a new table/button onto the grid.
     */
    const handleAddButton = useCallback(
        (layout, layoutItem) => {
            if (!dispatch || !layoutData) {
                return;
            }

            // If the layout algorithm suggest to add the new button outside the grid, then back off. This is not a good idea.
            if (
                layoutItem.y + layoutItem.h > dimensions.rows ||
                layoutItem.x + layoutItem.w > dimensions.columns
            ) {
                return;
            }

            const newButton: LayoutTableButton = {
                buttonType: "TABLE",
                x: layoutItem.x,
                y: layoutItem.y,
                width: newButtonDefaultWidth,
                height: newButtonDefaultHeight,
                color: "",
                label: `${
                    layoutData.sections[layoutData.currentSectionIndex].buttons
                        .length + 1
                }`,
                id: uuidv4(),
            };

            dispatch({
                type: TableLayoutAction.ADD_BUTTON_TO_SECTION,
                newButton,
            });

            return layoutData.onLayoutUpdate(
                tableLayoutReducer(layoutData, {
                    type: TableLayoutAction.ADD_BUTTON_TO_SECTION,
                    newButton,
                }).sections
            );
        },
        [dimensions.columns, dimensions.rows, dispatch, layoutData]
    );

    const handleDeleteButton = useCallback(
        async (buttonId: LayoutTableButton["id"]) => {
            if (!dispatch || !layoutData) {
                return;
            }

            const foundButtonIndex = layoutData.sections[
                layoutData.currentSectionIndex
            ].buttons.findIndex(buttonItr => buttonItr.id === buttonId);
            if (foundButtonIndex === -1) {
                console.error(
                    "Invalid input for handleShowButtonModal. Requested button",
                    buttonId,
                    "not found. Back off."
                );
                return;
            }

            if (
                !(await confirm(
                    t(
                        "backoffice.layout.delete_button_header",
                        "Delete button?"
                    ),
                    t(
                        "backoffice.layout.delete_button_body",
                        "Are you sure you want delete this button?"
                    )
                ))
            ) {
                return;
            }

            dispatch({
                type: TableLayoutAction.REMOVE_BUTTON_FROM_SECTION,
                buttonId: buttonId,
            });

            return layoutData.onLayoutUpdate(
                tableLayoutReducer(layoutData, {
                    type: TableLayoutAction.REMOVE_BUTTON_FROM_SECTION,
                    buttonId: buttonId,
                }).sections
            );
        },
        [confirm, dispatch, layoutData, t]
    );

    const handleShowButtonSettings = useCallback(
        async (button: LayoutTableButton) => {
            if (!dispatch || !layoutData) {
                return;
            }

            const editButtonResult = await renderEditButtonModal({
                button,
            });

            if (!editButtonResult) {
                return;
            }

            if (!["DELETE", "EDIT"].includes(editButtonResult.action)) {
                console.error(
                    "Unknown EditButtonResult.action:",
                    editButtonResult.action,
                    ":",
                    editButtonResult
                );
            }

            if (editButtonResult.action === "DELETE") {
                if (
                    !(await confirm(
                        t(
                            "backoffice.layout.delete_button_header",
                            "Delete button?"
                        ),
                        t(
                            "backoffice.layout.delete_button_body",
                            "Are you sure you want delete this button?"
                        )
                    ))
                ) {
                    return;
                }

                dispatch({
                    type: TableLayoutAction.REMOVE_BUTTON_FROM_SECTION,
                    buttonId: button.id,
                });

                return layoutData.onLayoutUpdate(
                    tableLayoutReducer(layoutData, {
                        type: TableLayoutAction.REMOVE_BUTTON_FROM_SECTION,
                        buttonId: button.id,
                    }).sections
                );
            }

            if (editButtonResult.action === "EDIT") {
                dispatch({
                    type: TableLayoutAction.UPDATE_BUTTON_IN_SECTION,
                    updatedButton: {
                        ...button,
                        ...editButtonResult.formValues,
                    },
                });

                return layoutData.onLayoutUpdate(
                    tableLayoutReducer(layoutData, {
                        type: TableLayoutAction.UPDATE_BUTTON_IN_SECTION,
                        updatedButton: {
                            ...button,
                            ...editButtonResult.formValues,
                        },
                    }).sections
                );
            }
        },
        [confirm, dispatch, layoutData, renderEditButtonModal, t]
    );

    const handleShowButtonById = useCallback(
        (buttonId: LayoutTableButton["id"]) => {
            if (!dispatch || !layoutData) {
                return;
            }

            const foundButtonIndex = layoutData.sections[
                layoutData.currentSectionIndex
            ].buttons.findIndex(buttonItr => buttonItr.id === buttonId);
            if (foundButtonIndex === -1) {
                console.error(
                    "Invalid input for handleShowButtonModal. Requested button",
                    buttonId,
                    "not found. Back off."
                );
                return;
            }

            handleShowButtonSettings(
                layoutData.sections[layoutData.currentSectionIndex].buttons[
                    foundButtonIndex
                ]
            );
        },
        [dispatch, handleShowButtonSettings, layoutData]
    );

    const handleResizeButton = useCallback(
        (_, prevItem, newItem) => {
            if (!dispatch || !layoutData) {
                return;
            }

            const { w: oldWidth, h: oldHeight } = prevItem;
            const { w: newWidth, h: newHeight } = newItem;

            if (oldWidth === newWidth && oldHeight === newHeight) {
                return;
            }

            dispatch({
                type: TableLayoutAction.RESIZE_BUTTON_IN_SECTION,
                buttonId: newItem.i,
                buttonDimensions: { width: newItem.w, height: newItem.h },
            });

            return layoutData.onLayoutUpdate(
                tableLayoutReducer(layoutData, {
                    type: TableLayoutAction.RESIZE_BUTTON_IN_SECTION,
                    buttonId: newItem.i,
                    buttonDimensions: { width: newItem.w, height: newItem.h },
                }).sections
            );
        },
        [dispatch, layoutData]
    );

    const handleMoveButton = useCallback(
        (_, prevItem, newItem) => {
            if (!dispatch || !layoutData) {
                return;
            }

            const { x: oldX, y: oldY } = prevItem;
            const { x: newX, y: newY } = newItem;

            if (oldX === newX && oldY === newY) {
                return;
            }

            dispatch({
                type: TableLayoutAction.MOVE_BUTTON_IN_SECTION,
                buttonId: newItem.i,
                position: { x: newItem.x, y: newItem.y },
            });

            return layoutData.onLayoutUpdate(
                tableLayoutReducer(layoutData, {
                    type: TableLayoutAction.MOVE_BUTTON_IN_SECTION,
                    buttonId: newItem.i,
                    position: { x: newItem.x, y: newItem.y },
                }).sections
            );
        },
        [dispatch, layoutData]
    );

    /**
     * Make sure the resized items cannot extend below the grid.
     */
    const handleButtonResizeStart = useCallback(
        (_, __, newItem) => {
            newItem.maxW = dimensions.columns - newItem.x;
            newItem.maxH = dimensions.rows - newItem.y;
        },
        [dimensions.columns, dimensions.rows]
    );

    const layoutStyle = useMemo(() => {
        const returnValue = {
            height:
                gridHeight -
                ITEM_MARGIN_VERTICAL * dimensions.rows +
                CONTAINER_PADDING_VERTICAL,
        };

        return returnValue;
    }, [dimensions.rows, gridHeight]);

    return (
        <View>
            <DroppableElement />
            <View style={styles.gridEditor}>
                <VisualGrid
                    dimensions={dimensions}
                    height={gridHeight}
                    width={gridWidth}
                />
                <GridLayout
                    className="layout"
                    cols={dimensions.columns}
                    style={layoutStyle}
                    containerPadding={CONTAINER_PADDING}
                    margin={ITEM_MARGIN}
                    rowHeight={
                        gridHeight / dimensions.rows - ITEM_MARGIN_VERTICAL * 2
                    }
                    width={gridWidth}
                    maxRows={dimensions.rows}
                    compactType={null}
                    autoSize={false}
                    preventCollision={true}
                    isDroppable={true}
                    isBounded={true}
                    onDrop={handleAddButton}
                    onResizeStart={handleButtonResizeStart}
                    onResizeStop={handleResizeButton}
                    onDragStop={handleMoveButton}
                    droppingItem={dropItem}
                >
                    {layoutData?.sections[
                        layoutData?.currentSectionIndex
                    ].buttons.map((item: LayoutTableButton) => (
                        <div
                            style={cssStyles.button}
                            key={item.id}
                            data-grid={{
                                x: item.x,
                                y: item.y,
                                w: item.width,
                                h: item.height,
                                draggableHandle: ".gridHandle",
                                isDraggable: true, // Allow the item to be moved by the user
                                isResizable: true, // Allow the item to be resized by the user
                                static: true, // Do not move the element, when another element is moved over it.
                            }}
                        >
                            <VisualLayoutButton
                                label={item.label}
                                buttonId={item.id}
                                onDelete={() => handleDeleteButton(item.id)}
                                onPress={() => handleShowButtonById(item.id)}
                            />
                        </div>
                    ))}
                </GridLayout>
            </View>
        </View>
    );
}

function DroppableElement() {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <View style={styles.droppableElement}>
            <div
                style={cssStyles.droppableElement}
                className="droppable-element"
                draggable={true}
                unselectable="on"
                // this is a hack for firefox
                // Firefox requires some kind of initialization
                // which we can do by adding this attribute
                // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                onDragStart={e => e.dataTransfer.setData("text/plain", "")}
            >
                {t(
                    "backoffice.table_layout_editor.drag_from_here",
                    "Drag new table from here"
                )}
            </div>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    gridEditor: {
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: theme.colors.lightGrey,
    },
    droppableElement: {
        width: "auto",
        maxWidth: 200,
        minWidth: 100,
        height: 60,
        borderWidth: StyleSheet.hairlineWidth,
        borderRadius: theme.borderRadiusTiny,
        borderColor: theme.colors.grey250,
        justifyContent: "center",
        backgroundColor: theme.colors.grey100,
        marginVertical: theme.spacingScale,
        fontFamily: "NunitoSans-Regular",
        fontSize: 12,
        color: theme.colors.textDark,
    },
});

// Styles used with the React / HTML elements above
const cssStyles: { [key: string]: React.CSSProperties } = {
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ccc",
        padding: 1,
        cursor: "grab",
    },
    droppableElement: {
        cursor: "grab",
        padding: 20,
    },
};
