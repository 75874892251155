import {
    DataTable,
    Icon,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQCashRegistersStatusQuery,
    useCashRegistersStatusQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { RootStackScreenProps } from "../../../navigation";
import { useAdminSession } from "../../../session";
import { AdminContainer } from "../../container";
import { CashRegisterStatusRow } from "./cash-register-status-row";
import { useSearchDefinition } from "../../../hooks";
import { SearchDefinition } from "lib";
import { View } from "react-native";

type ScreenProps = RootStackScreenProps<"CASH_REGISTER_STATUS">;

export function CashRegisterStatusListScreen({ route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["admin.role"]);

    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "deviceLastSeen",
        initialSortDirection: "DESC",
    });

    const defaultCashRegisterStatusSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        merchantName: createSearchDefinition("string", {
            name: t("backoffice.common.merchant", "Merchant"),
        }),
        deviceLastSeen: createSearchDefinition("dateInterval", {
            name: t("backoffice.cash_register_status.last_seen", "Last seen"),
        }),
        lastNotifySentAt: createSearchDefinition("dateInterval", {
            name: t(
                "backoffice.cash_register_status.last_notify_sent_at",
                "Last notify"
            ),
        }),
        lastAppDataSentAt: createSearchDefinition("dateInterval", {
            name: t(
                "backoffice.cash_register_status.last_app_data_sent_at",
                "App data sent"
            ),
        }),
        deviceAppVersion: createSearchDefinition("string", {
            name: t(
                "backoffice.cash_register_status.app_version",
                "App version"
            ),
        }),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultCashRegisterStatusSearch, {
        visible: true,
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    // GraphQL
    const { data, loading, refetch } = useCashRegistersStatusQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });
    CashRegisterStatusRow;
    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const renderCashRegisterStatusRow = useCallback(
        (
            item: GQCashRegistersStatusQuery["cashRegistersStatus"]["data"][0],
            rowIndex
        ) => (
            <CashRegisterStatusRow
                key={item.id}
                item={item}
                locale={locale}
                style={rowIndex % 2 === 1 ? styles.oddRow : undefined}
            />
        ),
        [locale, styles.oddRow]
    );

    return (
        <AdminContainer>
            <View style={[theme.styles.row, styles.container]}>
                <Spacer space={1} />
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t(
                                "backoffice.common.cash_register",
                                "Cash Register"
                            )}
                        </DataTable.Title>

                        <DataTable.Title
                            sortDirection={
                                sortBy === "merchant"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("merchant")}
                        >
                            {t("backoffice.common.merchant", "Merchant")}
                        </DataTable.Title>

                        <DataTable.Title
                            sortDirection={
                                sortBy === "appVersion"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("appVersion")}
                        >
                            {t(
                                "backoffice.cash_register_status.app_version",
                                "App version"
                            )}
                        </DataTable.Title>

                        <DataTable.Title
                            sortDirection={
                                sortBy === "lastNotifySentAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("lastNotifySentAt")}
                        >
                            {t(
                                "backoffice.cash_register_status.last_notify_sent_at",
                                "Last notify"
                            )}
                        </DataTable.Title>

                        <DataTable.Title
                            sortDirection={
                                sortBy === "lastAppDataSentAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("lastAppDataSentAt")}
                        >
                            {t(
                                "backoffice.cash_register_status.last_app_data_sent_at",
                                "App data sent"
                            )}
                        </DataTable.Title>

                        <DataTable.Title
                            sortDirection={
                                sortBy === "deviceLastSeen"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("deviceLastSeen")}
                        >
                            {t(
                                "backoffice.cash_register_status.last_seen",
                                "Last seen"
                            )}
                        </DataTable.Title>

                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={styles.iconColumn.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data?.cashRegistersStatus.data.map(
                            renderCashRegisterStatusRow
                        )
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={
                            data?.cashRegistersStatus.pagination.pages
                        }
                        itemCount={
                            data?.cashRegistersStatus.pagination.resultCount
                        }
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: theme.spacingScale * 6,
        color: theme.colors.black,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
