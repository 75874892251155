import React from "react";
import { View, Text, Platform, TouchableOpacity } from "react-native";
import { Icon, useThemedStyle, useDimensions, StyleFunction } from "../..";
import { PERMANENT_DRAWER_WIDTH } from "../navigation";
import { IMenuItem } from "./types";

export function MenuItem({
    item,
    isExpanded,
    onPress,
    onExpand,
}: {
    item: IMenuItem;
    isExpanded: boolean;
    onPress: (item: IMenuItem) => void;
    onExpand: (item: IMenuItem) => void;
}) {
    const groupIcon = isExpanded ? "dropup" : "dropdown";
    const styles = useThemedStyle(styleFunc);
    const { width } = useDimensions();

    const isFloating = width <= PERMANENT_DRAWER_WIDTH;
    const shouldExpand = (isFloating || !item.link) && item.items?.length > 0;

    return (
        <>
            <View
                style={[
                    styles.row,
                    item.isActive && !shouldExpand ? styles.activeRow : null,
                ]}
                testID={`${item.testID}:${
                    isExpanded ? "isExpanded" : "isClosed"
                }`}
            >
                <TouchableOpacity
                    style={[styles.flex, styles.row, styles.menuItem]}
                    testID={item.testID}
                    onPress={
                        shouldExpand
                            ? () => onExpand(item)
                            : () => onPress(item)
                    }
                >
                    <View style={styles.menuIcon}>
                        <Icon
                            name={item.iconName || "default"}
                            color={styles.menuIcon.color}
                            size="small"
                        />
                    </View>
                    <View style={styles.flex}>
                        <Text
                            style={[
                                styles.textColor,
                                Platform.OS === "android"
                                    ? styles.menuTextNative
                                    : styles.menuTextWeb,
                            ]}
                        >
                            {item.name}
                        </Text>
                    </View>
                    {item.items ? (
                        <Icon name={groupIcon} color={styles.menuIcon.color} />
                    ) : null}
                </TouchableOpacity>
            </View>
            {isExpanded && isFloating && item.items?.length > 0 ? (
                <View
                    style={[
                        styles.menuChildren,
                        item.isActive ? styles.activeRow : null,
                    ]}
                    key={item.name}
                >
                    <TouchableOpacity onPress={() => onPress(item)}>
                        <Text
                            style={[
                                styles.textColor,
                                Platform.OS === "android"
                                    ? styles.menuTextNative
                                    : styles.menuTextWeb,
                            ]}
                        >
                            {item.name}
                        </Text>
                    </TouchableOpacity>
                </View>
            ) : null}
            {isExpanded
                ? item.items?.map(childItem => (
                      <TouchableOpacity
                          key={childItem.name}
                          style={[
                              styles.menuChildren,
                              childItem.isActive ? styles.activeRow : null,
                          ]}
                          onPress={() => onPress(childItem)}
                          testID={childItem.testID}
                      >
                          <Text
                              style={[
                                  styles.textColor,
                                  Platform.OS === "android"
                                      ? styles.menuTextNative
                                      : styles.menuTextWeb,
                              ]}
                          >
                              {childItem.name}
                          </Text>
                      </TouchableOpacity>
                  ))
                : null}
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    flex: { ...theme.styles.flex1 },
    row: {
        ...theme.styles.row,
    },

    menuItem: {
        alignItems: "center",
        padding: theme.spacingScale,
    },

    menuChildren: {
        backgroundColor: theme.colors.tertiary,
        justifyContent: "center",
        padding: theme.spacingScale,
        paddingLeft: theme.spacingScale * 4.5,
    },

    activeRow: {
        backgroundColor: theme.colors.primary,
    },

    menuIcon: {
        width: theme.spacingScale * 3.5,
        color: theme.colors.white,
    },

    textColor: {
        color: theme.colors.textLight,
    },

    menuTextNative: {
        ...theme.fonts.regular,
        fontSize: 12,
    },

    menuTextWeb: {
        ...theme.fonts.regular,
        fontSize: 16,
    },
});
