import React from "react";
import { useMemo } from "react";
import { StyleProp, ViewStyle, TouchableOpacity } from "react-native";
import type { SortDirection } from "../..";
import { useThemedStyle, StyleFunction } from "../../theme";
import { Icon } from "../icon";
import { Text } from "../text";

export function DataGridTitle({
    children,
    numberOfLines = 1,
    numeric = false,
    onPress,
    style,
    sortDirection,
}: {
    children?: string | JSX.Element;
    onPress?: () => void;
    numberOfLines?: number;
    numeric?: boolean;
    sortDirection?: SortDirection;
    style?: StyleProp<ViewStyle>;
}) {
    let styles = useThemedStyle(styleFunc);
    let childElement;
    if (typeof children === "string") {
        childElement = (
            <Text style={styles.cell} numberOfLines={numberOfLines}>
                {children}
            </Text>
        );
    } else {
        childElement = children;
    }

    const sortIcon = useMemo(() => {
        if (sortDirection === "ASC") {
            return <Icon name="arrow-up" style={styles.icon} />;
        }

        if (sortDirection === "DESC") {
            return <Icon name="arrow-down" style={styles.icon} />;
        }
    }, [sortDirection, styles.icon]);

    return (
        <TouchableOpacity
            style={[styles.container, numeric && styles.right, style]}
            disabled={!onPress}
            onPress={onPress}
        >
            {numeric && sortIcon}
            {childElement}
            {!numeric && sortIcon}
        </TouchableOpacity>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flex: 1,
        flexDirection: "row",
        alignContent: "center",
        paddingVertical: 12,
        paddingRight: theme.spacingScale,
    },

    right: {
        justifyContent: "flex-end",
    },

    cell: {
        height: 24,
        lineHeight: 24,
        fontSize: 16,
        fontWeight: "700",
        alignItems: "center",
        color: theme.colors.textDark,
    },
    icon: {
        height: 24,
        color: theme.colors.textDark,
        justifyContent: "center",
        marginHorizontal: 8,
    },
});
