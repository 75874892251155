import React, { useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import { useTranslation } from "locales";
import { FlatList } from "react-native-gesture-handler";
import { formatAmount, ILayoutProduct, SearchDefinition } from "lib";
import { GQProductsQuery, useProductsQuery } from "graphql-sdk";
import {
    useThemedStyle,
    usePagination,
    useMerchantConfig,
    DataTable,
    Alert,
    Button,
    StyleFunction,
    Text,
    useSearch,
    Spacer,
    useTheme,
    Loading,
} from "@venuepos/react-common";
import { ProductRow } from "./product-table-row";
import { ProductTableHeader } from "./product-table-header";
import { useAdminSession } from "../../../../../../session";
import type { AvailableLocale } from "locales";
import { Divider } from "react-native-paper";
import { useSearchDefinition } from "../../../../../../hooks";

export function ProductSelector({
    addedProducts,
    onSelectProduct,
    onAddProductsToLayout,
}: {
    addedProducts: string[];
    onSelectProduct: ({
        selectedProduct,
        selectedButtonColor,
        selectedButtonLabel,
    }: {
        selectedProduct: ILayoutProduct;
        selectedButtonColor: string;
        selectedButtonLabel: string;
    }) => void;
    onAddProductsToLayout: (products: ILayoutProduct[]) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();
    const merchantConfig = useMerchantConfig();

    const { page, pageSize, sortBy, sortDirection, onSortChange } =
        usePagination({
            initialPageSize: 250,
            initialSortBy: "name",
        });

    const defaultLayoutSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        group: {
            name: t("searching.product_group", "Product group"),
            type: "string",
            value: "",
            enabled: false,
        },
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultLayoutSearch, {
        buttonSize: "small",
        hideCloseButton: true,
        visible: true,
    });

    const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);
    const [selectedProducts, setSelectedProducts] = useState<ILayoutProduct[]>(
        []
    );

    const { data, error, loading } = useProductsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
            includeChildren: true,
        },
        fetchPolicy: "no-cache",
    });

    const handleSelectProduct = useCallback(
        (productId: string) => {
            if (!data || !data.products.data) {
                return;
            }
            onSelectProduct({
                selectedProduct: data.products.data.find(
                    itr => itr.id === productId
                )! as ILayoutProduct,
                selectedButtonColor: "",
                selectedButtonLabel: "",
            });
        },
        [data, onSelectProduct]
    );

    const handleAddProducts = useCallback(() => {
        if (!data || !data.products.data) {
            return;
        }

        return onAddProductsToLayout(selectedProducts);
    }, [data, onAddProductsToLayout, selectedProducts]);

    const handleSelectAll = useCallback(
        (value: boolean) => {
            if (!data || !data.products.data) {
                return;
            }

            setAllRowsSelected(value);

            const productIdsFromThisSearch = data.products.data.map(p => p.id);
            const selectedProductIds = selectedProducts.map(p => p.id);

            // If the "select all" checkbox is switched off, then remove all products
            // from the current data set in the list of selected products.
            if (!value) {
                const filteredLists = selectedProducts.filter(
                    p => !productIdsFromThisSearch.includes(p.id)
                );
                setSelectedProducts(filteredLists);
                return;
            }

            const mappedProducts = mapToLayoutProducts(
                productIdsFromThisSearch,
                data.products.data
            );

            const mappedProductsMinusAlreadySelected = mappedProducts.filter(
                p => !selectedProductIds.includes(p.id)
            );

            setSelectedProducts([
                ...selectedProducts,
                ...mappedProductsMinusAlreadySelected,
            ]);
        },
        [data, selectedProducts]
    );

    const handleSelectNotAddedProducts = useCallback(() => {
        if (!data || !data.products.data) {
            return;
        }

        // Find the list of all the products in the current result set, that have NOT been added to a product.
        const allNotAddedProducts = data.products.data.filter(
            p => !addedProducts.includes(p.id)
        );

        if (allNotAddedProducts.length <= 0) {
            return;
        }

        setAllRowsSelected(
            allNotAddedProducts.length === data.products.data.length
        );

        setSelectedProducts([
            ...mapToLayoutProducts(
                allNotAddedProducts.map(p => p.id),
                data.products.data
            ),
        ]);
    }, [addedProducts, data]);

    const handleAddSingleProductToSelection = useCallback(
        (booleanValue: boolean, productId: ILayoutProduct["id"]) => {
            if (!data || !data.products || !data?.products.data) {
                return;
            }

            if (!booleanValue) {
                // remove the element from the list of selected products
                setSelectedProducts(
                    selectedProducts.filter(p => p.id !== productId)
                );
                return;
            }

            if (!selectedProducts.find(p => p.id === productId)) {
                setSelectedProducts([
                    ...selectedProducts,
                    ...mapToLayoutProducts([productId], data.products.data).map(
                        productItr => productItr
                    ),
                ]);
                return;
            }
        },
        [data, selectedProducts]
    );

    const handleAddSingleToLayout = useCallback(
        productId => {
            if (!data || !data.products || !data.products.data) {
                return;
            }

            const addProduct = data.products.data.find(
                itr => itr.id === productId
            )! as ILayoutProduct;

            return onAddProductsToLayout([addProduct]);
        },
        [data, onAddProductsToLayout]
    );

    const renderItem = useCallback(
        ({ item }: { item: GQProductsQuery["products"]["data"][0] }) => (
            <ProductRow
                item={item}
                formattedAmount={formatAmount(
                    item.amount,
                    merchantConfig.currency,
                    {
                        locale: locale as AvailableLocale,
                    }
                )}
                onSelect={handleSelectProduct}
                selectedProduct={selectedProducts
                    .map(productItr => productItr.id)
                    .includes(item.id)}
                alreadyAdded={addedProducts.includes(item.id)}
                onMultiSelectChange={handleAddSingleProductToSelection}
                onAddSingleToLayout={handleAddSingleToLayout}
            />
        ),
        [
            addedProducts,
            handleAddSingleProductToSelection,
            handleAddSingleToLayout,
            handleSelectProduct,
            locale,
            merchantConfig.currency,
            selectedProducts,
        ]
    );

    const emptyComponent = useMemo(() => {
        if (search === "") {
            return null;
        }

        if (data?.products.data.length !== 0) {
            return null;
        }

        return <EmptyList />;
    }, [data?.products.data.length, search]);

    const footerComponent = useMemo(() => {
        if (loading || !data || data?.products.pagination.pages <= 1) {
            return null;
        }

        return <ListFooter count={pageSize} />;
    }, [data, loading, pageSize]);

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <>
            <View style={theme.styles.row}>
                <Button
                    size="small"
                    onPress={handleSelectNotAddedProducts}
                    disabled={!data?.products.data.length}
                    testID="layout:addNotAddedProducts"
                    iconName="tasks"
                >
                    {t(
                        "backoffice.layout.select_unadded_products",
                        "Select un-added"
                    )}
                </Button>
                <Spacer />
                <Button
                    size="small"
                    onPress={handleAddProducts}
                    disabled={!selectedProducts.length}
                    testID="layout:addSelectedProducts"
                    iconName="plus"
                >
                    {t(
                        "backoffice.layout.add_multiple_products",
                        "Add selected to layout"
                    )}
                </Button>
                <View style={theme.styles.flex} />
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Divider />
            <Spacer space={2} />
            <View>{searchComponent}</View>
            <Spacer space={1} />
            <View style={styles.rowContainer}>
                <Text
                    style={styles.topListMessageText}
                    testID="layout:text:selectedProducts"
                >
                    {t(
                        "backoffice.layout.selected_product",
                        "{{ count }} selected product",
                        { count: selectedProducts.length }
                    )}
                </Text>
                {!!data && data.products.pagination.pages > 1 ? (
                    <Text style={styles.topListMessageText}>
                        {t(
                            "backoffice.layout.showing_first_elements_of_list",
                            "Showing the first {{ count }} results",
                            {
                                count: pageSize,
                            }
                        )}
                    </Text>
                ) : null}
            </View>
            <DataTable style={styles.dataTableContainer}>
                <ProductTableHeader
                    sortByColumn={sortBy}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                    showSelectAll={!!data && data.products.data.length > 0}
                    onSelectAll={handleSelectAll}
                    allSelected={allRowsSelected}
                />
                <FlatList
                    data={data?.products.data}
                    windowSize={5}
                    initialNumToRender={25}
                    maxToRenderPerBatch={25}
                    updateCellsBatchingPeriod={200}
                    renderItem={renderItem}
                    keyExtractor={item => item.id}
                    ListEmptyComponent={
                        loading ? (
                            <View>
                                <Spacer />
                                <Loading
                                    message={t(
                                        "backoffice.layout.loading_products",
                                        "Loading up to {{ count }} products...",
                                        { count: pageSize }
                                    )}
                                />
                            </View>
                        ) : (
                            emptyComponent
                        )
                    }
                    ListFooterComponent={footerComponent}
                />
            </DataTable>
            <Spacer space={2} />
            <Text style={styles.alreadyAddedNotice}>
                {t(
                    "backoffice.layout.notice_already_added",
                    "Notice: Products in a row with a darker background has already been added to this layout."
                )}
            </Text>
        </>
    );
}

function ListMessage({ message }: { message: string }) {
    const styles = useThemedStyle(styleFunc);
    return (
        <View style={styles.notice}>
            <Text>{message}</Text>
        </View>
    );
}

function EmptyList() {
    const [t] = useTranslation();

    return (
        <ListMessage
            message={t(
                "backoffice.layout.product_not_found",
                "No product was found, that matches the search."
            )}
        />
    );
}

function ListFooter({ count }: { count?: number }) {
    const [t] = useTranslation();

    return (
        <ListMessage
            message={t(
                "backoffice.layout.incomplete_product_list",
                "Showing {{ count }} products. Did you not find, what you were looking for in this incomplete list? Try searching in the text field.",
                { count }
            )}
        />
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    dataTableContainer: {
        flex: 1,
    },
    topListMessageText: {
        fontSize: 14,
    },
    notice: {
        margin: theme.spacingScale,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: theme.spacingScale,
        paddingVertical: theme.spacingScale / 2,

        borderRadius: theme.borderRadiusSmall,
        backgroundColor: theme.colors.toastDefault,
    },
    alreadyAddedNotice: {
        fontSize: 14,
    },
});

function mapToLayoutProducts(
    selectedProducts: GQProductsQuery["products"]["data"][0]["id"][],
    availableProducts: GQProductsQuery["products"]["data"]
): ILayoutProduct[] {
    if (selectedProducts.length === 0) {
        return [];
    }

    return selectedProducts.map((productId: string): ILayoutProduct => {
        let product = availableProducts.find(itr => itr.id === productId);

        if (product) {
            return {
                ...product,
                buttonText: product.buttonText ?? "",
            };
        }

        return {
            id: productId,
            amount: 0,
            buttonText: "UNKNOWN",
            costAmount: 0,
            minimumAmount: 0,
            name: "UNKNOWN",
        };
    });
}
