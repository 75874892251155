import {
    Button,
    InputControl,
    Picker,
    RequiredText,
    TextInput,
} from "@venuepos/react-common";
import { GQTagInput, useTagFormQuery } from "graphql-sdk";
import { tagTypes } from "lib";
import React, { useMemo } from "react";
import { useTranslation } from "locales";

import type { Form as FormComponent } from "@venuepos/react-common";
import { View } from "react-native";

export function ModalFriendlyForm(props: {
    form: FormComponent<GQTagInput>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();

    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const { data } = useTagFormQuery();

    // order the available tag types by name
    // i18next t("tag.type.barcode", "Card (barcode)");
    // i18next t("tag.type.wristband_nfc", "Wristband (NFC)");
    // i18next t("tag.type.card_nfc", "Card (NFC)");
    const orderedTagTypes = useMemo(
        () =>
            tagTypes
                .map(type => ({
                    value: type.value,
                    label: t(type.label, type.label),
                }))
                .sort((type1, type2) => type1.label.localeCompare(type2.label)),
        [t]
    );

    if (!values || !data) {
        return null;
    }

    return (
        <View>
            <InputControl error={errors.identifier}>
                <TextInput
                    label={t("common.identifier", "Identifier")}
                    placeholder={t(
                        "backoffice.tag_form.enter_identifier",
                        "Enter identifier (barcode, NFC id, etc.)"
                    )}
                    defaultValue={values.identifier}
                    onChangeText={text => setValue("identifier", text)}
                    testID="identifier"
                    required={true}
                />
            </InputControl>

            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name || ""}
                    onChangeText={text =>
                        setValue("name", text !== "" ? text : null)
                    }
                    testID="name"
                />
            </InputControl>

            <InputControl error={errors.tagType}>
                <Picker
                    label={t("common.type", "Type")}
                    onValueChange={value => {
                        setValue("tagType", value);
                    }}
                    selectedValue={values.tagType || ""}
                    testID="type"
                    required={true}
                >
                    <Picker.Item
                        label={t(
                            "backoffice.tag_form.choose_tag_type",
                            "Choose a type"
                        )}
                        value=""
                    />

                    {orderedTagTypes.map(tagType => (
                        <Picker.Item
                            key={tagType.value}
                            value={tagType.value}
                            label={t(tagType.label, tagType.label)}
                            testID={"type:" + tagType.value}
                        />
                    ))}
                </Picker>
            </InputControl>

            <InputControl error={errors.groupId}>
                <Picker
                    label={t("common.group", "Group")}
                    onValueChange={value => {
                        setValue("groupId", value);
                    }}
                    selectedValue={values.groupId || ""}
                    testID="tag:tagGroup"
                >
                    <Picker.Item
                        label={t(
                            "backoffice.tag_form.choose_tag_group",
                            "Choose a tag group"
                        )}
                        value=""
                    />
                    {data.tagGroups.data.map(tagGroup => {
                        if (!tagGroup) {
                            return null;
                        }
                        return (
                            <Picker.Item
                                key={tagGroup.id}
                                value={tagGroup.id}
                                label={tagGroup.name}
                                testID={"group:" + tagGroup.name}
                            />
                        );
                    })}
                </Picker>
            </InputControl>

            <Button onPress={handleSubmit(props.onSubmit)} testID="save">
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </View>
    );
}
