import {
    IconButton,
    StyleFunction,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import type { LayoutTableButton } from "lib";
import React from "react";
import { View } from "react-native";

export function VisualLayoutButton({
    label,
    onDelete,
    onPress,
}: {
    label: LayoutTableButton["label"];
    buttonId: LayoutTableButton["id"];
    onDelete: () => void;
    onPress: () => void;
}) {
    const styles = useThemedStyle(styleFunc);

    return (
        <View style={styles.container}>
            <View style={styles.helperContainer}>
                <View style={styles.helperContainerLeft}>
                    <IconButton
                        name="settings"
                        size="small"
                        onPress={onPress}
                        testID="settings"
                    />
                </View>
                <View style={styles.helperContainerRight}>
                    <IconButton
                        name="delete"
                        size="small"
                        onPress={onDelete}
                        testID="delete"
                    />
                </View>
            </View>
            <View style={styles.contentContainer}>
                <View>
                    <Text numberOfLines={1}>{label}</Text>
                </View>
            </View>
            <div className="gridHandle" />
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flex: 1,
        height: "100%",
    },
    helperContainer: {
        flexDirection: "row",
        backgroundColor: theme.colors.semiTransparentBlack,
    },
    helperContainerLeft: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    helperContainerRight: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
    },

    contentContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacingScale,
        userSelect: "none",
    },
});
