import React from "react";
import { useTheme, useThemedStyle, StyleFunction } from "../../theme";
import { contrastToBlack } from "../../utils";
import type { IToast } from ".";
import { useMemo } from "react";
import { View, Animated, TextStyle } from "react-native";
import { Icon } from "../icon";
import { Text } from "../text";
import { IconButton } from "../icon-button";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

const TOAST_WIDTH = 400;

export function ToastItem({
    item,
    onRemove,
}: {
    item: IToast;
    onRemove?: () => void;
}) {
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const bgColor = useToastColor(item.type);
    const textColor: TextStyle = useMemo(
        () => ({
            color: contrastToBlack(bgColor)
                ? theme.colors.textLight
                : theme.colors.textDark,
        }),
        [bgColor, theme.colors.textDark, theme.colors.textLight]
    );

    const windowTransition = item.animated.interpolate({
        inputRange: [0, 1],
        outputRange: [+TOAST_WIDTH, 0],
    });

    return (
        <Animated.View // insert custom renderer here
            pointerEvents="auto"
            style={[
                styles.toastItem,
                {
                    backgroundColor: bgColor,
                    transform: [
                        {
                            translateX: windowTransition,
                        },
                    ],
                },
            ]}
        >
            <View style={styles.row}>
                <Icon
                    name={item.iconName}
                    color={item.iconColor}
                    style={styles.icon}
                />

                <View style={styles.toastMsg} testID={"toast:" + item.message}>
                    {item.title ? (
                        <Text style={[styles.title, textColor]}>
                            {item.title}
                        </Text>
                    ) : null}
                    <TouchableWithoutFeedback onPress={onRemove}>
                        <Text
                            style={textColor}
                            numberOfLines={4}
                            ellipsizeMode="tail"
                            android_hyphenationFrequency="full"
                        >
                            {item.message}
                        </Text>
                    </TouchableWithoutFeedback>
                </View>
            </View>

            {item.showClose ? (
                <IconButton
                    size="small"
                    name="close"
                    onPress={onRemove}
                    style={[styles.closeIcon, textColor]}
                />
            ) : null}
        </Animated.View>
    );
}

function useToastColor(type: IToast["type"]) {
    const theme = useTheme();
    switch (type) {
        case "error":
            return theme.colors.error;
        case "warning":
            return theme.colors.warning;
        case "success":
            return theme.colors.success;
        default:
            return theme.colors.toastDefault;
    }
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    row: {
        ...theme.styles.row,
    },

    toastItem: {
        ...theme.styles.flex1,
        ...theme.styles.row,
        justifyContent: "space-between",

        borderColor: theme.colors.textLight,
        borderRadius: theme.borderRadiusSmall,
        padding: theme.spacingScale,

        elevation: 5,
        shadowColor: theme.colors.black,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    },
    icon: {
        marginTop: 2, // Adjust the position of the icon to better match the text lines.
        marginRight: theme.spacingScale,
    },
    closeIcon: {
        marginLeft: theme.spacingScale,
        paddingRight: 0,
    },
    title: {
        ...theme.fonts.medium,
    },

    toastMsg: {
        elevation: 5,
        maxWidth:
            dimensions.width <= theme.breakpoint.portraitWidth
                ? dimensions.width - 130
                : TOAST_WIDTH - theme.spacingScale * 4,
    },
});
