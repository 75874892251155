import { useApolloClient } from "@apollo/client";
import { useFocusEffect } from "@react-navigation/native";
import {
    Alert,
    Button,
    DataTable,
    Headline,
    Loading,
    LoadingScreen,
    SortDirection,
    Spacer,
    Surface,
    useAuth,
    useConfirm,
    useMerchantConfig,
    useModal,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
    useToast,
} from "@venuepos/react-common";
import {
    GQTagsWithoutCustomerQuery,
    TagsWithoutCustomerDocument,
    useAccountAssignTagsMutation,
    useAccountCloseMutation,
    useAccountOpenMutation,
    useAccountQuery,
    useAccountReissueGiftcardMutation,
    useAccountSaveMutation,
} from "graphql-sdk";
import { useTranslation } from "locales";
import React, { useCallback } from "react";
import { View } from "react-native";
import { Divider } from "react-native-paper";

import { AdminContainer } from "../../..";
import { useHandleMutationError, useSearchDefinition } from "../../../../hooks";
import { RootStackScreenProps } from "../../../../navigation";
import { useAdminSession } from "../../../../session";
import { useAssignTagsModal } from "../../../tags/assign-tags-modal/assign-tags-modal";
import { TransactionTableHeader } from "../view/transaction-table-header";
import { AccountInfo } from "./account-info";
import { TransactionTableRow } from "./transaction-table-row";
import { UpdateAmountModal } from "./update-amount-modal";

import type { StyleFunction } from "@venuepos/react-common";
import type { GQAccountQuery } from "graphql-sdk";
import { formatAmount, ITag, SearchDefinition } from "lib";
import type { AvailableLocale } from "locales";
type ScreenProps = RootStackScreenProps<"ACCOUNT_VIEW">;

export function AccountViewScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();
    const toast = useToast();
    const confirm = useConfirm();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "transactionAt",
        initialSortDirection: "DESC",
    });
    const [accountEdit] = useAccountSaveMutation();
    const { handleMutationError } = useHandleMutationError();
    const [reissueGiftcard] = useAccountReissueGiftcardMutation();

    const defaultTransactionSearch: SearchDefinition = {
        amount: createSearchDefinition("amount"),
        createdAt: createSearchDefinition("createdAt"),
        user: createSearchDefinition("user"),
        tag: {
            name: t("searching.tag", "Tag"),
            type: "string",
            value: "",
            enabled: false,
        },
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultTransactionSearch, {
        buttonSize: "small",
        hideCloseButton: true,
        visible: true,
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const { render } = useModal();
    const accountId = route.params.id;

    const { data, error, loading, refetch } = useAccountQuery({
        variables: {
            id: accountId,
            transactionPagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
            tagPagination: {
                sort: "name",
                sortDirection: "ASC",
            },
        },
        fetchPolicy: "no-cache",
    });

    const reissuePress = useCallback(async () => {
        if (
            !(await confirm(
                t(
                    "backoffice.gift_card.reissue_heading",
                    "Do you wish to reissue this gift card?"
                ),
                t(
                    "backoffice.gift_card.reissue_warning",
                    "The gift card will be closed and the related tag will be removed."
                )
            ))
        ) {
            return;
        }
        const result = await reissueGiftcard({ variables: { id: accountId } });

        if (
            result &&
            result.data &&
            result.data.accountReissueGiftcard.status
        ) {
            toast.success(
                t(
                    "backoffice.gift_card.reissued",
                    "The giftcard is closed, and the related tag was removed so it can be reused with another giftcard."
                )
            );

            refetch();
        } else {
            toast.warning(
                t(
                    "backoffice.gift_card.reissue_failed",
                    "Reissuing the giftcard failed."
                )
            );
        }
    }, [accountId, confirm, refetch, reissueGiftcard, t, toast]);

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    const [closeAccount] = useAccountCloseMutation();
    const [openAccount] = useAccountOpenMutation();

    const handleClose = useCallback(
        async (id: string) => {
            const result = await closeAccount({ variables: { id } });
            if (result && result.data && result.data.accountClose.status) {
                toast.success(t("backoffice.account.closed", "Account closed"));
                await refetch();
            } else {
                toast.warning(
                    t(
                        "backoffice.account.close_failed",
                        "Closing account failed."
                    )
                );
            }
        },
        [closeAccount, refetch, t, toast]
    );

    const handleOpen = useCallback(
        async (id: string) => {
            const result = await openAccount({ variables: { id } });
            if (result && result.data && result.data.accountOpen.status) {
                toast.success(t("backoffice.account.opened", "Account opened"));
                await refetch();
            } else {
                toast.warning(
                    t(
                        "backoffice.account.open_failed",
                        "Opening of account failed."
                    )
                );
            }
        },
        [openAccount, refetch, t, toast]
    );

    const handleSaveAmount = useCallback(
        async (account: GQAccountQuery["account"] | null) => {
            if (account === null) {
                return;
            }

            await accountEdit({
                variables: {
                    id: account.id,
                    account: {
                        name: account.name,
                        type: account.type,
                        status: account.status,
                        amount: account.amount,
                    },
                },
            });

            toast.success(
                t("backoffice.account.account_updated", "Balance updated.")
            );
            await refetch();
        },
        [accountEdit, refetch, t, toast]
    );

    const handleUpdateAmount = useCallback(
        async (account: GQAccountQuery["account"]) => {
            await render(onClose => (
                <UpdateAmountModal
                    item={account}
                    onClose={onClose}
                    onSave={handleSaveAmount}
                    minAmount={account.type === "GIFT_CARD" ? 0 : undefined}
                />
            ));
        },
        [render, handleSaveAmount]
    );

    const handleShowInvoice = useCallback(
        (invoiceId: string) => navigate("INVOICE", { id: invoiceId }),
        [navigate]
    );

    const [setAccountAssignTags] = useAccountAssignTagsMutation();
    const graphqlClient = useApolloClient();
    const assignTagsModal = useAssignTagsModal();

    const handleTagsFindTabQuery = useCallback(
        async (variables: {
            pagination: {
                page: number;
                pageSize: number;
                sort: string | undefined;
                sortDirection: SortDirection;
            };
            search: {
                query: string;
            };
        }) => {
            const tagsWithoutCustomer =
                await graphqlClient.query<GQTagsWithoutCustomerQuery>({
                    query: TagsWithoutCustomerDocument,
                    variables,
                    fetchPolicy: "no-cache",
                });

            if (!tagsWithoutCustomer.data) {
                throw new Error("Failed to fetch tags data");
            }

            return {
                data: tagsWithoutCustomer.data.tagsWithoutCustomer.data,
                loading: tagsWithoutCustomer.loading,
                pagination:
                    tagsWithoutCustomer.data.tagsWithoutCustomer.pagination,
            };
        },
        [graphqlClient]
    );

    const handleAssignTags = useCallback(async () => {
        if (loading || error || !data) {
            return;
        }

        let enabledTagIds: ITag["id"][] = [];
        if (data.account.tags.data) {
            enabledTagIds = data.account.tags.data.map(tagItr => tagItr.id);
        }

        const assignedTags = await assignTagsModal({
            enabledTags: enabledTagIds,
            tagsFindQuery: handleTagsFindTabQuery,
        });

        if (assignedTags === undefined) {
            // No update
            return;
        }

        await handleMutationError(
            async () =>
                await setAccountAssignTags({
                    variables: { id: accountId, tagIds: assignedTags },
                }),
            t("common.saved", "Saved"),
            async () => {
                await refetch();
            }
        );
    }, [
        accountId,
        assignTagsModal,
        data,
        error,
        handleMutationError,
        handleTagsFindTabQuery,
        loading,
        refetch,
        setAccountAssignTags,
        t,
    ]);

    if (loading || !data) {
        return <LoadingScreen style="light" />;
    }

    const transactions = data.account.transactions;

    return (
        <AdminContainer>
            <Surface>
                <View style={styles.headlineContainer}>
                    <Headline numberOfLines={2}>{data.account.name}</Headline>
                    <View style={styles.buttonContainer}>
                        {data.account.type === "GIFT_CARD" ? (
                            <Button
                                style={styles.button}
                                variant="outline"
                                size="small"
                                disabled={data.account.status === "CLOSED"}
                                onPress={reissuePress}
                            >
                                {t("backoffice.gift_card.reissue", "Reissue")}
                            </Button>
                        ) : null}
                        <Button
                            style={styles.button}
                            variant="outline"
                            size="small"
                            disabled={data.account.status === "CLOSED"}
                            onPress={async () => {
                                await handleUpdateAmount(data!.account);
                            }}
                        >
                            {t(
                                "backoffice.account.update_accounts",
                                "Update amount"
                            )}
                        </Button>
                        {data.account.status === "OPEN" ? (
                            <Button
                                style={styles.button}
                                variant="outline"
                                size="small"
                                onPress={async () => {
                                    await handleClose(data!.account.id);
                                }}
                            >
                                {t(
                                    "backoffice.account.close_accounts",
                                    "Close account"
                                )}
                            </Button>
                        ) : null}

                        {data.account.status === "CLOSED" ? (
                            <Button
                                style={styles.button}
                                variant="outline"
                                size="small"
                                onPress={async () => {
                                    await handleOpen(data!.account.id);
                                }}
                            >
                                {t(
                                    "backoffice.account.open_account",
                                    "Open account"
                                )}
                            </Button>
                        ) : null}

                        <Button
                            style={styles.button}
                            variant="outline"
                            size="small"
                            onPress={handleAssignTags}
                            testID="account:assignTags"
                            iconName="tags"
                        >
                            {t(
                                "backoffice.customer.assign_tags.button",
                                "Assign tags"
                            )}
                        </Button>

                        <Button
                            style={styles.button}
                            type="secondary"
                            size="small"
                            onPress={() => {
                                navigate("ACCOUNT_EDIT", {
                                    id: accountId,
                                });
                            }}
                            testID="account:edit"
                            iconName="edit"
                        >
                            {t("common.edit", "Edit")}
                        </Button>
                    </View>
                </View>

                <AccountInfo
                    name={data.account.name}
                    customer={data.account.customer}
                    tags={data.account.tags.data}
                    amount={formatAmount(data.account.amount, currency, {
                        printCurrency: true,
                        locale: locale as AvailableLocale,
                    })}
                    accountStatus={t(
                        `backoffice.account.status.${data.account.status.toLowerCase()}`,
                        "Placeholder: status"
                    )}
                    onAssignTags={handleAssignTags}
                />
            </Surface>
            <Spacer space={2} />
            <Surface>
                <View style={[theme.styles.row, styles.container]}>
                    <Headline size="h5">
                        {t(
                            "backoffice.account.transactions.headline",
                            "Transactions"
                        )}
                    </Headline>
                    <Spacer space={1} />
                    {searchIndicator}
                </View>
                <Divider />
                <Spacer space={2} />
                {searchComponent}
                <DataTable>
                    <TransactionTableHeader
                        onSortChange={onSortChange}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                    />
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        transactions?.data.map((item, index) => (
                            <TransactionTableRow
                                key={item.id}
                                item={item}
                                locale={locale as AvailableLocale}
                                currency={currency}
                                onShowInvoice={handleShowInvoice}
                                style={index % 2 ? styles.oddRow : undefined}
                            />
                        ))
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={transactions?.pagination.pages}
                        itemCount={transactions.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    container: {
        justifyContent: "space-between",
    },
    button: {
        marginLeft: theme.spacingScale,
        marginBottom: theme.spacingScale,
    },
    headlineContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },

    buttonContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        maxWidth:
            dimensions.width < theme.breakpoint.largeTabletWidth
                ? theme.dimensions.maxFormWidth
                : "100%",
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
