import {
    Alert,
    Button,
    CheckBox,
    ColorIndicator,
    ColorPickerInput,
    Form,
    IconButton,
    InputControl,
    InputLabel,
    Loading,
    NumberInput,
    Picker,
    Spacer,
    StyleFunction,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQVenueAccessAccessType,
    GQVenueAccessEvent,
    useProductGroupsQuery,
    useVenueAccessTicketParametersQuery,
} from "graphql-sdk";
import { Divider } from "react-native-paper";
import { produce } from "immer";
import { parseAmount, VenueAccessTicketButtonType } from "lib";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

export function VenueAccessTicketsSubForm({
    form,
}: {
    form: Form<VenueAccessTicketButtonType>;
}) {
    const [{ errors, values }, { setValue }] = form;
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const merchantConfig = useMerchantConfig();
    const [activateColorPicker, setActivateColorPicker] =
        useState<boolean>(false);
    const [color, setColor] = useState<string | null>(null);

    // Fetch product groups
    const productGroupsQuery = useProductGroupsQuery({
        variables: {
            pagination: {
                pageSize: 999999,
            },
        },
        fetchPolicy: "no-cache",
    });
    const productGroups = productGroupsQuery.data?.productGroups.data;

    // Fetch events and ticket types from Venue Access
    const { data, error: ticketError } = useVenueAccessTicketParametersQuery({
        variables: {
            eventId: values?.venueAccessEvent,
        },
    });

    const accessEvents = data?.venueAccessTicketParameters.events;
    const accessTypes = data?.venueAccessTicketParameters.accessTypes;

    const handleGroupChange = useCallback(
        selectedProductGroup => {
            if (!productGroups?.length) {
                return;
            }

            const groupData = productGroups.find(
                groupItr => groupItr.id === selectedProductGroup
            );

            if (!groupData) {
                return;
            }

            setColor(groupData.color);
            setValue("productGroupId", selectedProductGroup);
        },
        [productGroups, setValue]
    );

    // Select the first event, if none is selected
    useEffect(() => {
        if (
            !values?.venueAccessEvent &&
            accessEvents &&
            accessEvents.length > 0
        ) {
            setValue("venueAccessEvent", accessEvents[0].id);
        }
    }, [accessEvents, setValue, values]);

    // Select the first ticket type for the selected event, if no ticket type is selected
    useEffect(() => {
        if (
            accessTypes &&
            accessTypes.length > 0 &&
            values?.venueAccessTypes === undefined
        ) {
            if (accessTypes[0]) {
                setValue("venueAccessTypes", [accessTypes[0].id]);
            } else {
                setValue("venueAccessTypes", []);
            }
        }
    }, [accessTypes, setValue, values]);

    useEffect(() => {
        if (values?.amount === undefined) {
            setValue("amount", 0);
        }
    }, [setValue, values]);

    // // Select the first product group if none is selected
    // useEffect(() => {
    //     if (
    //         !values?.productGroupId &&
    //         productGroups &&
    //         productGroups.length > 0
    //     ) {
    //         handleGroupChange(productGroups[0].id);
    //     }
    // }, [handleGroupChange, productGroups, setValue, values]);

    // Select the "today" option in the validFor dropdown, if nothing is selected
    useEffect(() => {
        if (!values?.validFor) {
            setValue("validFor", "today");
        }
    }, [setValue, values]);

    return (
        <View style={styles.onTop}>
            {ticketError ? (
                <>
                    <Alert type="error">
                        {t(
                            "backoffice.error.from_server",
                            "There was an error: {{errorText}}",
                            {
                                errorText: ticketError.message,
                            }
                        )}
                    </Alert>
                    <Spacer space={2} />
                    <Divider />
                </>
            ) : (
                <>
                    <View style={styles.rowContainer}>
                        <InputControl style={styles.firstColumn}>
                            <InputLabel>
                                {t(
                                    "layout.function_button_form.venue_access_event"
                                )}
                            </InputLabel>
                            {!accessEvents ? (
                                <Loading />
                            ) : (
                                <AccessEventControl
                                    form={form as typeof form}
                                    accessEvents={accessEvents}
                                />
                            )}
                        </InputControl>
                        <Spacer space={2} />
                        <InputControl style={styles.lastColumn}>
                            <InputLabel>
                                {t(
                                    "layout.function_button_form.venue_access_type"
                                )}
                            </InputLabel>
                            {!accessTypes ? (
                                <Loading />
                            ) : (
                                <AccessTypesControl
                                    form={form}
                                    accessTypes={accessTypes}
                                />
                            )}
                        </InputControl>
                    </View>

                    <View style={styles.rowContainer}>
                        <InputControl
                            error={errors.amount}
                            style={styles.firstColumn}
                        >
                            <NumberInput
                                min={0}
                                label={t(
                                    "layout.function_button_form.ticket_price"
                                )}
                                onChange={value => {
                                    setValue(
                                        "amount",
                                        value !== null ? parseAmount(value) : 0
                                    );
                                }}
                                defaultValue={
                                    values?.amount ? values.amount / 100 : 0
                                }
                                unit={merchantConfig.currency}
                            />
                        </InputControl>
                        <Spacer space={2} />
                        <InputControl
                            error={errors.validFor}
                            style={styles.lastColumn}
                        >
                            <Picker
                                label={t(
                                    "layout.function_button_form.valid_for",
                                    "Valid for"
                                )}
                                onValueChange={value =>
                                    setValue("validFor", value)
                                }
                                selectedValue={values?.validFor ?? undefined}
                            >
                                <Picker.Item
                                    value="today"
                                    label={t(
                                        "layout.function_button_form.valid_for_options.today",
                                        "Same day"
                                    )}
                                    key="today"
                                />
                                <Picker.Item
                                    value="2_days"
                                    label={t(
                                        "layout.function_button_form.valid_for_options.2_days",
                                        "2 days"
                                    )}
                                    key="2_days"
                                />
                                <Picker.Item
                                    value="3_days"
                                    label={t(
                                        "layout.function_button_form.valid_for_options.3_days",
                                        "3 days"
                                    )}
                                    key="3_days"
                                />
                                <Picker.Item
                                    value="4_days"
                                    label={t(
                                        "layout.function_button_form.valid_for_options.4_days",
                                        "4 days"
                                    )}
                                    key="4_days"
                                />
                                <Picker.Item
                                    value="5_days"
                                    label={t(
                                        "layout.function_button_form.valid_for_options.5_days",
                                        "5 days"
                                    )}
                                    key="5_days"
                                />
                                <Picker.Item
                                    value="6_days"
                                    label={t(
                                        "layout.function_button_form.valid_for_options.6_days",
                                        "6 days"
                                    )}
                                    key="6_days"
                                />
                                <Picker.Item
                                    value="7_days"
                                    label={t(
                                        "layout.function_button_form.valid_for_options.7_days",
                                        "7 days"
                                    )}
                                    key="7_days"
                                />
                                <Picker.Item
                                    value="3_years"
                                    label={t(
                                        "layout.function_button_form.valid_for_options.3_years",
                                        "3 years"
                                    )}
                                    key="3_years"
                                />
                            </Picker>
                        </InputControl>
                    </View>

                    <InputControl error={errors.productGroupId}>
                        {!productGroups?.length ? (
                            <Loading
                                message={t(
                                    "backoffice.layout.checking_product_groups",
                                    "Checking for product groups. Are there any?"
                                )}
                                size="small"
                            />
                        ) : (
                            <Picker
                                required
                                label={t(
                                    "layout.function_button_form.product_group",
                                    "Product group"
                                )}
                                selectedValue={
                                    values?.productGroupId ?? undefined
                                }
                                onValueChange={handleGroupChange}
                            >
                                <Picker.Item
                                    label={t(
                                        "layout.function_button_form.choose_product_group",
                                        "Choose a product group"
                                    )}
                                />
                                {productGroups.map(productGroup => (
                                    <Picker.Item
                                        key={productGroup.id}
                                        value={productGroup.id}
                                        label={productGroup.name}
                                    />
                                ))}
                            </Picker>
                        )}
                    </InputControl>

                    <View style={styles.rowContainer}>
                        <InputControl
                            description={t(
                                "layout.function_button_form.override_product_group_color",
                                "The button gets the colour of the product group, unless you select a different color here."
                            )}
                            style={styles.firstColumn}
                        >
                            <View
                                style={[styles.rowContainer, styles.colorRow]}
                            >
                                <InputLabel>
                                    {`${t(
                                        "layout.button.product_group_color",
                                        "Product group color"
                                    )}:`}
                                </InputLabel>
                                <View style={styles.groupColor}>
                                    <ColorIndicator
                                        color={color ?? undefined}
                                        copyOnClick={true}
                                    />
                                </View>
                            </View>
                            <CheckBox
                                value={activateColorPicker}
                                onValueChange={checkboxValue => {
                                    if (!checkboxValue) {
                                        setValue("color", null);
                                    } else {
                                        setValue("color", "#73B22E");
                                    }
                                    setActivateColorPicker(checkboxValue);
                                }}
                                label={t(
                                    "layout.button.pick_color_question",
                                    "Pick a custom color?"
                                )}
                            />
                        </InputControl>
                        <Spacer space={2} />
                        {activateColorPicker ? (
                            <InputControl
                                error={errors.color}
                                style={styles.lastColumn}
                            >
                                <ColorPickerInput
                                    defaultColor={values?.color ?? undefined}
                                    label={t(
                                        "layout.button.color",
                                        "Custom button color"
                                    )}
                                    onChangeColor={newColor => {
                                        if (newColor !== values?.color) {
                                            setValue("color", newColor ?? null);
                                        }
                                    }}
                                />
                            </InputControl>
                        ) : (
                            <View style={styles.rowContent} />
                        )}
                    </View>
                </>
            )}
        </View>
    );
}

function AccessEventControl({
    form: [{ values }, { setValues }],
    accessEvents,
}: {
    form: Form<VenueAccessTicketButtonType>;
    accessEvents: Pick<GQVenueAccessEvent, "id" | "name" | "isActive">[];
}) {
    const [t] = useTranslation();

    if (!values?.venueAccessEvent) {
        return null;
    }

    return (
        <Picker
            selectedValue={String(values.venueAccessEvent)}
            onValueChange={value => {
                setValues({
                    venueAccessEvent: Number(value),
                    venueAccessTypes: undefined,
                });
            }}
            disabled={accessEvents.length === 0}
        >
            {accessEvents.map(accessEvent => (
                <Picker.Item
                    key={accessEvent.id}
                    label={accessEvent.name}
                    value={String(accessEvent.id)}
                    disabled={!accessEvent.isActive}
                />
            ))}
            {accessEvents && accessEvents.length === 0 && (
                <Picker.Item
                    key=""
                    label={t(
                        "layout.function_button_form.no_events",
                        "No events"
                    )}
                />
            )}
        </Picker>
    );
}

function AccessTypesControl({
    form: [{ values }, { setValue }],
    accessTypes,
}: {
    form: Form<VenueAccessTicketButtonType>;
    accessTypes: Pick<GQVenueAccessAccessType, "id" | "name">[];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <>
            {values?.venueAccessTypes?.map((selectedAccessType, index) => (
                <View
                    key={`${index}-${selectedAccessType}`}
                    style={styles.venueAccessTypeItem}
                >
                    <Picker
                        selectedValue={selectedAccessType}
                        onValueChange={value => {
                            if (!values.venueAccessTypes) {
                                return;
                            }

                            setValue(
                                "venueAccessTypes",
                                produce(values.venueAccessTypes, draft => {
                                    draft[index] = Number(value);
                                })
                            );
                        }}
                        disabled={!accessTypes || accessTypes?.length === 0}
                        style={styles.venueAccessTypePicker}
                    >
                        {accessTypes &&
                            accessTypes.length > 0 &&
                            accessTypes.map(accessType => (
                                <Picker.Item
                                    key={accessType.id}
                                    label={accessType.name}
                                    value={String(accessType.id)}
                                />
                            ))}
                    </Picker>
                    <IconButton
                        name="close"
                        disabled={values.venueAccessTypes!.length <= 1}
                        onPress={() => {
                            if (!values.venueAccessTypes) {
                                return;
                            }

                            setValue(
                                "venueAccessTypes",
                                produce(values.venueAccessTypes, draft => {
                                    draft.splice(index, 1);
                                })
                            );
                        }}
                        style={[
                            styles.removeIcon,
                            values.venueAccessTypes!.length <= 1 &&
                                styles.removeIconDisabled,
                        ]}
                    />
                </View>
            ))}
            {(accessTypes?.length || 0) > 0 && (
                <View style={styles.addVenueAccessTypeButtonContainer}>
                    <Button
                        onPress={() => {
                            setValue(
                                "venueAccessTypes",
                                values?.venueAccessTypes!.concat([
                                    accessTypes![0].id,
                                ])
                            );
                        }}
                        variant="text"
                        size="small"
                        iconName="plus"
                    >
                        {t("common.add", "Add")}
                    </Button>
                </View>
            )}
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    firstColumn: {
        flex: 1,
    },
    lastColumn: {
        flex: 1,
    },
    venueAccessTypeItem: {
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: theme.spacingScale,
    },
    venueAccessTypePicker: {
        marginBottom: 0,
    },
    removeIcon: {
        color: theme.colors.textDark,
    },
    addVenueAccessTypeButtonContainer: {
        alignItems: "flex-start",
    },
    rowContent: {
        flex: 1,
    },
    rowContainer: {
        flexDirection: "row",
    },
    onTop: { zIndex: 2 },
    colorRow: {
        alignItems: "baseline",
        marginBottom: 8,
    },
    groupColor: {
        marginLeft: theme.spacingScale / 2,
    },
    selectedType: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: theme.spacingScale / 2,
        borderBottomWidth: 1,
        borderColor: theme.colors.grey100,
    },
    lastRow: {
        borderBottomWidth: 0,
        marginBottom: theme.spacingScale / 2,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
    addButton: { alignSelf: "flex-start", marginLeft: 5 },
});
