import produce from "immer";
import { LayoutSection } from "lib";

/**
 * Removes the 'renaming' property from the layout data.
 */
export const rinseLayoutData = (
    section: LayoutSection & Partial<{ renaming: boolean }>
) =>
    produce(section, draft => {
        delete draft.renaming;
    });
