import { Loading } from "@venuepos/react-common";
import React, { useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import { useLayoutButtons } from "./buttons-context";
import { GridEditor } from "./edit/grid-editor";

export const ITEM_MARGIN_HORISONTAL = 10;
export const ITEM_MARGIN_VERTICAL = 10;
export const ITEM_MARGIN: [number, number] = [
    ITEM_MARGIN_HORISONTAL,
    ITEM_MARGIN_VERTICAL,
];

export const CONTAINER_PADDING_HORISONTAL = 10;
export const CONTAINER_PADDING_VERTICAL = 10;
export const CONTAINER_PADDING: [number, number] = [
    CONTAINER_PADDING_HORISONTAL,
    CONTAINER_PADDING_VERTICAL,
];

export function LayoutGridSection() {
    const layoutData = useLayoutButtons();

    const [gridWidth, setGridWidth] = useState<number>(200);
    const [columnWidth, setColumnWidth] = useState<number>(200);

    /**
     *
     * The height of the grid =
     *              no. rows * column width +
     *              no. of spaces between rows * margin +
     *              container padding * 2 (top and bottom)
     *
     * The column width is used for both height and width to make the cells squared.
     */
    const gridHeight = useMemo(() => {
        if (!layoutData) {
            return 200;
        }

        const returnVal =
            columnWidth * layoutData.dimensions.rows +
            (layoutData.dimensions.rows - 1) * ITEM_MARGIN_VERTICAL +
            CONTAINER_PADDING_VERTICAL * 2;

        return returnVal;
    }, [columnWidth, layoutData]);

    const handleOnLayout = useCallback(
        ({
            nativeEvent: {
                layout: { width },
            },
        }) => {
            if (!layoutData) {
                return;
            }

            let colWidth = Math.min(50, width / layoutData.dimensions.columns);

            setGridWidth(colWidth * layoutData.dimensions.columns);
            setColumnWidth(colWidth);
        },
        [layoutData]
    );

    if (!layoutData) {
        return <Loading />;
    }

    return (
        <View
            onLayout={handleOnLayout}
            key={`section_${
                layoutData?.sections[layoutData.currentSectionIndex].id
            }`}
        >
            <GridEditor
                dimensions={layoutData.dimensions}
                gridHeight={gridHeight}
                gridWidth={gridWidth}
            />
        </View>
    );
}
