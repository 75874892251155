import {
    Alert,
    Button,
    CheckBox,
    ConfirmModal,
    DataTable,
    Icon,
    IconButton,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useModal,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    useCashRegisterDeleteMutation,
    useCashRegistersDeleteMutation,
    useCashRegistersQuery,
} from "graphql-sdk";
import { formatDateTime } from "lib";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "locales";
import { FlatList, View } from "react-native";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { SearchDefinition } from "lib";

import type { AvailableLocale } from "locales";
import { useSearchDefinition } from "../../hooks";
type ScreenProps = RootStackScreenProps<"CASH_REGISTERS">;

export function CashRegisterListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce(
        ["merchant.cash_register.write", "merchant.cash_register.delete"],
        "OR"
    );

    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();

    const { render } = useModal();
    const { handleMutationError } = useHandleMutationError();
    const [selectedCashregisters, setSelectedCashregisters] = useState<
        string[]
    >([]);
    const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({ initialSortBy: "name" });

    const defaultCashRegisterSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        department: createSearchDefinition("department"),
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultCashRegisterSearch, {
        visible: true,
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    // GraphQL
    const { data, loading, error, refetch } = useCashRegistersQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });
    const [deleteCashRegister] = useCashRegisterDeleteMutation();
    const [deleteCashRegisters] = useCashRegistersDeleteMutation();

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("CASH_REGISTER_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleMultiSelect = useCallback(
        (selected: boolean, itemIds: string[]) => {
            let clonedSelectedCashregisters = [...selectedCashregisters];
            if (selected) {
                clonedSelectedCashregisters = [
                    ...clonedSelectedCashregisters,
                    ...itemIds,
                ];
            } else {
                clonedSelectedCashregisters =
                    clonedSelectedCashregisters.filter(
                        i => !itemIds.includes(i)
                    );
            }
            setSelectedCashregisters(clonedSelectedCashregisters);
        },
        [selectedCashregisters]
    );

    const handleDelete = useCallback(
        async (id: string) => {
            const deleteResponse = await render(onClose => (
                <ConfirmModal
                    headerText={t(
                        "backoffice.cash_register.delete",
                        "Delete Cash register?"
                    )}
                    bodyText={t(
                        "backoffice.cash_register.delete_explain",
                        "If you choose to delete this cash register, the devices, that use this setup will stop working. Make sure that no device is using this cash register before you continue.\n\nAre you sure, you want to delete this?"
                    )}
                    onClose={onClose}
                />
            ));

            if (!deleteResponse) {
                return;
            }

            await handleMutationError(
                async () => await deleteCashRegister({ variables: { id } }),
                t("backoffice.cash_register.deleted", "Cash register deleted"),
                async () => {
                    await refetch();
                }
            );
        },
        [deleteCashRegister, handleMutationError, refetch, render, t]
    );

    const deleteSelectedCashregisters = useCallback(async () => {
        const deleteResponse = await render(onClose => (
            <ConfirmModal
                headerText={t(
                    "backoffice.cash_registers.delete",
                    "Delete cash registers?"
                )}
                bodyText={t(
                    "backoffice.cash_registers.delete_explain",
                    "If you choose to delete these cash registers, the devices, that use this setup will stop working. Make sure that no device is using this cash registers before you continue.\n\nAre you sure, you want to delete these cash registers?"
                )}
                onClose={onClose}
            />
        ));

        if (!deleteResponse) {
            return;
        }

        const ids = selectedCashregisters.map(productId => productId);

        await handleMutationError(
            async () => await deleteCashRegisters({ variables: { ids } }),
            t("backoffice.cash_registers.deleted", "Cash registers deleted"),
            async () => {
                await refetch();
            }
        );

        setSelectedCashregisters([]);
        setAllRowsSelected(false);
    }, [
        deleteCashRegisters,
        handleMutationError,
        refetch,
        render,
        selectedCashregisters,
        t,
    ]);

    return (
        <AdminContainer testID="cashRegisterScreen">
            <View style={[theme.styles.row, styles.container]}>
                {auth.may("merchant.cash_register.write") ? (
                    <>
                        <Button
                            style={styles.button}
                            variant="invert"
                            disabled={!selectedCashregisters.length}
                            onPress={deleteSelectedCashregisters}
                            testID="deleteProduct"
                        >
                            {t("common.delete", "Delete")}
                        </Button>
                        <Spacer space={1} />
                        <Button
                            onPress={() => {
                                navigate("CASH_REGISTER_CLAIM");
                            }}
                            style={styles.button}
                            testID="claim"
                        >
                            {t("backoffice.cash_register.claim", "Claim")}
                        </Button>
                        <Spacer space={1} />
                        <Button
                            onPress={() => {
                                navigate("CASH_REGISTER_CREATE");
                            }}
                            style={styles.button}
                            testID="create"
                        >
                            {t("common.create", "Create")}
                        </Button>
                    </>
                ) : null}
                <Spacer space={1} />
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title style={styles.iconColumn}>
                            <CheckBox
                                value={allRowsSelected}
                                onValueChange={value => {
                                    const allItemIds =
                                        data!.cashRegisters.data.map(p => p.id);
                                    handleMultiSelect(value, allItemIds);
                                    setAllRowsSelected(value);
                                }}
                            />
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "department"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("department")}
                        >
                            {t("common.department", "Department")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.claimedColumn}>
                            {t("backoffice.cash_register.claimed", "Claimed")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "deviceVersion"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("deviceVersion")}
                        >
                            {t("common.version", "Version")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "deviceLastSeen"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("deviceLastSeen")}
                            style={styles.lastSeenColumn}
                        >
                            {t(
                                "backoffice.cash_register.device_last_seen",
                                "Last seen"
                            )}
                        </DataTable.Title>
                        {auth.may("merchant.cash_register.delete") && (
                            <DataTable.Title numeric style={styles.sortIcon}>
                                <Icon
                                    name="sort"
                                    color={styles.sortIcon.color}
                                />
                            </DataTable.Title>
                        )}
                    </DataTable.Header>
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        <FlatList
                            data={data && data.cashRegisters.data}
                            renderItem={({ item, index }) => (
                                <DataTable.Row
                                    onPress={() => handleEdit(item.id)}
                                    style={
                                        index % 2 === 1
                                            ? styles.oddRow
                                            : undefined
                                    }
                                >
                                    <DataTable.Cell style={styles.iconColumn}>
                                        <CheckBox
                                            value={selectedCashregisters.includes(
                                                item.id
                                            )}
                                            onValueChange={value =>
                                                handleMultiSelect(value, [
                                                    item.id,
                                                ])
                                            }
                                            style={styles.checkbox}
                                            testID={"select:" + item.name}
                                        />
                                    </DataTable.Cell>
                                    <DataTable.Cell>{item.name}</DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.department
                                            ? item.department.name
                                            : null}
                                    </DataTable.Cell>
                                    <DataTable.Cell
                                        style={styles.claimedColumn}
                                    >
                                        {item.device
                                            ? t("common.yes", "Yes")
                                            : ""}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.device
                                            ? item.device.appVersion
                                            : ""}
                                    </DataTable.Cell>
                                    <DataTable.Cell
                                        style={styles.lastSeenColumn}
                                    >
                                        {item.device
                                            ? formatDateTime(
                                                  item.device.lastSeen,
                                                  locale as AvailableLocale
                                              )
                                            : ""}
                                    </DataTable.Cell>

                                    <DataTable.Cell
                                        numeric
                                        style={styles.sortIcon}
                                    >
                                        <IconButton
                                            color={styles.deleteIcon.color}
                                            name="edit"
                                            onPress={() => handleEdit(item.id)}
                                            testID={"edit:" + item.name}
                                        />
                                        {auth.may(
                                            "merchant.cash_register.delete"
                                        ) && (
                                            <IconButton
                                                color={styles.deleteIcon.color}
                                                name="delete"
                                                onPress={() =>
                                                    handleDelete(item.id)
                                                }
                                                testID={"delete:" + item.name}
                                            />
                                        )}
                                    </DataTable.Cell>
                                </DataTable.Row>
                            )}
                            keyExtractor={(_, index) => index.toString()}
                        />
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.cashRegisters.pagination.pages}
                        itemCount={data?.cashRegisters.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 60 },
    sortIcon: {
        color: theme.colors.black,
        flexBasis: 40,
        flexGrow: 0,
        flexShrink: 0,
        justifyContent: "center",
    },
    deleteIcon: {
        color: theme.colors.secondary,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
    claimedColumn: { flexBasis: 100, flexGrow: 0, flexShrink: 0 },
    lastSeenColumn: { flexBasis: 200, flexGrow: 0, flexShrink: 0 },
});
