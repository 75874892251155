import React, { ReactNode, useRef, useState } from "react";
import {
    Animated,
    Platform,
    Pressable,
    StyleProp,
    TextStyle,
    View,
    ViewStyle,
} from "react-native";
import { Icon, IconProps } from "../icon";
import { StyleFunction, useTheme, useThemedStyle } from "../../theme";
import { Text } from "../text";

export function Accordion(props: {
    title?: string | ReactNode;
    showContents?: boolean;
    iconName?: string;
    variant?: "normal" | "outline";
    caret?: boolean;
    style?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    triggerStyle?: StyleProp<ViewStyle>;
    children?: ReactNode;
}) {
    const { showContents: initialShowContents = true } = props;
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const [showContents, setShowContents] =
        useState<boolean>(initialShowContents);

    let displayIcon: IconProps["name"];
    if (showContents) {
        displayIcon = "dropupCircle";
    } else {
        displayIcon = "dropdownCircle";
    }

    // Default to normal
    let containerStyle: StyleProp<ViewStyle> = styles.containerNormal;
    let openContainerStyle: StyleProp<ViewStyle> = styles.containerNormal;
    let triggerStyle: StyleProp<ViewStyle> = styles.triggerNormal;

    switch (props.variant) {
        case "outline":
            containerStyle = styles.containerOutlined;
            openContainerStyle = styles.openContainerOutlined;
            triggerStyle = styles.triggerOutlined;
            break;
    }

    if (props.triggerStyle) {
        triggerStyle = [triggerStyle, props.triggerStyle];
    }

    // Content fade/toggle animation
    const contentFadeAnim = useRef(
        new Animated.Value(showContents ? 1 : 0)
    ).current;
    const toggleExpand = () => {
        setShowContents(!showContents);
        Animated.timing(contentFadeAnim, {
            useNativeDriver: Platform.OS !== "web",
            toValue: showContents ? 0 : 1,
            duration: 80,
        }).start();
    };

    // Switch fade animations
    const switchFadeAnim = new Animated.Value(1);
    const switchFadeIn = () => {
        Animated.timing(switchFadeAnim, {
            toValue: 0.1,
            duration: 150,
            useNativeDriver: Platform.OS !== "web",
        }).start();
    };

    const switchFadeOut = () => {
        Animated.timing(switchFadeAnim, {
            toValue: 1,
            duration: 250,
            useNativeDriver: Platform.OS !== "web",
        }).start();
    };

    return (
        <View style={props.style}>
            <Pressable
                onPress={toggleExpand}
                style={triggerStyle}
                accessibilityState={{ expanded: showContents }}
                role="button"
                aria-labelledby="accordion-title"
                onPressIn={switchFadeIn}
                onPressOut={switchFadeOut}
            >
                <Animated.View
                    style={[styles.titleBox, { opacity: switchFadeAnim }]}
                >
                    {typeof props.title === "string" ? (
                        <>
                            {props.iconName && (
                                <Icon
                                    name={props.iconName}
                                    color={theme.colors.grey500}
                                />
                            )}
                            <Text
                                style={[styles.titleText, props.textStyle]}
                                id="accordion-title"
                            >
                                {props.title}
                            </Text>
                        </>
                    ) : (
                        props.title
                    )}
                    {props.caret !== false && (
                        <Icon
                            style={styles.caret}
                            name={displayIcon}
                            color={theme.colors.grey500}
                        />
                    )}
                </Animated.View>
            </Pressable>

            <Animated.View
                style={[
                    containerStyle,
                    showContents ? openContainerStyle : styles.closedContainer,
                    {
                        opacity: contentFadeAnim,
                    },
                ]}
            >
                {props.children}
            </Animated.View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    containerOutlined: {
        borderColor: theme.colors.grey500,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        padding: theme.spacingScale,
    },
    containerNormal: {
        paddingVertical: theme.spacingScale,
    },
    openContainerOutlined: {
        padding: theme.spacingScale,
    },
    openContainerNormal: {
        paddingVertical: theme.spacingScale,
    },
    closedContainer: {
        height: 0,
        overflow: "hidden",
        maxHeight: 0,
        padding: 0,
    },
    triggerOutlined: {
        backgroundColor: theme.colors.white,
        borderWidth: 1,
        borderColor: theme.colors.grey500,
        padding: theme.spacingScale,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    triggerNormal: {
        backgroundColor: theme.colors.white,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingVertical: theme.spacingScale,
    },
    titleBox: {
        flexDirection: "row",
        flex: 1,
    },
    titleText: {
        flex: 1,
        color: theme.colors.textDark,
    },
    caret: {
        flex: 0,
    },
});
