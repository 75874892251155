import {
    createDrawerNavigator,
    DrawerContentComponentProps,
    DrawerContentScrollView,
    DrawerNavigationOptions,
} from "@react-navigation/drawer";
import { useNavigationState } from "@react-navigation/native";
import {
    MenuBar,
    MenuData,
    PERMANENT_DRAWER_WIDTH,
    StyleFunction,
    useDimensions,
    useThemedStyle,
} from "@venuepos/react-common";
import React, { useCallback, useMemo } from "react";
import { RootDrawerParamList, RootStackParamList } from "../navigation";

import { StackScreen } from "../navigation/navigator-stack";
import { useAdminMenuItems } from "./menu";
import { routeConfig } from "./navigator-routes";

const Drawer = createDrawerNavigator<RootDrawerParamList>();

export function DrawerScreen<RouteKey extends keyof RootStackParamList>() {
    const dimensions = useDimensions();
    const extraItems = useAdminMenuItems();
    const styles = useThemedStyle(styleFunc);

    const currentRoute = useNavigationState(state => {
        if (!state) {
            return;
        }

        const drawerLevelIndex = state.index === undefined ? 0 : state.index;
        const drawerLevelState = state.routes[drawerLevelIndex].state;

        if (drawerLevelState === undefined) {
            return;
        }

        const stackLevelIndex =
            drawerLevelState.index === undefined ? 0 : drawerLevelState.index;
        const stackLevelRouteName =
            drawerLevelState.routes[stackLevelIndex].name;

        return stackLevelRouteName;
    });

    const drawerContent = useCallback(
        (props: DrawerContentComponentProps) => (
            <DrawerContentScrollView
                contentContainerStyle={styles.drawerContent}
                style={styles.container}
                {...props}
            >
                <MenuData
                    routeConfig={routeConfig}
                    render={menuItems => (
                        <MenuBar
                            items={[...menuItems, ...extraItems]}
                            navigation={props.navigation}
                            logoLink={{ screen: "DASHBOARD" }}
                        />
                    )}
                    currentRoute={(currentRoute as RouteKey) ?? "DASHBOARD"}
                />
            </DrawerContentScrollView>
        ),
        [currentRoute, extraItems, styles.container, styles.drawerContent]
    );

    const defaultScreenOptions: DrawerNavigationOptions = useMemo(
        () => ({
            // Theme isn't ready, so we cant use it here - we have to figure out the type ourselves.
            drawerType:
                dimensions.width >= PERMANENT_DRAWER_WIDTH
                    ? "permanent"
                    : "front",
            headerShown: false,
        }),
        [dimensions.width]
    );

    return (
        <Drawer.Navigator
            initialRouteName="admin"
            drawerContent={drawerContent}
            screenOptions={defaultScreenOptions}
        >
            <Drawer.Screen name="admin" component={StackScreen} />
        </Drawer.Navigator>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: { backgroundColor: theme.colors.secondary },
    drawerContent: { padding: 0 },
});
