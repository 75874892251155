import React from "react";
import type { GQBookkeepingAccountsQuery } from "graphql-sdk";
import {
    DataTable,
    IconButton,
    StyleFunction,
    Text,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useAdminSession } from "../../../session";
import { formatDateTime } from "lib";
import type { AvailableLocale } from "locales";
import { StyleProp, ViewStyle } from "react-native";

export function AccountRow({
    item,
    onSelect,
    onEdit,
    style,
}: {
    item: GQBookkeepingAccountsQuery["bookkeepingAccounts"]["data"][0];
    onSelect: () => void;
    onEdit: () => void;
    style?: StyleProp<ViewStyle>;
}) {
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);

    const [{ locale }] = useAdminSession(["locale"]);

    return (
        <DataTable.Row style={style}>
            <DataTable.Cell>
                <Text onPress={onSelect} style={styles.textLink}>
                    {item.name}
                </Text>
            </DataTable.Cell>
            <DataTable.Cell>
                {formatDateTime(item.createdAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                <IconButton
                    color={theme.colors.secondary}
                    name="edit"
                    onPress={onEdit}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
        color: theme.colors.secondary,
    },
    textLink: {
        ...theme.styles.link,
    },
});
