import React, { ReactNode, useCallback, useState } from "react";
import { Pressable, StyleProp, View, ViewStyle } from "react-native";
import { Hoverable, Text } from "..";
import { StyleFunction, useThemedStyle } from "../..";

export function Tabs({
    labels = [],
    children,
    index: initialIndex = 0,
    headerStyle,
}: {
    labels: string[];
    children: ReactNode[];
    index?: number;
    headerStyle?: StyleProp<ViewStyle>;
}) {
    const [index, setIndex] = useState(initialIndex);
    const styles = useThemedStyle(styleFunc);

    const handlePress = useCallback(selectedIndex => {
        setIndex(selectedIndex);
    }, []);

    if (index > children.length) {
        console.warn(`Tab with index ${index} does not exist`);
        return null;
    }

    return (
        <>
            <View style={[styles.header, headerStyle]}>
                {labels.map((label, idx) => (
                    <Tab
                        key={idx}
                        index={idx}
                        label={label}
                        selected={idx === index}
                        onPress={handlePress}
                        testID={`tab:${label}`}
                    />
                ))}
            </View>
            {children[index]}
        </>
    );
}

function Tab({
    index,
    label,
    selected,
    onPress,
    testID,
}: {
    index: number;
    label: string;
    selected: boolean;
    onPress: (index: number) => void;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const handlePress = useCallback(() => {
        onPress(index);
    }, [index, onPress]);

    return (
        <Hoverable>
            {isHovered => (
                <Pressable
                    onPress={handlePress}
                    style={[
                        styles.headerItem,
                        isHovered ? styles.hoveredItem : undefined,
                        selected && styles.activeTab,
                    ]}
                    testID={testID}
                >
                    <Text
                        style={[
                            styles.headerItemText,
                            selected && styles.activeTabText,
                        ]}
                    >
                        {label}
                    </Text>
                </Pressable>
            )}
        </Hoverable>
    );
}

const styleFunc: StyleFunction = theme => ({
    header: {
        flexDirection: "row",
        marginBottom: theme.spacingScale * 2,
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.grey250,
    },
    headerItem: {
        paddingHorizontal: theme.spacingScale * 2,
        paddingTop: 5,
    },
    headerItemText: {
        ...(theme.headlines.h5 as ViewStyle),
        marginBottom: 0,
    },
    activeTab: {
        borderBottomColor: theme.colors.secondary,
        borderBottomWidth: 2,
    },
    activeTabText: {
        color: theme.colors.secondary,
    },
    hoveredItem: {
        backgroundColor: theme.colors.grey100,
    },
});
