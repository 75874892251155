import React, { useCallback } from "react";
import { View } from "react-native";
import {
    IconButton,
    StyleFunction,
    TextInput,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { LayoutSection } from "lib";

export function SectionTabHeader({
    sectionId,
    label,
    renaming,
    activeTab,
    allowMoveBefore,
    allowMoveAfter,
    onPress,
    onDelete,
    onChange,
    onMoveSection,
    onToggleRenaming,
    testID,
}: {
    sectionId: LayoutSection["id"];
    label: LayoutSection["label"];
    renaming: boolean;
    activeTab: boolean;
    allowMoveBefore: boolean;
    allowMoveAfter: boolean;
    onPress: (sectionId: LayoutSection["id"]) => void;
    onDelete: (sectionId: LayoutSection["id"]) => void;
    onChange: (
        sectionId: LayoutSection["id"],
        newLabel: LayoutSection["label"]
    ) => void;
    onMoveSection: (sectionId: string, direction: "BEFORE" | "AFTER") => void;
    onToggleRenaming: (
        sectionId: LayoutSection["id"],
        toggleState: boolean
    ) => void;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);

    const handleToggleRenaming = useCallback(() => {
        onToggleRenaming(sectionId, !renaming);
    }, [onToggleRenaming, renaming, sectionId]);

    const handleOnEndEditing = useCallback(
        event => {
            onChange(sectionId, event.nativeEvent.text);
            onToggleRenaming(sectionId, !renaming);
        },
        [onChange, onToggleRenaming, renaming, sectionId]
    );

    const handleOnPress = useCallback(() => {
        onPress(sectionId);
    }, [onPress, sectionId]);

    const handleOnDelete = useCallback(() => {
        onDelete(sectionId);
    }, [onDelete, sectionId]);

    const handleMoveBefore = useCallback(() => {
        if (!allowMoveBefore) {
            return;
        }
        onMoveSection(sectionId, "BEFORE");
    }, [allowMoveBefore, onMoveSection, sectionId]);

    const handleMoveAfter = useCallback(() => {
        if (!allowMoveAfter) {
            return;
        }
        onMoveSection(sectionId, "AFTER");
    }, [allowMoveAfter, onMoveSection, sectionId]);

    return (
        <View
            style={[styles.tab, activeTab ? styles.currentTab : null]}
            testID={testID}
        >
            <View style={styles.buttonsContainer}>
                <View style={styles.moveButton}>
                    <IconButton
                        name="angle-left"
                        size="small"
                        onPress={handleMoveBefore}
                        style={
                            !allowMoveBefore
                                ? styles.disabledButton
                                : styles.button
                        }
                        disabled={!allowMoveBefore}
                        testID="layout:section:move:before"
                    />
                </View>
                <View style={styles.buttonContainer}>
                    {renaming ? (
                        <IconButton
                            name="close"
                            onPress={handleToggleRenaming}
                            style={styles.closeButton}
                        />
                    ) : (
                        <IconButton
                            name="edit"
                            size="small"
                            onPress={handleToggleRenaming}
                            style={styles.button}
                            testID="layout:section:toggleRename"
                        />
                    )}
                </View>
            </View>
            <View
                style={styles.labelHolder}
                testID={`layout:section:${
                    activeTab ? "isSelected" : "isNotSelected"
                }`}
            >
                {renaming ? (
                    <TextInput
                        label=""
                        defaultValue={label}
                        onBlur={handleOnEndEditing}
                        selectTextOnFocus={true}
                        style={styles.inputElement}
                        testID="layout:section:input"
                    />
                ) : (
                    <Text
                        onPress={handleOnPress}
                        style={[
                            styles.textLabel,
                            activeTab ? styles.activeTextLabel : null,
                        ]}
                        numberOfLines={1}
                        testID="layout:section:labelText"
                    >
                        {label}
                    </Text>
                )}
            </View>
            <View style={styles.buttonsContainer}>
                <View style={styles.buttonContainer}>
                    <IconButton
                        name="delete"
                        size="small"
                        onPress={handleOnDelete}
                        style={styles.deleteButton}
                        testID="layout:section:delete"
                    />
                </View>
                <View style={styles.moveButton}>
                    <IconButton
                        name="angle-right"
                        size="small"
                        onPress={handleMoveAfter}
                        style={
                            !allowMoveAfter
                                ? styles.disabledButton
                                : styles.button
                        }
                        disabled={!allowMoveAfter}
                        testID="layout:section:move:after"
                    />
                </View>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    textLabel: {
        ...theme.fonts.regular,
        fontSize: 16,
        padding: theme.spacingScale * 1.5,
        paddingLeft: theme.spacingScale / 2 + 1,
        userSelect: "none",
    },
    activeTextLabel: {
        fontWeight: "bold",
    },
    tab: {
        width: "32.2%",
        backgroundColor: theme.colors.grey50,
        marginBottom: theme.spacingScale,
        marginHorizontal: theme.spacingScale / 2,
        borderColor: theme.colors.lightGrey,
        borderStyle: "dashed",
        borderWidth: 1,
        flexDirection: "row",
    },
    currentTab: {
        borderStyle: "solid",
        borderColor: theme.colors.secondary,
    },
    buttonsContainer: {
        flexDirection: "row",
    },
    buttonContainer: {
        flex: 1,
        justifyContent: "center",
    },
    button: {
        color: theme.colors.black,
        padding: theme.spacingScale,
    },
    disabledButton: {
        color: theme.colors.semiTransparentBlack,
        padding: theme.spacingScale,
        alignItems: "center",
    },
    moveButton: {
        flex: 1,
        backgroundColor: theme.colors.grey100,
        justifyContent: "center",
    },
    deleteButton: {
        color: theme.colors.error,
        marginRight: theme.spacingScale,
    },
    closeButton: {
        color: theme.colors.textDark,
        alignSelf: "center",
    },
    inputElement: {
        flex: 1,
        paddingStart: theme.spacingScale - 1,
        marginBottom: theme.spacingScale / 2 + 1,
        marginRight: theme.spacingScale / 2,
        marginVertical: theme.spacingScale / 2 + 1,
    },
    labelHolder: {
        flex: 1,
        justifyContent: "center",
    },
});
