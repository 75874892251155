import { TFunction } from "i18next";
import { v4 as uuidv4 } from "uuid";
import {
    ProductSection,
    ProductButton,
    FunctionButton,
    ILayoutProduct,
    LayoutSection,
    LayoutButton,
    GridDimensions,
    ButtonPosition,
    LayoutButtonMetaData,
    IProductGroup,
    CustomItemButtonType,
    VenueAccessTicketButtonType,
} from "lib";
import { produceNewLayoutSection } from "../functions";
import {
    LayoutActionType,
    LayoutAction,
    LayoutReducerState,
} from "../layout-operation-reducer";
import {
    positionProductButtonsInSections,
    getButtonPositionsForSection,
    checkButtonCollision,
    getMaxDimensionsForCoordinate,
    positionButtonInFirstAvailableSpot,
} from "../functions";
import produce from "immer";

export type ProductLayoutReducerState = Omit<
    LayoutReducerState,
    "sections" | "buttons"
> & {
    sections: (ProductSection & Partial<{ renaming: boolean }>)[];
    knownProducts: ILayoutProduct[];
    knownProductGroups: Pick<IProductGroup, "id" | "color">[];
    dimensions: GridDimensions;
    buttonMetaData: Record<LayoutButton["id"], LayoutButtonMetaData>;
};

export enum ProductLayoutAction {
    ADD_BUTTON_TO_SECTION = "add_button_to_section",
    ADD_PRODUCTS_TO_SECTION = "add_products_to_section",
    MOVE_BUTTON_IN_SECTION = "move_button_in_section",
    MOVE_BUTTON_TO_SECTION = "move_button_to_section",
    REMOVE_BUTTON_FROM_SECTION = "remove_button_from_section",
    UPDATE_BUTTON_IN_SECTION = "update_button_in_section",
}

export type ProductLayoutActionType =
    | {
          type: ProductLayoutAction.ADD_BUTTON_TO_SECTION;
          newButton: ProductButton | FunctionButton;
          productData?: ILayoutProduct;
          translationFunction: TFunction;
      }
    | {
          type: ProductLayoutAction.ADD_PRODUCTS_TO_SECTION;
          products: ILayoutProduct[];
          startingPosition: ButtonPosition;
          translationFunction: TFunction;
      }
    | {
          type: ProductLayoutAction.MOVE_BUTTON_IN_SECTION;
          position: ButtonPosition;
          buttonId: ProductButton["id"];
      }
    | {
          type: ProductLayoutAction.MOVE_BUTTON_TO_SECTION;
          buttonId: LayoutButton["id"];
          toSection: LayoutSection["id"] | "NEW";
          translationFunction: TFunction;
      }
    | {
          type: ProductLayoutAction.REMOVE_BUTTON_FROM_SECTION;
          buttonId: (ProductButton | FunctionButton)["id"];
      }
    | {
          type: ProductLayoutAction.UPDATE_BUTTON_IN_SECTION;
          updatedButton: ProductButton | FunctionButton;
          productData?: ILayoutProduct;
          translationFunction: TFunction;
      };

export const productLayoutReducer = <T extends ProductLayoutReducerState>(
    state: T,
    action: LayoutActionType | ProductLayoutActionType
) => {
    let newState: T;
    let foundSectionIndex: number;
    let foundSection: T["sections"][0] | undefined;
    let foundButtonIndex: number | undefined;
    let foundButton: ProductButton | FunctionButton | undefined;

    switch (action.type) {
        case LayoutAction.ADD_SECTION: {
            if (!action.translationFunction) {
                return state;
            }

            newState = produce(state, draft => {
                draft.sections.push(
                    produceNewLayoutSection(
                        action.translationFunction
                    ) as ProductSection
                );
            });

            break;
        }

        case LayoutAction.DELETE_SECTION: {
            foundSectionIndex = state.sections.findIndex(
                itr => itr.id === action.sectionId
            );

            if (foundSectionIndex === -1) {
                return state;
            }

            // Find the section and remove it.
            newState = produce(state, draft => {
                const deletedSectionWithButtons = draft.sections.splice(
                    foundSectionIndex,
                    1
                );

                const deletedButtons = deletedSectionWithButtons[0].buttons;

                // Update the list of known products.
                // First, find the list of all products in product buttons on this layout.
                const allProductIds: string[] = [];
                draft.sections.forEach(section => {
                    section.buttons.forEach(button => {
                        if (button.buttonType !== "PRODUCT") {
                            return;
                        }

                        allProductIds.push((button as ProductButton).productId);
                    });
                });

                deletedButtons.forEach(deletedButton => {
                    // Figure out if other buttons use this product
                    // If the product id from the deleted button is still in the list, then just back off.
                    if (
                        allProductIds.includes(
                            (deletedButton as ProductButton).productId
                        )
                    ) {
                        return;
                    }

                    // There is no other use of this product in this layout.
                    // Remove the product from the cache of known products
                    draft.knownProducts = draft.knownProducts.filter(
                        productItr =>
                            productItr.id !==
                            (deletedButton as ProductButton).productId
                    );
                });

                // If this was the last section, then add a new one.
                if (draft.sections.length === 0) {
                    draft.sections.push(
                        produceNewLayoutSection(
                            action.translationFunction
                        ) as ProductSection
                    );
                }

                // If the currently deleted section was before the currently
                // active section then adjust the index of the active section.
                if (foundSectionIndex <= draft.currentSectionIndex) {
                    draft.currentSectionIndex = draft.currentSectionIndex - 1;

                    // Make sure the index never gets faulty
                    if (draft.currentSectionIndex < 0) {
                        draft.currentSectionIndex = 0;
                    }
                }
            });

            break;
        }

        case LayoutAction.MOVE_SECTION: {
            const layoutIndex = state.sections.findIndex(
                section => section.id === action.sectionId
            );

            if (layoutIndex === -1) {
                return state;
            }

            if (!["BEFORE", "AFTER"].includes(action.direction)) {
                return state;
            }

            const newPosition =
                action.direction === "BEFORE"
                    ? layoutIndex - 1
                    : layoutIndex + 1;

            // if newPosition is out of bounds: back off.
            if (newPosition < 0 || newPosition > state.sections.length) {
                return state;
            }

            newState = produce(state, draft => {
                // Move section from layoutIndex to newPosition
                draft.sections.splice(
                    newPosition,
                    0,
                    draft.sections.splice(layoutIndex, 1)[0]
                );

                // If the active section was involved in the move, then update currentSectionIndex
                if (layoutIndex === draft.currentSectionIndex) {
                    draft.currentSectionIndex = newPosition;
                } else if (newPosition === draft.currentSectionIndex) {
                    draft.currentSectionIndex = layoutIndex;
                }
            });

            break;
        }

        case LayoutAction.RENAME_SECTION: {
            foundSection = state.sections.find(
                itr => itr.id === action.sectionId
            );

            if (!foundSection) {
                return state;
            }

            // change label on section
            newState = produce(state, draft => {
                draft.sections.map(section => {
                    if (section.id === action.sectionId) {
                        section.label = action.newLabel.trim();
                    }

                    return section;
                });
            });

            break;
        }

        case LayoutAction.SELECT_SECTION: {
            foundSectionIndex = state.sections.findIndex(
                itr => itr.id === action.sectionId
            );

            if (foundSectionIndex === -1) {
                return state;
            }

            newState = produce(state, draft => {
                draft.currentSectionIndex = foundSectionIndex;
            });

            break;
        }

        case LayoutAction.TOGGLE_RENAME_SECTION: {
            foundSection = state.sections.find(
                itr => itr.id === action.sectionId
            );

            if (!foundSection) {
                return state;
            }

            newState = produce(state, draft => {
                draft.sections.map(section => {
                    if (section.id === action.sectionId) {
                        // toggle the renaming property for this section
                        section.renaming = action.toggleState;
                    }

                    return section;
                });
            });

            break;
        }

        case ProductLayoutAction.ADD_BUTTON_TO_SECTION: {
            newState = produce(state, draft => {
                const newId = action.newButton.id;

                // Add button meta data
                draft.buttonMetaData[newId] = {
                    maxHeightValue: 1,
                    maxWidthValue: 1,

                    text: !action.newButton.label
                        ? !action.productData?.name
                            ? !action.productData?.buttonText
                                ? action.translationFunction(
                                      "backoffice.layout.no_text",
                                      "No text"
                                  )
                                : action.productData?.buttonText
                            : action.productData?.name
                        : action.newButton.label,

                    color: !action.newButton.color
                        ? !action.productData?.group?.color
                            ? "#aaaaaa"
                            : action.productData?.group?.color
                        : action.newButton.color,
                };

                draft.sections[draft.currentSectionIndex].buttons.push(
                    action.newButton
                );

                if (
                    action.newButton.buttonType === "FUNCTION" &&
                    (action.newButton.function === "CUSTOM_ITEM" ||
                        action.newButton.function === "VENUE_ACCESS_TICKETS")
                ) {
                    const foundProductGroup = draft.knownProductGroups.find(
                        prodGroup => {
                            return (
                                prodGroup.id ===
                                (
                                    (action.newButton as CustomItemButtonType) ||
                                    (action.newButton as VenueAccessTicketButtonType)
                                ).productGroupId
                            );
                        }
                    );

                    if (!foundProductGroup) {
                        return;
                    }

                    // Append with button color. Check the form value first, then check the related product group.
                    draft.buttonMetaData[newId] = {
                        ...draft.buttonMetaData[newId],

                        color: !action.newButton.color
                            ? !foundProductGroup.color
                                ? "#aaaaaa"
                                : foundProductGroup.color
                            : action.newButton.color,
                    };
                } else if (action.newButton.buttonType === "PRODUCT") {
                    // If this is not a Product button, then back off.

                    // If there is no product data with the button, or the button is already in the cache, then back off.
                    if (
                        !action.productData ||
                        draft.knownProducts.find(
                            productItr =>
                                productItr.id ===
                                (action.productData as ILayoutProduct).id
                        )
                    ) {
                        return;
                    }

                    // Add the product data to the cache of known products in this layout.
                    draft.knownProducts.push(action.productData);
                }
            });

            break;
        }

        case ProductLayoutAction.ADD_PRODUCTS_TO_SECTION: {
            if (!action.products || !action.products.length) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            // The requested section does not exist. Back off.
            if (!foundSection) {
                return state;
            }

            newState = produce(state, draft => {
                // Convert product data to product button data
                const newProductButtons: ProductButton[] = action.products.map(
                    (layoutProduct: ILayoutProduct) => {
                        const newId = uuidv4();
                        const newProductButton = {
                            id: newId,
                            x: -1,
                            y: -1,
                            width: 1,
                            height: 1,
                            buttonType: "PRODUCT",
                            productId: layoutProduct.id,
                            amount: layoutProduct.amount,
                            color: layoutProduct.group!.color,
                            label: "",
                        } as ProductButton;

                        // Add product metadata for the buttons
                        draft.buttonMetaData[newId] = {
                            text: !newProductButton.label
                                ? !layoutProduct.buttonText
                                    ? !layoutProduct.name
                                        ? action.translationFunction(
                                              "backoffice.layout.no_text",
                                              "No text"
                                          )
                                        : layoutProduct.name
                                    : layoutProduct.buttonText
                                : newProductButton.label,
                            color: layoutProduct.group?.color ?? "#aaaaaa",
                        };

                        return newProductButton;
                    }
                );

                const { updatedSections, updatedSectionIndex } =
                    positionProductButtonsInSections(
                        newProductButtons,
                        draft.sections,
                        draft.currentSectionIndex,
                        getButtonPositionsForSection(
                            draft.sections[draft.currentSectionIndex].buttons,
                            draft.dimensions
                        ),
                        action.startingPosition,
                        draft.dimensions,
                        action.translationFunction
                    );
                draft.sections = updatedSections;

                draft.knownProducts = [
                    ...draft.knownProducts,
                    ...action.products,
                ];

                if (updatedSectionIndex !== draft.currentSectionIndex) {
                    draft.currentSectionIndex = updatedSectionIndex;
                }
            });

            break;
        }

        case ProductLayoutAction.MOVE_BUTTON_IN_SECTION: {
            // If, for some odd reason, the button does not have an id or is dropped in the same location, then back off.
            if (!action.buttonId) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButtonIndex = state.sections[
                state.currentSectionIndex
            ].buttons.findIndex(buttonItr => buttonItr.id === action.buttonId);

            if (foundButtonIndex === -1) {
                return state;
            }

            newState = produce(state, draft => {
                let sectionButtons: (ProductButton | FunctionButton)[] =
                    foundSection!.buttons;

                let updateButton: ProductButton | FunctionButton = {
                    ...sectionButtons[foundButtonIndex!],
                };

                let buttonPositions: (ProductButton | FunctionButton)["id"][] =
                    getButtonPositionsForSection(
                        sectionButtons,
                        draft.dimensions
                    );

                const oldPosition = { x: updateButton.x, y: updateButton.y };
                const newPosition = { ...action.position };

                updateButton = {
                    ...updateButton,

                    ...newPosition,
                };

                // Check if the button is dropped "on top of" another button
                const singleCellSize = { width: 1, height: 1 };
                let collisionWithButtonId = checkButtonCollision(
                    action.buttonId,
                    singleCellSize, // Only check the drop position (meaning: use a "single cell" button)
                    newPosition, // Check the drop position
                    buttonPositions,
                    draft.dimensions
                );

                const oldDimensions = {
                    width: updateButton.width,
                    height: updateButton.height,
                };
                const maxDimensions = getMaxDimensionsForCoordinate(
                    newPosition,
                    draft.dimensions,
                    sectionButtons,
                    [action.buttonId!]
                );

                const newWidth = Math.min(
                    updateButton.width,
                    maxDimensions.width
                );
                const newHeight = Math.min(
                    updateButton.height,
                    maxDimensions.height
                );

                // Check if the buttons new position and size makes it collide/overlap with other buttons
                if (
                    checkButtonCollision(
                        action.buttonId,
                        {
                            width: newWidth,
                            height: newHeight,
                        },
                        newPosition,
                        buttonPositions,
                        draft.dimensions
                    )
                ) {
                    // If the button in the new position collides with another button,
                    // then set new button dimensions
                    updateButton.width = 1;
                    updateButton.height = 1;
                } else {
                    updateButton.width = newWidth;
                    updateButton.height = newHeight;
                }

                // When the button is dropped on another button (or overlaps in some way),
                // then handle that scenario.
                if (collisionWithButtonId) {
                    // Find the colliding button
                    sectionButtons = sectionButtons.map(button => {
                        if (button.id === collisionWithButtonId) {
                            // Find the max width and height for the colliding button,
                            // when it's moved to the dropped button's start position.
                            const _maxDimensions =
                                getMaxDimensionsForCoordinate(
                                    oldPosition,
                                    draft.dimensions,
                                    sectionButtons,
                                    [button.id]
                                );

                            // Update the metadata for the colliding button.
                            draft.buttonMetaData[button.id] = {
                                ...draft.buttonMetaData[button.id],

                                maxWidthValue: _maxDimensions.width,
                                maxHeightValue: _maxDimensions.height,
                            };

                            // Update the colliding button with the old position and dimensions from the dropped button
                            return {
                                ...button,

                                ...oldPosition,
                                ...oldDimensions,
                            };
                        }

                        return button;
                    });
                }

                // update button meta data
                draft.buttonMetaData[updateButton.id] = {
                    ...draft.buttonMetaData[updateButton.id],

                    maxHeightValue: maxDimensions.height,
                    maxWidthValue: maxDimensions.width,
                };

                draft.sections[draft.currentSectionIndex].buttons =
                    sectionButtons.map(button => {
                        if (button.id === action.buttonId) {
                            return updateButton;
                        }

                        return button;
                    });
            });

            break;
        }

        /**
         * 1. Remove the button from the currently selected section
         * 2: Find the known button positions for the new section
         * 3: Found an available position for the moved button in the new section
         * 4: Update the moved button to the new position
         * 5: Update the collection of layout sections
         */
        case ProductLayoutAction.MOVE_BUTTON_TO_SECTION: {
            if (!action.buttonId || !action.toSection) {
                return state;
            }
            // The "from" section
            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButton = foundSection.buttons.find(
                button => button.id === action.buttonId
            );

            if (!foundButton) {
                return state;
            }

            const toSectionIndex = state.sections.findIndex(
                sectionItr => sectionItr.id === action.toSection
            );

            if (action.toSection !== "NEW" && toSectionIndex < 0) {
                // The To section was not found. Back off.
                return state;
            }

            // All pre-conditions should be true now:
            // 1. The button is found
            // 2. The To section is found

            newState = produce(state, draft => {
                // We need the index of the button to remove it with splice()
                foundButtonIndex = draft.sections[
                    draft.currentSectionIndex
                ].buttons.findIndex(itr => itr.id === action.buttonId);

                // remove button from currently active section
                draft.sections[draft.currentSectionIndex].buttons =
                    draft.sections[draft.currentSectionIndex].buttons.filter(
                        button => button.id !== action.buttonId
                    );

                // If the button is moved to a new section, then create it, add the button and back off.
                if (action.toSection === "NEW") {
                    let newSection = produceNewLayoutSection(
                        action.translationFunction
                    ) as ProductSection;

                    // Reposition the button to the top left (0,0) position of the new section
                    newSection.buttons.push({ ...foundButton!, x: 0, y: 0 });

                    draft.sections.push(newSection);

                    // Change active section to the new section?
                    draft.currentSectionIndex = draft.sections.length - 1;

                    return;
                }

                // The button is moved to an existing section
                draft.sections.map(section => {
                    if (section.id === action.toSection) {
                        section.buttons.push(
                            positionButtonInFirstAvailableSpot(
                                { ...foundButton! },
                                getButtonPositionsForSection(
                                    draft.sections[toSectionIndex].buttons,
                                    draft.dimensions
                                ),
                                0,
                                draft.dimensions.columns
                            )
                        );

                        // Change active section to this section?
                        draft.currentSectionIndex = toSectionIndex;
                    }

                    return section;
                });
            });

            break;
        }

        case ProductLayoutAction.REMOVE_BUTTON_FROM_SECTION: {
            if (!action.buttonId) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButton = state.sections[
                state.currentSectionIndex
            ].buttons.find(itr => itr.id === action.buttonId);

            if (!foundButton) {
                return state;
            }

            newState = produce(state, draft => {
                // Quote from immerjs.github.io: https://immerjs.github.io/immer/update-patterns/
                // when filtering, creating a fresh collection is simpler than
                // removing irrelevant items
                const buttons =
                    draft.sections[draft.currentSectionIndex].buttons;

                draft.sections[draft.currentSectionIndex].buttons =
                    buttons.filter(button => button.id !== action.buttonId);

                //
                // Clear the meta data and product data for the button
                //

                delete draft.buttonMetaData[action.buttonId];

                if (foundButton!.buttonType !== "PRODUCT") {
                    // This is not a Product button. Just back off, we're done here.
                    return;
                }

                // Update the list of known products.
                // First, find the list of all products in product buttons on this layout.
                const allProductIds: string[] = [];
                draft.sections.forEach(section => {
                    section.buttons.forEach(button => {
                        if (button.buttonType !== "PRODUCT") {
                            return;
                        }

                        allProductIds.push((button as ProductButton).productId);
                    });
                });

                // Then figure out if other buttons use this product
                // If the product id from the deleted button is still in the list, then just back off.
                if (
                    allProductIds.includes(
                        (foundButton as ProductButton).productId
                    )
                ) {
                    return;
                }

                // There is no other use of this product in this layout.
                // Remove the product from the cache of known products
                draft.knownProducts = draft.knownProducts.filter(
                    productItr =>
                        productItr.id !==
                        (foundButton as ProductButton).productId
                );
            });

            break;
        }

        case ProductLayoutAction.UPDATE_BUTTON_IN_SECTION: {
            if (!action.updatedButton.id) {
                return state;
            }

            const foundButtons =
                state.sections[state.currentSectionIndex].buttons;

            if (foundButtons === undefined) {
                return state;
            }
            foundButtonIndex = foundButtons.findIndex(
                buttonItr => buttonItr?.id === action.updatedButton.id
            );

            if (foundButtonIndex === -1) {
                return state;
            }

            newState = produce(state, draft => {
                draft.sections[draft.currentSectionIndex].buttons[
                    foundButtonIndex!
                ] = { ...action.updatedButton };

                //
                // Clear the meta data and product data for the button
                //

                if (action.updatedButton.buttonType === "FUNCTION") {
                    if (
                        action.updatedButton.function === "CUSTOM_ITEM" ||
                        action.updatedButton.function === "VENUE_ACCESS_TICKETS"
                    ) {
                        if (
                            (action.updatedButton as CustomItemButtonType) ||
                            (
                                action.updatedButton as VenueAccessTicketButtonType
                            ).productGroupId
                        ) {
                            const foundGroup = draft.knownProductGroups.find(
                                group =>
                                    group.id ===
                                    (
                                        (action.updatedButton as CustomItemButtonType) ||
                                        (action.updatedButton as VenueAccessTicketButtonType)
                                    ).productGroupId
                            );

                            if (foundGroup) {
                                draft.buttonMetaData[action.updatedButton.id] =
                                    {
                                        ...draft.buttonMetaData[
                                            action.updatedButton.id
                                        ],

                                        color: !action.updatedButton.color
                                            ? !foundGroup.color
                                                ? "#aaaaaa"
                                                : foundGroup.color
                                            : action.updatedButton.color,
                                        text: action.updatedButton.label,
                                    };
                            }
                        }
                    } else if (
                        action.updatedButton.function === "BUY_ACCOUNT_FUNDS"
                    ) {
                        draft.buttonMetaData[action.updatedButton.id] = {
                            ...draft.buttonMetaData[action.updatedButton.id],

                            color: !action.updatedButton.color
                                ? "#aaaaaa"
                                : action.updatedButton.color,
                        };
                    }
                } else if (
                    action.updatedButton.buttonType === "PRODUCT" &&
                    action.productData
                ) {
                    const knownProduct = draft.knownProducts.find(
                        product =>
                            product.id ===
                            (action.updatedButton as ProductButton).productId
                    );

                    if (!knownProduct) {
                        // The product was not in the cache of known products. Insert it.
                        draft.knownProducts.push(action.productData);
                    } else {
                        // Update the existing button metadata.
                        (
                            draft.sections[draft.currentSectionIndex].buttons[
                                foundButtonIndex!
                            ] as ProductButton
                        ).amount = knownProduct.amount;
                    }

                    draft.buttonMetaData[action.updatedButton.id] = {
                        ...draft.buttonMetaData[action.updatedButton.id],

                        text: !action.updatedButton.label
                            ? !action.productData.buttonText
                                ? !action.productData.name
                                    ? action.translationFunction(
                                          "backoffice.layout.no_text",
                                          "No text"
                                      )
                                    : action.productData.name
                                : action.productData.buttonText
                            : action.updatedButton.label,
                        color: !action.updatedButton.color
                            ? !action.productData.group?.color
                                ? "#aaaaaa"
                                : action.productData.group?.color
                            : action.updatedButton.color,
                    };
                }
            });

            break;
        }

        default:
            console.error("Invalid Product Layout Operation:");
            console.error(action);
            throw new Error(
                `Invalid Product Layout Operation: ${JSON.stringify(action)}`
            );
    }

    return newState;
};
